import React, { useEffect, useState, useCallback } from "react"
import _ from "lodash"

import axios from "axios"

import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import ProgressBar from "react-bootstrap/ProgressBar"
import Dropdown from "react-bootstrap/Dropdown"
import FormGroup from "react-bootstrap/FormGroup"
import FormControl from "react-bootstrap/FormControl"
import InputGroup from "react-bootstrap/InputGroup"
import Spinner from "react-bootstrap/Spinner"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleUp, faChevronRight, faChevronCircleDown, faTrash, faEllipsisV, faCheck, faLink, faImage } from "@fortawesome/free-solid-svg-icons"

import CancelWhite from "../../images/svgs/close.svg"
import DeleteIcon from "../../images/svgs/delete.svg"
import PointsIcon from "../../images/svgs/pointsicon.svg"
import CheckIcon from "../../images/svgs/check-white.svg"
import FolderIcon from "../../images/svgs/folderuploadicon.svg"
import UploadVideo from "../../images/svgs/uploadvideo.svg"
import UploadBlog from "../../images/svgs/uploadblog.svg"
import doc from "../../images/svgs/doc.png"
import excel from "../../images/svgs/excel.png"
import ppt from "../../images/svgs/ppt.png"

import authClient from "../../services/auth"
// import hideMessage from '../../services/hidemessage';
import Notification from "../custom/Notification"
import Common from "../../services/common"
import utcDateTime from "../../services/utcdatetime"
import { isBrowser } from "../../services/developer_mode"

import StylesM from "../../components/upload-resource.module.scss"
import Styles from "../../modularscss/upload.module.scss"

function AddExtraFields(props) {
  const [clickedDuration, setClickedDuration] = useState(false)
  const [clickedDescription, setClickedDescription] = useState(false)
  const [clickedPoints, setClickedPoints] = useState(false)

  function sendQueryExtra(field, value, type, item) {
    props.handleSubmitData(field, value, type, item)
  }

  const delayedQueryExtra = useCallback(
    _.debounce((field, value, type, item) => sendQueryExtra(field, value, type, item), 1000),
    []
  )

  function onBlurDataExtra(field, value, type, item) {
    if (item[field] !== value) {
      props.handleSubmitData(field, value, type, item)
    }
  }

  return (
    <div className={`${clickedPoints === false && clickedDuration === false && clickedDescription === false ? `d-flex` : null}`}>
      {clickedDescription === props.currentFile.id ? (
        <Col xs="12" sm="6" md="12" className={`p-0 mb-2`}>
          <InputGroup className={`border_on_hover m-0 d-flex w-100 p-1 ${StylesM.upload_edit_abovediv}`}>
            <FormControl
              as="textarea"
              placeholder="Add Description"
              name="description"
              defaultValue={props.currentFile.description}
              className={`text-white border-0 ${StylesM.upload_edit_input} ${StylesM.extra_input} ${StylesM.font_14} shadow-none`}
              onChange={(e) => {
                e.preventDefault()
                props.settingLoader(props.type, props.currentFile.id)
                delayedQueryExtra(e.target.name, e.target.value, props.type, props.currentFile)
              }}
              onBlur={(e) => {
                e.preventDefault()
                onBlurDataExtra(e.target.name, e.target.value, props.type, props.currentFile)
              }}
            />
          </InputGroup>
        </Col>
      ) : (
        <div
          className={`pointer mr-2 mb-2 ${props.type === "link_links" ? `d-none` : ``}`}
          onClick={() => {
            setClickedDescription(props.currentFile.id)
          }}
          style={{
            color: "#E0E2E5",
            fontSize: "12px",
            borderBottom: "1px solid #E0E2E5",
            width: "fit-content",
          }}
        >
          Add Description
        </div>
      )}
      {clickedDuration === props.currentFile.id ? (
        <Col xs="12" sm="6" md="12" className={`p-0 mb-2`}>
          <InputGroup className={`border_on_hover m-0 d-flex w-100 p-1 ${StylesM.upload_edit_abovediv}`}>
            <FormControl
              placeholder="Add Duration"
              name="duration"
              type="number"
              defaultValue={props.currentFile.duration}
              className={`text-white border-0 ${StylesM.upload_edit_input} ${StylesM.extra_input} ${StylesM.font_14} shadow-none`}
              onChange={(e) => {
                e.preventDefault()
                props.settingLoader(props.type, props.currentFile.id)
                delayedQueryExtra(e.target.name, e.target.value, props.type, props.currentFile)
              }}
              onBlur={(e) => {
                e.preventDefault()
                onBlurDataExtra(e.target.name, e.target.value, props.type, props.currentFile)
              }}
            />
          </InputGroup>
        </Col>
      ) : (
        <div
          className={`pointer mr-2 mb-2 ${props.type === "link_links" ? `d-none` : ``} ${
            props.currentFile.channel === "vimeo" || props.hide === "duration" ? `d-none` : ``
          }`}
          onClick={() => {
            setClickedDuration(props.currentFile.id)
          }}
          style={{
            color: "#E0E2E5",
            fontSize: "12px",
            borderBottom: "1px solid #E0E2E5",
            width: "fit-content",
          }}
        >
          Add Duration
        </div>
      )}
      {clickedPoints === props.currentFile.id ? (
        <Col xs="12" sm="6" md="12" className="p-0 mb-2">
          <InputGroup className={`border_on_hover m-0 d-flex w-100 p-1 ${StylesM.upload_edit_abovediv}`}>
            <FormControl
              placeholder="Add Points"
              name="points"
              type="number"
              defaultValue={props.currentFile.points}
              className={`text-white border-0 ${StylesM.upload_edit_input} ${StylesM.extra_input} ${StylesM.font_14} shadow-none`}
              onChange={(e) => {
                e.preventDefault()
                props.settingLoader(props.type, props.currentFile.id)
                delayedQueryExtra(e.target.name, e.target.value, props.type, props.currentFile)
              }}
              onBlur={(e) => {
                e.preventDefault()
                onBlurDataExtra(e.target.name, e.target.value, props.type, props.currentFile)
              }}
            />
            <InputGroup.Append>
              <InputGroup.Text className={`bg-transparent border-0`}>
                <img src={PointsIcon} alt={`topic-points`} className={`my-auto`} style={{ objectFit: `contain`, height: `16px` }} />
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      ) : (
        <div
          className="pointer mr-2 mb-2"
          onClick={() => {
            setClickedPoints(props.currentFile.id)
          }}
          style={{
            color: "#E0E2E5",
            fontSize: "12px",
            borderBottom: "1px solid #E0E2E5",
            width: "fit-content",
          }}
        >
          Add Points
        </div>
      )}
    </div>
  )
}

function UploadFiles(props) {
  const DefaultHeader = isBrowser()
    ? {
        authorization: "Token " + localStorage.getItem("student_authenticity_token"),
      }
    : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [showSection, setShowSection] = useState("upload_files")

  const [highlight, setHighlight] = useState("unhighlight")
  const [files, setFiles] = useState(props.files)
  const [progressBar, setProgressBar] = useState(0)
  const [savedCount, setSavedCount] = useState(0)

  const [editFilesNow, setEditFilesNow] = useState(false)
  const [undefinedFiles, setUndefinedFiles] = useState(0)

  const [showDefineModal, setShowDefineModal] = useState(false)

  const [fileDeleted, setFileDeleted] = useState(false)

  const [link_index, setLinkIndex] = useState(1)
  const [videoArray, setVideoArray] = useState({})
  const [videos_data, setVideosData] = useState({})
  const [typingTimer, setTypingTimer] = useState("") //timer identifier
  const [selected_index, setSelectedIndex] = useState(-1)
  const [currentLinkIndex, setCurrentLinkIndex] = useState(-1)
  const [url, setUrl] = useState("")
  const [video_loading, setVideoLoading] = useState([])
  const [video, setVideo] = useState({})
  const [doneTypingInterval, setDoneTypingInterval] = useState(1500) //time in ms, 5 second for example

  const [addMore, setAddMore] = useState(true)
  const [defaultLink, setDefaultLink] = useState(true)

  const [showDefineNow, setShowDefineNow] = useState(true)
  const [fileCreated, setFileCreated] = useState(false)

  const [toggleValue, setToggleValue] = useState(false)
  const [updateInputData, setUpdateInputData] = useState({})

  const [loader, setLoader] = useState({})

  const [defineFiles, setDefineFiles] = useState("1")
  const [show, setShow] = useState(false)
  const [showOnlyLinks, setShowOnlyLinks] = useState(false)
  const [addToResLib, setAddToResLib] = useState(true)
  const [notificationData, setNotificationData] = useState(null)
  const [hideCloseButton, setHideCloseButton] = useState(true)

  useEffect(() => {
    setAddToResLib(true)
  }, [])

  function closeModal() {
    if (props.topic) {
      props.fetchTopicResources(props.topic.id)
    } else {
      props.fetchTopicResources()
    }
    setFiles([])
    setShow(false)
    props.setFiles([])
    setProgressBar(0)
    setSavedCount(0)
    setEditFilesNow(false)
    setUndefinedFiles(0)

    setLinkIndex(1)
    setVideoArray({})
    setVideosData({})
    setSelectedIndex(-1)
    setCurrentLinkIndex(-1)
    setUrl("")
    setVideo({})
    setAddMore(true)
    setDefaultLink(true)

    setShowDefineNow(true)
    setFileCreated(false)

    setUpdateInputData({})
    setLoader({})
    setShowSection("")
  }

  function onDragEnter(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("highlight")
  }

  function onDragOver(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("highlight")
  }

  function onDragLeave(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("unhighlight")
  }

  function onDrop(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("unhighlight")
    handleDrop(event)
  }

  function handleDrop(e) {
    var dt = e.dataTransfer
    let new_files = dt.files
    let filex = []
    for (let i = 0; i < new_files.length; i++) {
      if (parseInt(new_files[i].size) < 200000000) {
        filex.push(new_files[i])
        setFiles([...files, ...filex])
      } else {
        // hideMessage.displayErrorMsg( new_files[i].name + ' has size more than 200MB.')
        setNotificationData({
          type: "error",
          title: new_files[i].name + " has size more than 200MB.",
        })
      }
    }
  }

  useEffect(() => {
    if (props.files.length > 0 && !show) {
      setShow(true)
    } else if (props.showLinksSection && !show) {
      setShow(true)
      setShowSection("upload_links")
      setShowOnlyLinks(true)
    }
    setFiles(props.files)
  }, [props.files])

  /*useEffect(() => {
    if (localStorage.getItem('defineFiles') != null) {
      setDefineFiles(localStorage.getItem('defineFiles'))
    } else {
      setDefineFiles('')
    }
  }, [localStorage.getItem('defineFiles')])*/

  useEffect(() => {
    handleFiles(files)
    if (showDefineNow && files.length > 0) {
      setShowDefineNow(false)
    } else {
      if (!showDefineNow && Object.keys(videoArray).filter((key) => videoArray[key] === false).length === link_index) {
        setShowDefineNow(true)
      }
    }
  }, [files])

  function handleFiles(files) {
    files = [...files]
    initializeProgress(files.length)
    files.forEach(uploadData)
    // files.forEach(previewFile)
  }

  let uploadProgress = []
  // let progressBar = isBrowser() ? document.getElementById('progress-bar') : null

  function initializeProgress(numFiles) {
    // if (progressBar !== null) {
    // progressBar.value = 0
    setProgressBar(0)
    uploadProgress = []

    for (let i = numFiles; i > 0; i--) {
      uploadProgress.push(0)
    }
    // }
  }

  function updateProgress(fileNumber, percent) {
    uploadProgress[fileNumber] = percent
    let total = uploadProgress.reduce((tot, curr) => tot + curr, 0) / uploadProgress.length
    let defineFilesStatus = "0"
    // console.debug('update', fileNumber, percent, total)
    // progressBar.value = total
    setProgressBar(total)
    if (total === 100 && defineFiles !== "0") {
      /*if (defineFiles === '' && localStorage.getItem('defineFiles') != null) {
        setDefineFiles(localStorage.getItem('defineFiles'))
        defineFilesStatus = localStorage.getItem('defineFiles')
      } else {
        setDefineFiles('')
      }*/
      if (defineFiles.toString() === "1" || defineFilesStatus === "1") {
        setEditFilesNow(true)
        setUndefinedFiles(0)
      } else if (defineFiles.toString() === "2" || defineFilesStatus === "2") {
        setProgressBar(0)
        setSavedCount(0)
        setEditFilesNow(false)
        setUndefinedFiles(files.length)
        setFiles([])
        props.setFiles([])
      }
    }
  }

  function uploadData(file, i) {
    // if (parseInt(file.size) < 200000000){
    if (file.save && file.save === true) {
      updateProgress(i, 100)
    } else {
      // var url = 'https://api.cloudinary.com/v1_1/joezimim007/image/upload'
      var xhr = new XMLHttpRequest()
      var formData = new FormData()
      // xhr.open('POST', url, true)
      // xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

      // Update progress (can be used to show progress indicator)
      xhr.upload.addEventListener("progress", function (e) {
        updateProgress(i, (e.loaded * 100.0) / e.total || 100)
      })

      // xhr.addEventListener('readystatechange', function(e) {
      //   if (xhr.readyState == 4 && xhr.status == 200) {
      //     updateProgress(i, 100) // <- Add this
      //   }
      //   else if (xhr.readyState == 4 && xhr.status != 200) {
      //     // Error. Inform the user
      //   }
      // })
      let type = "images"
      let myVideoPlayer
      if (file.type.indexOf("video") > -1) {
        type = "videos"
        myVideoPlayer = document.getElementById(`video-element-${i}`)
        if (myVideoPlayer) {
          myVideoPlayer.addEventListener("loadedmetadata", function () {
            formData.append("channel", "turient")
            formData.append("duration", parseInt(myVideoPlayer.duration))
            formData.append("points", calculatePointsToSave(myVideoPlayer.duration))
            let tempName // = files[index].saved_name ? files[index].saved_name : files[index].name;
            tempName = file.name.replace(/\.[a-zA-Z0-9]{2,4}$/gi, "")

            formData.append("name", tempName)
            formData.append("video_file", file)
            if (file.size) {
              formData.append("size", file.size)
            }
            formData.append("is_added_to_library", addToResLib)
            formData.append("sequence", parseFloat(parseFloat(props.sequence) + (i + 1) * 10).toFixed(2))
            if (props.parentType) {
              if (props.parentType === "instruction") {
                formData.append("instruction", props.topic.id)
              } else if (props.parentType === "casestudy") {
                formData.append("casestudy", props.casestudy.id)
              }
            } else {
              formData.append("topic", props.topic.id)
            }
            if (file.id !== undefined) {
            } else if (file.save !== `loading`) {
              axios
                .post(process.env.GATSBY_REACT_APP_API_HOST + `/${type}/create/`, formData, { headers: login_credentials })
                .then((res) => {
                  file.id = res.data.id
                  file.duration = res.data.duration
                  file.points = res.data.points
                  file.description = res.description
                  file.title = res.data.name
                  file.created = res.data.created
                  file.channel = res.data.channel
                  file.display_name = res.data.display_name
                  updateProgress(i, 100)
                  file.save = true
                  file.avatar = res.data.avatar
                  if (parseInt(savedCount) + 1 === files.length) {
                    setProgressBar(100)
                    setSavedCount(0)
                  } else {
                    setSavedCount(parseInt(savedCount) + 1)
                  }
                })
                .catch((error) => {
                  file.save = false
                  let error_messages = ""
                  for (var key in error.response.data) {
                    error_messages += error.response.data[key] + "<br>"
                  }
                  if (error_messages === "Invalid token.<br>") {
                    authClient.signOut()
                  }
                  // hideMessage.displayErrorMsg(error_messages)
                })
            }
          })
        }
      } else {
        let { logo, file_type, type, isValid, file_type1 } = getImageTypeBasedOnFile(file)
        if (isValid === false) {
          // hideMessage.displayErrorMsg( file.name + ' is not supported. Only File Types supported: MP4/ PDF/ XLS/ PPT/ DOC/ JPG/PNG')
          setNotificationData({
            type: "error",
            title: file.name + " is not supported. Only File Types supported: MP4/ PDF/ XLS/ PPT/ DOC/ JPG/PNG",
          })
          return
        }
        type = "images"
        let tempName
        tempName = file.name.replace(/\.[a-zA-Z0-9]{2,4}$/gi, "")

        formData.append("name", tempName)

        formData.append("avatar", file)
        formData.append("size", file.size)
        formData.append("file_type", file_type1)
        formData.append("is_added_to_library", addToResLib)

        formData.append("sequence", parseFloat(parseFloat(props.sequence) + (i + 1) * 10).toFixed(2))
        if (props.parentType) {
          if (props.parentType === "instruction") {
            formData.append("instruction", props.topic.id)
          } else if (props.parentType === "casestudy") {
            formData.append("casestudy", props.casestudy.id)
          }
        } else {
          formData.append("topic", props.topic.id)
        }
        if (file.save !== `loading`) {
          var image_id = file.id
          file.save = `loading`
          axios
            .post(process.env.GATSBY_REACT_APP_API_HOST + `/${type}/create/`, formData, { headers: login_credentials })
            .then((res) => {
              file.id = res.data.id
              file.created = res.data.created
              file.display_name = res.data.display_name
              file.points = res.data.points
              updateProgress(i, 100)
              file.save = true
              if (parseInt(savedCount) + 1 === files.length) {
                setProgressBar(100)
                setSavedCount(0)
              } else {
                setSavedCount(parseInt(savedCount) + 1)
              }
            })
            .catch((error) => {
              file.save = false
              let error_messages = ""
              for (var key in error.response.data) {
                error_messages += error.response.data[key] + "<br>"
              }
              if (error_messages === "Invalid token.<br>") {
                authClient.signOut()

                localStorage.removeItem("authenticity_token")
                localStorage.removeItem("username")
                window.location.reload(true)
              }
              document.getElementById("fixed-error-div").innerHTML =
                '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
                '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
                '<span aria-hidden="true">×</span></button>' +
                error_messages +
                "</div>"
            })
        }
      }
    }
  }

  function calculatePointsToSave(d) {
    if (d) {
      d = Number(d)
      return Math.ceil(d / 60) > 0 ? Math.ceil(d / 60) : 1
    } else {
      return 1
    }
  }

  function deleteFiles(event, id, type = "images", delete_type = null, tempremove = null) {
    event.preventDefault()
    if (delete_type === null) {
      var index = files.findIndex((res) => res.id === id)
      setFileDeleted(true)
    }
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/${type}/` + id + "/", {
        headers: DefaultHeader,
      })
      .then((res) => {
        if (delete_type === null) {
          if (tempremove === "tempremove") {
            files[index].show_data = false
            setToggleValue(!toggleValue)
          } else {
            files.splice(index, 1)
          }
          let filexs
          if (tempremove === "tempremove" && Object.keys(files).filter((key) => files[key].show_data === undefined).length === 0) {
            filexs = []
          } else {
            filexs = files
          }
          setFiles([...filexs])
          props.setFiles([...filexs])
          setFileDeleted(false)
          if (filexs.length === 0) {
            if (!showDefineNow && Object.keys(videoArray).filter((key) => videoArray[key] === false).length === 0) {
              setShowDefineNow(true)
            }
            setUndefinedFiles(0)
            setEditFilesNow(false)
          }
        } else {
          videos_data[delete_type] = null
          let v_array = { ...videoArray }
          v_array[delete_type] = false
          // delete v_array[delete_type]
          // delete videos_data[delete_type]
          setVideosData(videos_data)
          setVideoArray(v_array)
          if (Object.keys(v_array).filter((key) => v_array[key] === false).length === link_index) {
            setAddMore(true)
            if (!showDefineNow && files.length === 0) {
              setShowDefineNow(true)
            }
          }
        }
      })
      .catch((error) => {
        if (delete_type === null) {
          setFileDeleted(false)
        }
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function uploadFiles() {
    let data = [...files]
    return (
      <div
        id={`drop-area`}
        className={`${Styles.drop_area} m-0 px-0 py-0 bg-transparent ${highlight === "highlight" ? `` : `border-0`} text-center ${
          files.length === 0 ? `` : `border-0`
        }`}
        onDragEnter={onDragEnter}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className={`w-100 col-12`}>
          {data && data.length === 0 ? (
            <Form className="my-form mt-3 mb-2">
              {/*<p className={`${StylesM.font_14}`} >Upload multiple images with the Browse Button <br/> or <br/> by dragging and dropping images onto the dashed region </p>*/}
              {undefinedFiles > 0 ? (
                <React.Fragment>
                  <div className="pb-3 d-inline-flex">
                    <img src={FolderIcon} alt={`white check`} className={`my-auto pointer`} style={{ objectFit: `contain` }} />
                    <u style={{ alignSelf: "flex-end" }}>undefined</u>
                  </div>
                  <p className={`${StylesM.font_14} mb-4`}>
                    {undefinedFiles} {undefinedFiles <= 1 ? ` file` : ` files`} have been uploaded successfully to undefined folder <br /> You can define the{" "}
                    {undefinedFiles <= 1 ? ` file` : ` files`} anytime you want!
                  </p>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <p className={`${StylesM.font_14} mb-2`}>Upload or drag and drop files</p>
                  <p className={`${StylesM.font_14} mb-4`}>Files Supported: MP4/ PDF/ XLS/ PPT/ DOC/ JPG/PNG</p>
                </React.Fragment>
              )}
              <input
                className={`d-none`}
                type="file"
                id="fileElem"
                multiple
                accept="image/*,application/pdf,video/*,.doc,.docx,.ppt,.pptx,.xlsx,.xls"
                onChange={(e) => {
                  e.preventDefault()
                  validateAndAdd(e)
                }}
              />
              <label
                className={`button btn border-0 px-5 border_on_hover ${StylesM.font_14}`}
                style={{
                  backgroundColor: `#586886`,
                  boxShadow: "0px 3px 6px #00000029",
                  color: "#E0E2E5",
                  borderRadius: "36px",
                }}
                htmlFor="fileElem"
              >
                Browse {undefinedFiles > 0 ? ` more files` : ``}
              </label>
              {/*progressBar === 0 && defineFiles !== '0' && (showDefineNow || undefinedFiles > 0 || files.length === 0) ?
                <div className={`text-right`} >
                  <Button onClick={(e)=>{e.preventDefault();setShowDefineModal(true)}} variant="transparent" className={`shadow-none text-white px-1 d-inline-flex ${StylesM.font_12} align-items-center`} > <FontAwesomeIcon color='#212C42' icon={faCheck} style={{ width: `15px`, height: `15px`, color: defineFiles === '1' ? `#E0E2E5` : `transparent`, border: `1px solid white` }} /> &nbsp;Define Now</Button>
                  <Button onClick={(e)=>{e.preventDefault();setShowDefineModal(true)}} variant="transparent" className={`shadow-none text-white px-1 d-inline-flex ${StylesM.font_12} align-items-center ml-1`} > <FontAwesomeIcon color='#212C42' icon={faCheck} style={{ width: `15px`, height: `15px`, color: defineFiles === '2' ? `#E0E2E5` : `transparent`, border: `1px solid white` }} /> &nbsp;Define Later</Button>
                </div>
                : null*/}
            </Form>
          ) : (
            <div className="col-12 d-inline-flex w-100 flex-wrap">
              <div id="gallery" className={`mt-2 ${StylesM.gallery}`}>
                {Object.keys(data).map((item, index) => {
                  if (data[item].type.indexOf("video") > -1) {
                    return (
                      <div key={index.toString() + `_image`} className={`float-left`} style={{ width: `149px` }}>
                        <div className={`${StylesM.card} m-2 p-3`}>
                          <div
                            className={StylesM.progress}
                            style={
                              index === data.length - 1
                                ? {
                                    width: data[item].save && progressBar ? `100%` : `100%`,
                                  }
                                : { width: data[item].save ? `100%` : `100%` }
                            }
                          ></div>
                          <div className={StylesM.content}>
                            <div className={``} style={{ position: `relative` }}>
                              <img
                                src={UploadVideo}
                                style={{
                                  width: `100px`,
                                  height: `100px`,
                                  objectFit: `contain`,
                                  margin: 0,
                                }}
                              />
                              {progressBar === 100 ? (
                                <FontAwesomeIcon
                                  onClick={(e) => {
                                    deleteFiles(e, data[item].id, "videos")
                                  }}
                                  className="pointer position-absolute"
                                  icon={faTrash}
                                  style={{
                                    marginBottom: "0px",
                                    color: `#161D34`,
                                    bottom: 0,
                                    top: `100%`,
                                    zIndex: 2,
                                    width: `10px`,
                                  }}
                                />
                              ) : (
                                ``
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`px-2`}
                          style={{
                            color: "#E0E2E5",
                            fontSize: "12px",
                            clear: "both",
                          }}
                        >
                          {data[item].name}
                        </div>
                        {data[item].save && progressBar === 100 ? null : (
                          <video className="d-none" style={{ width: "350px", height: "250px" }} id={`video-element-${index}`} controls>
                            <source src={URL.createObjectURL(data[item])} type="video/mp4" />
                          </video>
                        )}
                      </div>
                    )
                  } else {
                    let { logo, type, file_type, isValid } = getImageTypeBasedOnFile(data[item])
                    return (
                      <div key={index.toString() + `_image`} className={`float-left`} style={{ width: `149px` }}>
                        <div className={`${data[item].type.indexOf("pdf") > -1 ? StylesM.card : StylesM.card2} m-2 p-3`}>
                          <div
                            className={data[item].type.indexOf("pdf") > -1 ? StylesM.progress : StylesM.progress2}
                            style={
                              index === data.length - 1
                                ? {
                                    width: data[item].save && progressBar ? `100%` : `100%`,
                                  }
                                : { width: data[item].save ? `100%` : `100%` }
                            }
                          ></div>
                          <div className={StylesM.content}>
                            <div className={``} style={{ position: `relative` }}>
                              {file_type === "Image" ? (
                                <FontAwesomeIcon
                                  icon={logo}
                                  className={`my-0`}
                                  style={{
                                    color: "#67edfd",
                                    marginBottom: 0,
                                    width: `100px`,
                                    height: `100px`,
                                  }}
                                />
                              ) : (
                                <img
                                  src={logo}
                                  style={{
                                    width: `100px`,
                                    height: `100px`,
                                    objectFit: `contain`,
                                    marginBottom: 0,
                                  }}
                                />
                              )}
                              {progressBar === 100 ? (
                                <FontAwesomeIcon
                                  onClick={(e) => {
                                    deleteFiles(e, data[item].id)
                                  }}
                                  className="pointer position-absolute"
                                  icon={faTrash}
                                  style={{
                                    marginBottom: "0px",
                                    color: `#161D34`,
                                    bottom: 0,
                                    top: `100%`,
                                    zIndex: 2,
                                    width: `10px`,
                                  }}
                                />
                              ) : (
                                ``
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`px-2`}
                          style={{
                            color: "#E0E2E5",
                            fontSize: "12px",
                            clear: "both",
                          }}
                        >
                          {data[item].name}
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          )}
          {progressBar > 0 || files.length > 0 ? (
            <ProgressBar
              animated
              striped
              variant="info"
              now={progressBar == 0 ? 25 : progressBar}
              label={`${progressBar == 0 ? 25 : progressBar}%`}
              className={`w-50 mx-auto mb-3`}
              style={{ height: `12px`, fontSize: `8px` }}
            />
          ) : (
            ""
          )}
          {progressBar === 100 && defineFiles === "0" ? getDefineNow() : null}
        </div>
      </div>
    )
  }

  function validateAndAdd(event) {
    event.preventDefault()
    event.stopPropagation()
    let new_files = event.target.files
    let filex = []
    for (let i = 0; i < new_files.length; i++) {
      if (parseInt(new_files[i].size) < 200000000) {
        filex.push(new_files[i])
        setFiles([...files, ...filex])
        props.setFiles([...files, ...filex])
      } else {
        // hideMessage.displayErrorMsg( new_files[i].name + ' has size more than 200MB.')
        setNotificationData({
          type: "error",
          title: new_files[i].name + " has size more than 200MB.",
        })
      }
    }
  }

  function getDefineNow(type = null) {
    return (
      <React.Fragment>
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="pointer py-3 d-inline-flex w-100"
          onClick={(e) => {
            e.preventDefault()
            fetchStudentDefineFiles("1", type)
          }}
          style={{
            textAlign: "left",
            backgroundColor: "#586886",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "11px",
          }}
        >
          <div className="float-left align-left text-white w-100" style={{ width: "95%" }}>
            <p className="mb-2 font-weight-bold" style={{ fontSize: "16px" }}>
              Define Files Now{" "}
            </p>
            <p className="mb-2 pr-3" style={{ fontSize: "12px" }}>
              Choose this to add or edit your files details right after you upload them.{" "}
            </p>
            <div className={`d-inline-flex align-items-center`}>
              <FontAwesomeIcon
                color="#212C42"
                icon={faChevronRight}
                style={{
                  width: `30px`,
                  height: `30px`,
                  color: `transparent`,
                  border: `1px solid white`,
                }}
              />
              <div style={{ width: "calc(100% - 30px)" }}>
                <p className={`m-0 ml-2`} style={{ fontSize: "12px", color: "#E0E2E5" }}>
                  Make it default.
                  <br />
                  You can always uncheck it while uploading
                </p>
              </div>
            </div>
          </div>
          {/*<div className='float-right align-self-center' style={{position:'relative',top:'44%'}}>
            <div className={`float-right pointer d-inline-flex`} style={{ backgroundColor:'#E0E2E5',borderRadius:'50%', width: `25px`, height: `25px` }} ><FontAwesomeIcon icon={faChevronRight} className={`m-auto`} style={{ width: `15px`, height: `15px`, color: `#586985` }} /></div>
          </div>*/}
        </Col>
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="pointer mt-3 py-3 d-inline-flex w-100"
          onClick={(e) => {
            e.preventDefault()
            fetchStudentDefineFiles("2", type)
          }}
          style={{
            textAlign: "left",
            backgroundColor: "#586886",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "11px",
          }}
        >
          <div className="float-left align-left text-white w-100" style={{ width: "95%" }}>
            <p className="mb-2 font-weight-bold" style={{ fontSize: "16px" }}>
              I will do it Later
            </p>
            <p className="mb-2 pr-3" style={{ fontSize: "12px" }}>
              Choose this to add or edit details at your convenient time. You can access them in “Undefined” folder later.
            </p>
            <div className={`d-inline-flex align-items-center`}>
              <FontAwesomeIcon
                color="#212C42"
                icon={faChevronRight}
                style={{
                  width: `30px`,
                  height: `30px`,
                  color: `transparent`,
                  border: `1px solid white`,
                }}
              />
              <div style={{ width: "calc(100% - 30px)" }}>
                <p className={`m-0 ml-2`} style={{ fontSize: "12px", color: "#E0E2E5" }}>
                  Make it default.
                  <br />
                  You can always uncheck it while uploading
                </p>
              </div>
            </div>
          </div>
          {/*<div className='float-right align-self-center' style={{position:'relative',top:'44%'}}>
            <div className={`float-right pointer d-inline-flex`} style={{ backgroundColor:'#E0E2E5',borderRadius:'50%', width: `25px`, height: `25px` }} ><FontAwesomeIcon icon={faChevronRight} className={`m-auto`} style={{ width: `15px`, height: `15px`, color: `#586985` }} /></div>
          </div>*/}
        </Col>
      </React.Fragment>
    )
  }

  async function fetchStudentDefineFiles(value, type) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/fetch-learner-define-file/?update_define_type=${value}`, { headers: login_credentials })
      .then((res) => {
        // setDefineFiles(res.data.define_files)
        if (res.data.define_files === "1") {
          if (type === null) {
            setEditFilesNow(true)
          }
          setUndefinedFiles(0)
        } else if (res.data.define_files === "2") {
          setUndefinedFiles(files.length)
          setFiles([])
          props.setFiles([])
        }
        if (type === "modal") {
          setShowDefineModal(false)
        }
      })
      .catch((error) => {
        if (error.response) {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        }
        if (!error.response) {
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            "Please check your internet connection" +
            "</div>"
        }
      })
  }

  function editFiles() {
    let data = [...files]
    let modified_data = data.filter((key) => key.show_data === undefined)
    return (
      <div>
        {Object.keys(data).map((item, index) => {
          let file_index = modified_data.findIndex((x) => x.id === data[item].id) + 1
          let type = data[item].type.indexOf("video") > -1 ? "videos" : "images"
          type = data[item].link ? "links" : type
          return (
            <div
              className={`col-12 mb-2 px-0 py-1 ${data[item].show_data === false ? `d-none` : ``}`}
              key={index.toString() + `_image`}
              style={{
                backgroundColor: `#42506C`,
                position: `relative`,
                borderRadius: "9px",
              }}
            >
              <Col lg="12" md="12" sm="12" xs="12" className={`px-3 mx-0 py-1`} style={{ borderBottom: "1px solid #E0E2E5", height: "38px" }}>
                <p className={`float-left`} style={{ fontSize: "12px", color: "#E0E2E5" }}>{`File ${file_index}`}</p>
                <Dropdown className="breakout-groups pointer float-right">
                  <Dropdown.Toggle className="border-none p-0 shadow-none" style={{ backgroundColor: "transparent" }} id="dropdown-basic">
                    <div>
                      <FontAwesomeIcon
                        icon={faEllipsisV}
                        style={{
                          objectFit: `contain`,
                          height: `20px`,
                          color: `#E0E2E5`,
                        }}
                      />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={`br-9px breakout-groups ${Styles.min_fit_width}`}>
                    <Dropdown.Item
                      onClick={(e) => {
                        deleteFiles(e, data[item].id, type, null, "tempremove")
                      }}
                      className={`${StylesM.dropitem} text-white fs-14px px-2 py-1 d-inline-flex`}
                      style={{ minWidth: `max-content` }}
                    >
                      <img src={DeleteIcon} alt={`topic-delete`} className={`my-auto`} style={{ objectFit: `contain`, height: `13px` }} />
                      &nbsp;Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              {fileData(data[item], file_index)}
            </div>
          )
        })}
      </div>
    )
  }

  useEffect(() => {
    if (updateInputData && Object.keys(updateInputData).length > 0) {
      handleSubmitData(updateInputData.field, updateInputData.value, updateInputData.type, updateInputData.item)
    }
  }, [Object.keys(updateInputData).length > 0])

  function sendQuery(field, value, type, item) {
    setUpdateInputData({ field: field, value: value, type: type, item: item })
  }

  const delayedQuery = useCallback(
    _.debounce((field, value, type, item) => {
      sendQuery(field, value, type, item)
      dispLoaderToVal(type, item.id, false)
    }, 1000),
    []
  )

  function dispLoaderToVal(type, id, val) {
    Common.SetValForID(setLoader, type + id, val)
    if (type === "link_videos" || type === "link_images" || type === "link_links") {
      Common.SetValForID(setLoader, "type2", val)
    } else {
      Common.SetValForID(setLoader, "type1", val)
    }
  }
  function onBlurData(field, value, type, item) {
    if (field === "name" && type !== "link_videos" && type !== "link_images" && type !== "link_links") {
      if (value !== (item.saved_name ? item.saved_name : item.name)) {
        handleSubmitData(field, value, type, item)
      }
    } else if (item[field] !== value) {
      handleSubmitData(field, value, type, item)
    }
    dispLoaderToVal(type, item.id, false)
  }

  function settingLoader(type, id) {
    dispLoaderToVal(type, id, true)
    setToggleValue(!toggleValue)
  }
  function getImageTypeBasedOnFile(fileObj) {
    let file_type,
      logo,
      type = "images",
      isValid = true,
      file_type1 = 0
    if (fileObj.type === undefined) {
      type = "links"
      file_type = "Link"
      logo = fileObj.icon
    } else if (fileObj.type.indexOf("image/") > -1) {
      file_type = "Image"
      logo = faImage
      file_type1 = 0
    } else if (fileObj.type.indexOf("/pdf") > -1) {
      file_type = "PDF"
      logo = UploadBlog
      file_type1 = 1
    } else if (fileObj.type.indexOf("video/") > -1) {
      logo = UploadVideo
      type = "videos"
      file_type = "Video"
    } else if (
      fileObj.type.indexOf("application/vnd.ms-excel") > -1 ||
      fileObj.type.indexOf("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") > -1
    ) {
      logo = excel
      type = "images"
      file_type = "Excel document"
      file_type1 = 3
    } else if (
      fileObj.type.indexOf("application/vnd.openxmlformats-officedocument.presentationml.presentation") > -1 ||
      fileObj.type.indexOf("application/vnd.ms-powerpoint") > -1
    ) {
      logo = ppt
      type = "images"
      file_type = "PowerPoint Presentation"
      file_type1 = 4
    } else if (
      fileObj.type.indexOf("application/msword") > -1 ||
      fileObj.type.indexOf("application/vnd.openxmlformats-officedocument.wordprocessingml.document") > -1
    ) {
      logo = doc
      type = "images"
      file_type = "Word document"
      file_type1 = 2
    } else {
      isValid = false
    }
    return { logo, type, file_type, isValid, file_type1 }
  }
  function humanFileSize(size) {
    var i = Math.floor(Math.log(size) / Math.log(1024))
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + " " + ["B", "kB", "MB", "GB", "TB"][i]
  }

  function fileData(currentFile, file_index) {
    const size = humanFileSize(currentFile.size) //(currentFile.size/1000000).toFixed(2)
    let { logo, type, file_type, isValid } = getImageTypeBasedOnFile(currentFile)
    let name = currentFile.saved_name ? currentFile.saved_name : currentFile.name
    let dispName = currentFile.display_name
    name = name.replace(/\.[a-zA-Z0-9]{2,4}$/gi, "")
    dispName = dispName.replace(/\.[a-zA-Z0-9]{2,4}$/gi, "")

    return (
      <div
        className={`text-left py-2 mb-2 col-12 ${currentFile && currentFile.show_data === false ? `d-none` : `d-inline-flex`} w-100`}
        id={currentFile.id ? currentFile.id : "currentFile"}
      >
        {file_type === "Image" ? (
          <FontAwesomeIcon
            icon={logo}
            className={`my-0`}
            style={{
              color: "#67edfd",
              marginBottom: 0,
              width: `80px`,
              height: `80px`,
            }}
          />
        ) : (
          <img
            src={logo}
            style={{
              width: `80px`,
              height: `80px`,
              objectFit: `contain`,
              marginBottom: 0,
            }}
          />
        )}
        <div className="ml-2" style={{ width: "43%" }}>
          <Form className="form-horizontal">
            <Col xs="12" sm="6" md="12" className="p-0 mb-2">
              <InputGroup className={`border_on_hover m-0 d-flex w-100 ${StylesM.upload_edit_abovediv}`}>
                <InputGroup.Prepend>
                  <InputGroup.Text className={`bg-transparent border-0 pr-0 pl-2 ${StylesM.font_14}`} style={{ color: "#E0E2E5", opacity: "41%" }}>
                    Name:
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {fileDeleted ? (
                  <FormControl
                    placeholder=""
                    className={`text-white border-0 ${StylesM.upload_edit_input} ${StylesM.extra_input} ${StylesM.font_14} shadow-none pl-1`}
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => {
                      e.preventDefault()
                      settingLoader(type, currentFile.id)
                      delayedQuery(e.target.name, e.target.value, type, currentFile)
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      onBlurData(e.target.name, e.target.value, type, currentFile)
                    }}
                    required
                  />
                ) : (
                  <FormControl
                    placeholder=""
                    className={`text-white border-0 ${StylesM.upload_edit_input} ${StylesM.extra_input} ${StylesM.font_14} shadow-none pl-1`}
                    type="text"
                    name="name"
                    defaultValue={name}
                    onChange={(e) => {
                      e.preventDefault()
                      settingLoader(type, currentFile.id)
                      delayedQuery(e.target.name, e.target.value, type, currentFile)
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      onBlurData(e.target.name, e.target.value, type, currentFile)
                    }}
                    required
                  />
                )}
              </InputGroup>
            </Col>
            <Col xs="12" sm="6" md="12" className="p-0 mb-2">
              <InputGroup className={`border_on_hover m-0 d-flex w-100 ${StylesM.upload_edit_abovediv}`}>
                <InputGroup.Prepend>
                  <InputGroup.Text className={`bg-transparent border-0 pr-0 pl-2 ${StylesM.font_14}`} style={{ color: "#E0E2E5", opacity: "41%" }}>
                    Display Name:
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {fileDeleted ? (
                  <FormControl
                    placeholder=""
                    className={`text-white border-0 ${StylesM.upload_edit_input} ${StylesM.extra_input} ${StylesM.font_14} shadow-none pl-1`}
                    type="text"
                    name="display_name"
                    value={dispName}
                    onChange={(e) => {
                      e.preventDefault()
                      settingLoader(type, currentFile.id)
                      delayedQuery(e.target.name, e.target.value, type, currentFile)
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      onBlurData(e.target.name, e.target.value, type, currentFile)
                    }}
                    required
                  />
                ) : (
                  <FormControl
                    placeholder=""
                    className={`text-white border-0 ${StylesM.upload_edit_input} ${StylesM.extra_input} ${StylesM.font_14} shadow-none pl-1`}
                    type="text"
                    name="display_name"
                    defaultValue={dispName}
                    onChange={(e) => {
                      e.preventDefault()
                      settingLoader(type, currentFile.id)
                      delayedQuery(e.target.name, e.target.value, type, currentFile)
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      onBlurData(e.target.name, e.target.value, type, currentFile)
                    }}
                    required
                  />
                )}
              </InputGroup>
            </Col>
            <Col xs="12" sm="6" md="12" className="p-0 mb-2">
              <FormGroup className={`border_on_hover m-0 d-flex w-100 ${StylesM.upload_edit_abovediv}`}>
                <p className="pl-2 m-0 py-2" style={{ color: "#E0E2E5", opacity: "41%", fontSize: "14px" }}>
                  Category:{" "}
                </p>
                <p style={{ fontSize: "14px", flexGrow: "1" }} className={`py-2 pl-1 m-0 shadow-none ${StylesM.upload_edit_input}`}>
                  {" "}
                  {file_type}{" "}
                </p>
              </FormGroup>
            </Col>
            <AddExtraFields
              hide={"duration"}
              fileDeleted={fileDeleted}
              currentFile={currentFile}
              handleSubmitData={handleSubmitData}
              type={type}
              files={files}
              videos_data={videos_data}
              settingLoader={settingLoader}
            />
            {/*<Button type='submit' className='box-shadow pl-4 pr-4' style={{background: '#485b9e', border: 'none'}} > Save </Button>*/}
            <Col xs="12" sm="6" md="12" className="p-0 mb-2">
              <Form.Check
                className="mt-2"
                style={{ fontSize: "14px" }}
                type={"checkbox"}
                name="is_added_to_library"
                id={`is_added_to_library_${file_index}`}
                label={`Add this to the resource library`}
                defaultChecked={addToResLib}
                onChange={(e) => sendQuery(e.target.name, e.target.checked, type === "documents" ? "images" : type, currentFile)}
              />
            </Col>
          </Form>
        </div>
        <div className="p-2 pl-4 ml-0 float-left" style={{ fontSize: "12px", color: "#BBBFCE" }}>
          <p className="mb-0">Uploaded on: {currentFile && currentFile.created ? utcDateTime.utcDateTime(currentFile.created, "display_dt") : ``}</p>
          <p className="mb-0">Uploaded By: {localStorage.getItem("username")} </p>
          <p className="mb-0">File name: {currentFile.name} </p>
          <p className="mb-0">File type: {currentFile.type}</p>
          <p className="mb-0">File size: {size}</p>
          <svg
            className={`animate-spin my-auto ${loader[type + currentFile.id] ? `` : `d-none`}`}
            style={{ height: `16px`, width: `16px` }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      </div>
    )
  }

  function handleSubmitData(field, value, type, item) {
    if (value === "") {
      dispLoaderToVal(type, item.id, false)
      setToggleValue(!toggleValue)
      return
    }

    var formData = new FormData()
    formData.append(field, value)
    if (type.replace("link_", "") === "videos") {
      formData.append("channel", item.channel)
    }
    var index = files.findIndex((res) => res.id === item.id)
    if (type === "link_videos" || type === "link_images" || type === "link_links") {
      index = Object.keys(videos_data).filter((key) => videos_data[key].id === item.id)
      index = index.length > 0 ? index[0] : -1
    }
    if (field !== "name" && type !== "link_videos" && type !== "link_images" && type !== "link_links") {
      let tempName = files[index].saved_name ? files[index].saved_name : files[index].name
      tempName = tempName.replace(/\.[a-zA-Z0-9]{2,4}$/gi, "")

      formData.append("name", tempName)
    } else if (field !== "name" && index !== -1) {
      formData.append("name", videos_data[index].name)
    }
    if (item.id != undefined) {
      var file_id = item.id
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + `/${type.replace("link_", "")}/` + file_id + "/", formData, { headers: login_credentials })
        .then((res) => {
          if (type !== "link_videos" && type !== "link_images" && type !== "link_links") {
            let file = files[index]
            var blob = file.slice(0, file.size, file.type)
            let newFile = new File([blob], item.name, { type: file.type })
            newFile.id = file.id
            newFile.created = file.created
            newFile.save = file.save
            newFile.saved_name = res.data.name
            newFile.display_name = res.data.display_name
            newFile.description = res.data.description
            newFile.points = res.data.points
            newFile.duration = res.data.duration
            newFile.edited = true
            newFile.is_added_to_library = res.data.is_added_to_library
            files[index] = newFile
            dispLoaderToVal(type, item.id, false)
            //loader[type+item.id] = false
            //loader[`type1`] = false
            setToggleValue(!toggleValue)
            // document.body.click()
          } else {
            if (index != -1) {
              videos_data[index].edited = true
              videos_data[index][field] = res.data[field]
              //videos_data[index].is_added_to_library = res.data.is_added_to_library
              dispLoaderToVal(type, item.id, false)

              //loader[type+item.id] = false
              //loader[`type2`] = false
              setToggleValue(!toggleValue)
            }
          }
          setUpdateInputData({})
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  function closeDefineModal() {
    setShowDefineModal(false)
  }

  function defineModal() {
    return (
      <Modal
        show={showDefineModal}
        onHide={closeDefineModal}
        scrollable={true}
        size="lg"
        dialogClassName={`w-50`}
        className={`${StylesM.classroom_delete_modal} classroom-resource-delete-modal mx-auto mb-auto`}
        style={{ left: `10%`, top: `40%` }}
      >
        <Modal.Header className={`${StylesM.delete_modal_header}`}>
          <p style={{ color: "#E0E2E5", fontWeight: "18px" }}>Change your default option</p>
          <img
            onClick={(e) => {
              e.preventDefault()
              setShowDefineModal(false)
            }}
            src={CancelWhite}
            alt={`Cancel`}
            className={`my-auto mr-1 pointer`}
            style={{
              objectFit: `contain`,
              height: `15px`,
              position: "absolute",
              top: "10px",
              right: "5px",
            }}
          />
        </Modal.Header>
        <Modal.Body
          className="align-items-center"
          style={{
            backgroundColor: "#1a263a",
            borderRadius: `0px 0px 8px 8px`,
          }}
        >
          {getDefineNow("modal")}
        </Modal.Body>
      </Modal>
    )
  }

  function showVideoLinkData(i) {
    let currentFile = videos_data[i]
    let type = videos_data[i].type === "video" ? "link_videos" : "link_images"
    type = videos_data[i].link ? "link_links" : type
    let doc_logo = doc
    if (videos_data[i].type === "images") {
      if (videos_data[i].doc_type == "document") {
        doc_logo = doc
      } else if (videos_data[i].doc_type == "presentation") {
        doc_logo = ppt
      } else if (videos_data[i].doc_type == "spreadsheets") {
        doc_logo = excel
      }
    }
    let imgObj = video.thumbnail_url || videos_data[i].icon
    return (
      <div className="text-left py-3 col-12 d-inline-flex w-100">
        {videos_data[i].type == "video" || videos_data[i].link ? (
          <img
            src={imgObj}
            className={`mt-3`}
            style={{
              height: `${imgObj ? "auto" : 0}`,
              width: `80px`,
              objectFit: `contain`,
              marginBottom: 0,
              borderRadius: `8px`,
            }}
          />
        ) : (
          <img
            src={doc_logo}
            className={`mt-3`}
            style={{
              height: `auto`,
              width: `80px`,
              objectFit: `contain`,
              marginBottom: 0,
              borderRadius: `8px`,
            }}
          />
        )}
        <div className="ml-2" style={{ width: "52%" }}>
          <Form className="form-horizontal mb-0">
            <Col xs="12" sm="6" md="12" className="p-0 mb-2">
              <InputGroup className={`border_on_hover m-0 d-flex w-100 ${StylesM.upload_edit_abovediv}`}>
                <InputGroup.Prepend>
                  <InputGroup.Text className={`bg-transparent border-0 pr-0 pl-2 ${StylesM.font_14}`} style={{ color: "#E0E2E5", opacity: "41%" }}>
                    Name:
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {fileCreated ? (
                  <FormControl
                    placeholder=""
                    className={`text-white border-0 ${StylesM.upload_edit_input} ${StylesM.extra_input} ${StylesM.font_14} shadow-none pl-1`}
                    type="text"
                    name="name"
                    value={currentFile.name}
                    onChange={(e) => {
                      e.preventDefault()
                      settingLoader(type, currentFile.id)
                      delayedQuery(e.target.name, e.target.value, type, currentFile)
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      onBlurData(e.target.name, e.target.value, type, currentFile)
                    }}
                    required
                  />
                ) : (
                  <FormControl
                    placeholder=""
                    className={`text-white border-0 ${StylesM.upload_edit_input} ${StylesM.extra_input} ${StylesM.font_14} shadow-none pl-1`}
                    type="text"
                    name="name"
                    defaultValue={currentFile.name}
                    onChange={(e) => {
                      e.preventDefault()
                      settingLoader(type, currentFile.id)
                      delayedQuery(e.target.name, e.target.value, type, currentFile)
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      onBlurData(e.target.name, e.target.value, type, currentFile)
                    }}
                    required
                  />
                )}
              </InputGroup>
            </Col>
            <Col xs="12" sm="6" md="12" className={`p-0 mb-2 ${videos_data[i].link ? `d-none` : ``}`}>
              <InputGroup className={`border_on_hover m-0 d-flex w-100 ${StylesM.upload_edit_abovediv}`}>
                <InputGroup.Prepend>
                  <InputGroup.Text className={`bg-transparent border-0 pr-0 pl-2 ${StylesM.font_14}`} style={{ color: "#E0E2E5", opacity: "41%" }}>
                    Display Name:
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {fileCreated ? (
                  <FormControl
                    placeholder=""
                    className={`text-white border-0 ${StylesM.upload_edit_input} ${StylesM.extra_input} ${StylesM.font_14} shadow-none pl-1`}
                    type="text"
                    name="display_name"
                    value={currentFile.display_name}
                    onChange={(e) => {
                      e.preventDefault()
                      settingLoader(type, currentFile.id)
                      delayedQuery(e.target.name, e.target.value, type, currentFile)
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      onBlurData(e.target.name, e.target.value, type, currentFile)
                    }}
                    required
                  />
                ) : (
                  <FormControl
                    placeholder=""
                    className={`text-white border-0 ${StylesM.upload_edit_input} ${StylesM.extra_input} ${StylesM.font_14} shadow-none pl-1`}
                    type="text"
                    name="display_name"
                    defaultValue={currentFile.display_name}
                    onChange={(e) => {
                      e.preventDefault()
                      settingLoader(type, currentFile.id)
                      delayedQuery(e.target.name, e.target.value, type, currentFile)
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      onBlurData(e.target.name, e.target.value, type, currentFile)
                    }}
                    required
                  />
                )}
              </InputGroup>
            </Col>
            <Col xs="12" sm="6" md="12" className="p-0 mb-2">
              <FormGroup className={`border_on_hover m-0 d-flex w-100 ${StylesM.upload_edit_abovediv}`}>
                <p className="pl-2 m-0 py-2" style={{ color: "#E0E2E5", opacity: "41%", fontSize: "14px" }}>
                  Category:{" "}
                </p>
                <p style={{ fontSize: "14px", flexGrow: "1" }} className={`py-2 pl-1 m-0 shadow-none ${StylesM.upload_edit_input}`}>
                  {videos_data[i].type == "video" ? "Video" : videos_data[i].link ? "Link" : "Google " + videos_data[i].doc_type}{" "}
                </p>
              </FormGroup>
            </Col>
            <AddExtraFields
              hide={"null"}
              fileDeleted={false}
              currentFile={currentFile}
              handleSubmitData={handleSubmitData}
              type={type}
              files={files}
              videos_data={videos_data}
              settingLoader={settingLoader}
            />
            {/*<Col xs="12" sm="6" md="12" className='p-0 mb-2'>
            <Form.Check className="mt-2" style={{fontSize: '14px'}} type={"switch"}
                 name="is_added_to_library" id={`is_added_to_library_${i}`}
                 label={`Add this ${type} to the resource library`}
                 checked={currentFile.is_added_to_library ?? true}
                 onChange={(e) => sendQuery(e.target.name,e.target.checked,type,currentFile)}
                />
            </Col>
              */}
          </Form>
        </div>
        <div className="p-2 pl-4 ml-0 float-left" style={{ fontSize: "12px", color: "#BBBFCE" }}>
          <p className="mb-0">Uploaded on: {video && video.created ? utcDateTime.utcDateTime(video.created, "display_dt") : ``}</p>
          <p className="mb-0">Uploaded By: {localStorage.getItem("username")} </p>
          <p className="mb-0" style={{ wordBreak: "break-all" }}>
            Url: {videos_data[i].url || videos_data[i].link}{" "}
          </p>
          {video.channel ? <p className="mb-0">File type: {video.channel}</p> : null}
          <svg
            className={`animate-spin my-auto ${loader[type + currentFile.id] ? `` : `d-none`}`}
            style={{ height: `16px`, width: `16px` }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      </div>
    )
  }

  function videoLinkHeader(i, type = null) {
    return (
      <InputGroup key={`link_div-${i}`} id={`link_div-${i}`} className={`py-1 align-items-center`}>
        <InputGroup.Prepend>
          <InputGroup.Text id={`basic-addon-${i}`} className={`bg-transparent border-0 pr-0`}>
            <FontAwesomeIcon
              icon={faLink}
              className={`m-auto`}
              style={{
                color: "#828B9D",
                marginBottom: "10px",
                width: `20px`,
                height: `20px`,
              }}
            />
          </InputGroup.Text>
        </InputGroup.Prepend>
        {type === null ? (
          <FormControl
            placeholder="Add Vimeo/ YouTube Link/ Google Docs Link/ Link "
            aria-label="Username"
            aria-describedby={`basic-addon-${i}`}
            id={i}
            style={{ color: `#828B9D` }}
            className={`bg-transparent border-0 pl-2 shadow-none ${StylesM.upload_input} ${StylesM.font_14}`}
            onKeyUp={(e) => {
              clearTimeout(typingTimer)
              setCurrentLinkIndex(i)
              setUrl(e.target.value)
              videoLoadEnable(i, true)
              setTypingTimer(setTimeout(fetchVideoData, doneTypingInterval))
            }}
            onKeyDown={(e) => {
              clearTimeout(typingTimer)
              setUrl(e.target.value)
              videoLoadEnable(i, false)
            }}
            onChange={(e) => {
              clearTimeout(typingTimer)
              setCurrentLinkIndex(i)
              setUrl(e.target.value)
              videoLoadEnable(i, true)
              setTypingTimer(setTimeout(fetchVideoData, doneTypingInterval))
            }}
          />
        ) : (
          <p
            style={{ color: `#828B9D`, overflowWrap: "anywhere" }}
            className={`bg-transparent border-0 pl-2 shadow-none m-0 align-self-center ${StylesM.upload_input} ${StylesM.font_14}`}
          >
            {videos_data[i].name || videos_data[i].link}
          </p>
        )}
        <InputGroup.Append className={type === null ? `` : `ml-auto`}>
          {video_loading[i] ? (
            <InputGroup.Text className={`bg-transparent border-0`} id={`basic-addon${i}`}>
              <Spinner animation="border" variant="danger" size="sm" />
            </InputGroup.Text>
          ) : null}
          <FontAwesomeIcon
            onClick={(e) => {
              e.preventDefault()
              setSelectedIndex(selected_index === i ? -1 : i)
              setVideo(videos_data[i])
            }}
            className="mr-3 align-self-center pointer"
            icon={selected_index !== i ? faChevronCircleDown : faChevronCircleUp}
            style={{ width: `20px`, height: `20px`, color: `#E0E2E5` }}
          />
        </InputGroup.Append>
        {type === null ? (
          ""
        ) : (
          <Dropdown className="breakout-groups pointer float-right mr-3">
            <Dropdown.Toggle className="border-none p-0 shadow-none" style={{ backgroundColor: "transparent" }} id="dropdown-basic">
              <div>
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  style={{
                    objectFit: `contain`,
                    height: `20px`,
                    color: `#E0E2E5`,
                  }}
                />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className={`br-9px breakout-groups ${Styles.min_fit_width}`}>
              <Dropdown.Item
                onClick={(e) => {
                  e.preventDefault()
                  removeVideo(e, i, videos_data[i].type)
                }}
                className={`${StylesM.dropitem} text-white fs-14px px-2 py-1 d-inline-flex`}
                style={{ minWidth: `max-content` }}
              >
                <img src={DeleteIcon} alt={`topic-delete`} className={`my-auto`} style={{ objectFit: `contain`, height: `13px` }} />
                &nbsp;Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </InputGroup>
    )
  }

  function showInputs() {
    const buttons = []
    for (let i = 0; i < link_index; i++) {
      if (videos_data[i]) {
        let background_url = videos_data[i].thumbnail_url
        buttons.push(
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            key={`link_div-${i}`}
            className={`p-0 mb-3 pointer ${defineFiles !== "1" ? `d-flex-align-center` : ``}`}
            style={{
              position: "relative",
              borderRadius: "10px",
              backgroundColor: defineFiles === "1" ? `#42506C` : `transparent`,
            }}
          >
            {defineFiles !== "1" ? (
              <React.Fragment>
                <Col xs="2" sm="2" md="2" lg="2" className="px-0 text-center d-flex-align-center">
                  <img
                    style={{
                      height: `auto`,
                      objectFit: `contain`,
                      marginBottom: 0,
                      borderRadius: "8px",
                    }}
                    src={background_url}
                    alt="video_background_image"
                    layout="fill"
                    objectFit={`contain`}
                  />
                </Col>
                <Col xs="10" sm="10" md="10" lg="10" style={{ color: `#E0E2E5` }}>
                  <h6 className={`m-0`}>{videos_data[i].title}</h6>
                  <p style={{ fontSize: "12px" }} className={`m-0`}>
                    {secondsToHms(videos_data[i].duration)}
                  </p>
                  <p style={{ fontSize: "12px" }} className={`m-0`}>
                    {" "}
                    Points: {calculatePoints(videos_data[i].duration)}
                  </p>
                  <FontAwesomeIcon
                    onClick={(e) => {
                      e.preventDefault()
                      removeVideo(e, i, videos_data[i].type)
                    }}
                    icon={faTrash}
                    size="sm"
                    style={{ color: "#E0E2E5" }}
                  />
                </Col>
              </React.Fragment>
            ) : (
              <React.Fragment>{videoLinkHeader(i, "saved_link")}</React.Fragment>
            )}
            {defineFiles === "1" ? (
              <div className={`${selected_index === i ? `` : `d-none`}`} style={{ borderTop: `1px solid #E0E2E5` }}>
                {video ? showVideoLinkData(i) : ``}
              </div>
            ) : null}
          </Col>
        )
      } else if (defaultLink || videoArray[i] !== false) {
        buttons.push(
          <div className="mb-3" key={`link_div-${i}`} style={{ backgroundColor: `#42506C`, borderRadius: `9px` }}>
            {videoLinkHeader(i)}
            <div className={`${selected_index === i ? `d-inline-flex w-100` : `d-none`}`} style={{ height: `100px`, borderTop: `1px solid #E0E2E5` }}>
              <p className={`${StylesM.font_14} m-auto`} style={{ color: `#828B9D` }}>
                Details about the links appears here. You can edit the metadata before saving
              </p>
            </div>
          </div>
        )
      } else if (Object.keys(videoArray).filter((key) => videoArray[key] === false).length === link_index) {
        return (
          <InputGroup key={`link_div-${i}`} className="mb-3" id={`link_div-${i}`} style={{ backgroundColor: `#42506C` }}>
            <InputGroup.Prepend>
              <InputGroup.Text id={`basic-addon-${i}`} className={`bg-transparent border-0 pr-0`}>
                <FontAwesomeIcon
                  icon={faLink}
                  className={`m-auto`}
                  style={{
                    color: "#828B9D",
                    marginBottom: "10px",
                    width: `20px`,
                    height: `20px`,
                  }}
                />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Add Vimeo/ YouTube Link/ Google Docs Link/ Link"
              aria-label="Username"
              aria-describedby={`basic-addon-${i}`}
              id={i}
              style={{ color: `#828B9D` }}
              className={`bg-transparent border-0 pl-2 shadow-none ${StylesM.upload_input}`}
              onKeyUp={(e) => {
                clearTimeout(typingTimer)
                setCurrentLinkIndex(i)
                setUrl(e.target.value)
                videoLoadEnable(i, true)
                setTypingTimer(setTimeout(fetchVideoData, doneTypingInterval))
              }}
              onKeyDown={(e) => {
                clearTimeout(typingTimer)
                setUrl(e.target.value)
                videoLoadEnable(i, false)
              }}
              onChange={(e) => {
                clearTimeout(typingTimer)
                setCurrentLinkIndex(i)
                setUrl(e.target.value)
                videoLoadEnable(i, true)
                setTypingTimer(setTimeout(fetchVideoData, doneTypingInterval))
              }}
            />
            {video_loading[i] ? (
              <InputGroup.Append>
                <InputGroup.Text className={`bg-transparent border-0`} id={`basic-addon${i}`}>
                  <Spinner animation="border" variant="danger" size="sm" />
                </InputGroup.Text>
              </InputGroup.Append>
            ) : null}
          </InputGroup>
        )
      }
    }
    return buttons
  }

  function videoLoadEnable(index, value) {
    let v_loading = { ...video_loading }
    v_loading[index] = value
    setVideoLoading(v_loading)
  }

  function fetchVideoData(event) {
    let parsed_video = parseVideo(url)
    if (parsed_video.type === "youtube") {
      axios
        .get(`https://www.youtube.com/oembed?url=${url}&format=json`, {
          headers: login_credentials,
        })
        .then((res) => {
          if (res.data.title !== undefined) {
            videos_data[currentLinkIndex] = res.data
            videos_data[currentLinkIndex]["url"] = url
            videos_data[currentLinkIndex]["channel"] = "youtube"
            videos_data[currentLinkIndex]["sequence"] = props.sequence
            videos_data[currentLinkIndex]["video_id"] = parsed_video.id
            videos_data[currentLinkIndex]["duration"] = res.data.duration ? res.data.duration : 0
            videos_data[currentLinkIndex]["points"] = calculatePointsToSave(res.data.duration)
            videos_data[currentLinkIndex]["name"] = res.data.title
            videos_data[currentLinkIndex]["display_name"] = res.data.title

            videoLoadEnable(currentLinkIndex, false)
            setFileCreated(true)
            // setVideo(videos_data[currentLinkIndex])
            handleSubmit(videos_data[currentLinkIndex])
            let v_array = { ...videoArray }
            v_array[currentLinkIndex] = true
            setVideosData(videos_data)
            setVideoArray(v_array)
            setAddMore(false)
            setSelectedIndex(currentLinkIndex)
            if (showDefineNow) {
              setShowDefineNow(false)
            }
          } else {
            videoLoadEnable(currentLinkIndex, false)
            document.getElementById("fixed-error-div").innerHTML =
              '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
              '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
              '<span aria-hidden="true">×</span></button>We could not fetch the details. Please check your privacy settings for this video on youtube</div>'
          }
        })
    } else if (parsed_video.type === "vimeo") {
      axios
        .get(`https://vimeo.com/api/oembed.json?url=${url}`, {
          "Access-Control-Allow-Origin": "*",
        })
        .then((res) => {
          if (res.data.title !== undefined) {
            videos_data[currentLinkIndex] = res.data
            videos_data[currentLinkIndex]["url"] = url
            videos_data[currentLinkIndex]["channel"] = "vimeo"
            videos_data[currentLinkIndex]["sequence"] = props.sequence
            videos_data[currentLinkIndex]["duration"] = res.data.duration ? res.data.duration : 0
            videos_data[currentLinkIndex]["points"] = calculatePointsToSave(res.data.duration)
            videos_data[currentLinkIndex]["name"] = res.data.title
            videos_data[currentLinkIndex]["display_name"] = res.data.title

            videoLoadEnable(currentLinkIndex, false)
            setFileCreated(true)
            // setVideo(videos_data[currentLinkIndex])
            handleSubmit(videos_data[currentLinkIndex])
            let v_array = { ...videoArray }
            v_array[currentLinkIndex] = true
            setVideosData(videos_data)
            setVideoArray(v_array)
            setAddMore(false)
            setSelectedIndex(currentLinkIndex)
            if (showDefineNow) {
              setShowDefineNow(false)
            }
          } else {
            videoLoadEnable(currentLinkIndex, false)
            document.getElementById("fixed-error-div").innerHTML =
              '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
              '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
              '<span aria-hidden="true">×</span></button>We could not fetch the details. Please check your privacy settings for this video on vimeo</div>'
          }
        })
    } else if (parsed_video.type === "google") {
      let { type, doc_type, doc_id, doc_name } = getGoogleDocsDetails(url)
      videos_data[currentLinkIndex] = {}
      videos_data[currentLinkIndex]["url"] = url
      //videos_data[currentLinkIndex]['channel'] = 'Google'+' '+doc_type;
      videos_data[currentLinkIndex]["sequence"] = props.sequence
      videos_data[currentLinkIndex]["doc_type"] = doc_type
      videos_data[currentLinkIndex]["doc_id"] = doc_id
      videos_data[currentLinkIndex]["type"] = "images"
      videos_data[currentLinkIndex]["edited"] = false
      //videos_data[currentLinkIndex]['duration'] = res.data.duration ? res.data.duration : 0;
      //videos_data[currentLinkIndex]['points'] = calculatePointsToSave(res.data.duration);
      videos_data[currentLinkIndex]["name"] = doc_name
      videos_data[currentLinkIndex]["display_name"] = doc_name
      videoLoadEnable(currentLinkIndex, false)
      setFileCreated(true)
      setVideo(videos_data[currentLinkIndex])
      //handleSubmit(videos_data[currentLinkIndex])
      uploadGoogleDocs(url, props.sequence)
      let v_array = { ...videoArray }
      v_array[currentLinkIndex] = true
      setVideosData(videos_data)
      setVideoArray(v_array)
      setAddMore(false)
      setSelectedIndex(currentLinkIndex)
      if (showDefineNow) {
        setShowDefineNow(false)
      }
      setVideoLoading(currentLinkIndex, false)
    } else {
      let link_url = url
      if (link_url.indexOf("http://") == -1 && link_url.indexOf("https://") == -1) {
        link_url = "https://" + link_url
      }
      let is_valid_link = Common.isValidURL(link_url)
      if (is_valid_link) {
        videos_data[currentLinkIndex] = {}
        videos_data[currentLinkIndex]["link"] = url
        videos_data[currentLinkIndex]["sequence"] = props.sequence
        videos_data[currentLinkIndex]["type"] = "link"
        videoLoadEnable(currentLinkIndex, false)
        setFileCreated(true)
        // setVideo(videos_data[currentLinkIndex])
        handleSubmit(videos_data[currentLinkIndex], "link")
        let v_array = { ...videoArray }
        v_array[currentLinkIndex] = true
        setVideosData(videos_data)
        setVideoArray(v_array)
        setAddMore(false)
        setSelectedIndex(currentLinkIndex)
        if (showDefineNow) {
          setShowDefineNow(false)
        }
      } else {
        videoLoadEnable(currentLinkIndex, false)
        // hideMessage.displayErrorMsg('We could not fetch the details. Please provide valid link')
        setNotificationData({
          type: "error",
          title: "We could not fetch the details. Please provide valid link",
        })
      }
    }
  }

  function getGoogleDocsDetails(link) {
    let doc_type = link.split("https://docs.google.com/")[1].split("/")[0]
    let doc_id = link.split("https://docs.google.com/")[1].split("/")[2]
    let type
    if (doc_type === "document") {
      type = 5
    } else if (doc_type === "presentation") {
      type = 6
    } else if (doc_type === "spreadsheets") {
      type = 7
    }
    let doc_name = "Google" + " " + doc_type + " " + doc_id
    return { type, doc_type, doc_id, doc_name }
  }

  function uploadGoogleDocs(link, sequence) {
    setHideCloseButton(false)

    let { type, doc_id, doc_name } = getGoogleDocsDetails(link)
    let formData = new FormData()
    formData.append("name", doc_name)
    formData.append("file_type", type)
    formData.append("google_doc_id", doc_id)
    formData.append("sequence", sequence)
    formData.append("is_added_to_library", addToResLib)

    if (props.topic && props.topic.id) {
      formData.append("topic", props.topic.id)
    }
    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + `/images/create/`, formData, { headers: login_credentials })
      .then((res) => {
        let data = { ...videos_data }
        data[currentLinkIndex]["created"] = res.data.created
        data[currentLinkIndex]["id"] = res.data.id
        setVideosData(data)
        setFileCreated(false)
        // hideMessage.displaySuccessMsg('Document has been successfully added')
        setNotificationData({
          type: "success",
          title: "Document has been successfully added",
        })
        setHideCloseButton(true)
        //hideMessage.displaySuccessMsg('Document has been successfully added')
      })
      .catch((error) => {
        setHideCloseButton(true)
        console.log(error)
      })
  }

  function secondsToHms(d) {
    if (d) {
      d = Number(d)
      var h = Math.floor(d / 3600)
      var m = Math.floor((d % 3600) / 60)
      var s = Math.floor((d % 3600) % 60)

      var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : ""
      var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : ""
      var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : ""
      return hDisplay + mDisplay + sDisplay
    } else {
      return "Duration cannot be found for this video"
    }
  }

  function calculatePoints(d) {
    if (d) {
      d = Number(d)
      return Math.ceil(d / 60) > 0 ? Math.ceil(d / 60) : 1
    } else {
      return "Points cannot be calculated for this video"
    }
  }

  function removeVideo(e, index, type) {
    let res_type = type === "images" ? "images" : "videos"
    res_type = type === "link" ? "links" : res_type
    deleteFiles(e, videos_data[index].id, res_type, index)
  }

  function handleSubmit(event, type = "video") {
    setHideCloseButton(false)

    if (type === "link") {
      let link_data = videos_data[currentLinkIndex]
      let link_url = link_data["link"]
      if (link_url.indexOf("http://") == -1 && link_url.indexOf("https://") == -1) {
        link_url = "https://" + link_url
      }
      link_data["link"] = link_url
      var formData = new FormData()
      for (var k in link_data) {
        formData.append(k, link_data[k])
      }
      formData.append("name", link_url)
      formData.append("points", 1)
      formData.append("is_added_to_library", addToResLib)

      if (props.topic && props.topic.id) {
        formData.append("topic", props.topic.id)
      }

      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/links/create/", formData, { headers: login_credentials })
        .then((res) => {
          videos_data[currentLinkIndex]["id"] = res.data.id
          videos_data[currentLinkIndex]["name"] = res.data.name
          videos_data[currentLinkIndex]["link"] = res.data.link
          videos_data[currentLinkIndex]["created"] = res.data.created
          videos_data[currentLinkIndex]["points"] = res.data.points
          videos_data[currentLinkIndex]["icon"] = res.data.icon
          videos_data[currentLinkIndex]["is_added_to_library"] = res.data.is_added_to_library

          setVideosData(videos_data)
          setVideo(videos_data[currentLinkIndex])
          setFileCreated(false)
          // hideMessage.displaySuccessMsg('Link has been successfully added')
          setNotificationData({
            type: "success",
            title: "Link has been successfully added",
          })
          setHideCloseButton(true)

          //hideMessage.displaySuccessMsg('Link has been successfully added')
        })
        .catch((error) => {
          videoLoadEnable(currentLinkIndex, false)
          setHideCloseButton(true)

          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
          }
          // hideMessage.displayErrorMsg('We could not fetch the details. Please provide valid link')
          setNotificationData({
            type: "error",
            title: "We could not fetch the details. Please provide valid link",
          })
        })
    } else {
      let video = videos_data[currentLinkIndex]
      let keys = ["name", "title", "channel", "url", "sequence", "description", "video_id", "thumbnail_url", "duration", "points"]
      var formData = new FormData()
      for (var k in video) {
        if (keys.indexOf(k) != -1) {
          if (k === "name" || k === "title") {
            let tempName // = files[index].saved_name ? files[index].saved_name : files[index].name;
            tempName = video[k].replace(/\.[a-zA-Z0-9]{2,4}$/gi, "")

            formData.append("name", tempName)
          } else formData.append(k, video[k])
        }
      }
      formData.append("is_added_to_library", addToResLib)

      if (props.topic && props.topic.id) {
        formData.append("topic", props.topic.id)
      }
      let v_array = { ...videoArray }
      v_array[currentLinkIndex] = false
      if (video.id != undefined) {
      } else {
        var video_id = video.id
        axios
          .post(process.env.GATSBY_REACT_APP_API_HOST + "/videos/create/", formData, { headers: login_credentials })
          .then((res) => {
            videos_data[currentLinkIndex]["id"] = res.data.id
            videos_data[currentLinkIndex]["name"] = res.data.name
            videos_data[currentLinkIndex]["display_name"] = res.data.display_name
            videos_data[currentLinkIndex]["created"] = res.data.created
            setVideosData(videos_data)
            setVideo(videos_data[currentLinkIndex])
            setFileCreated(false)
            // hideMessage.displaySuccessMsg('Video has been successfully added')
            setNotificationData({
              type: "success",
              title: "Video has been successfully added",
            })
            setHideCloseButton(true)

            //hideMessage.displaySuccessMsg('Video has been successfully added')
            // document.body.click();
          })
          .catch((error) => {
            setHideCloseButton(true)

            let error_messages = ""
            for (var key in error.response.data) {
              error_messages += error.response.data[key] + "<br>"
            }
            if (error_messages === "Invalid token.<br>") {
              authClient.signOut()

              localStorage.removeItem("authenticity_token")
              localStorage.removeItem("username")
              // window.location.reload(true);
            }
            document.getElementById("fixed-error-div").innerHTML =
              '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
              '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
              '<span aria-hidden="true">×</span></button>' +
              error_messages +
              "</div>"
          })
      }
    }
  }

  function parseVideo(url) {
    // - Supported YouTube URL formats:
    //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
    //   - http://youtu.be/My2FRPA3Gf8
    //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
    // - Supported Vimeo URL formats:
    //   - http://vimeo.com/25451551
    //   - http://player.vimeo.com/video/25451551
    // - Also supports relative URLs:
    //   - //player.vimeo.com/video/25451551

    //let r = /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
    url.match(
      /(http:\/\/|https:\/\/|)?(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
    )
    //r.exec(url)

    if (RegExp.$3.indexOf("youtu") > -1) {
      var type = "youtube"
    } else if (RegExp.$3.indexOf("vimeo") > -1) {
      var type = "vimeo"
    } else if (url.indexOf("https://docs.google.com/") > -1) {
      var type = "google"
    } else {
      var type = "link"
    }

    return {
      type: type,
      id: RegExp.$6,
    }
  }

  function addMoreLinks(value) {
    if (value) {
      setLinkIndex(link_index + 1)
      setAddMore(true)
      setDefaultLink(false)
      setSelectedIndex(-1)
    }
  }

  return (
    <Modal
      show={show}
      centered
      size="lg"
      scrollable={true}
      className={`${StylesM.classroom_delete_modal} ${files.length > 0 && !editFilesNow ? `modal-content-bg-transparent` : `classroom-resource-delete-modal`}`}
    >
      <Modal.Header
        className={`${StylesM.delete_modal_header} ${files.length > 0 && !editFilesNow ? `border-01` : ``} ${
          files.length > 0 && !editFilesNow ? StylesM.bg_303C54 : ``
        }`}
        style={{ borderBottom: files.length > 0 ? null : "1px solid #6c7382" }}
      >
        {files.length > 0 ? (
          <div>
            {props.module ? (
              <p className={`font-weight-bold`} style={{ color: "#E0E2E5", fontSize: "14px" }}>
                {" "}
                {props.module ? `${props.module}/` : ``}
                {props.parentType ? props.topic.title : props.topic.name}
              </p>
            ) : (
              <p className={`font-weight-bold`} style={{ color: "#E0E2E5", fontSize: "14px" }}>
                {" "}
                {props.casestudy ? `${props.casestudy.name}` : ``}
              </p>
            )}
            {editFilesNow ? (
              <p className={`font-weight-bold`} style={{ color: "#E0E2E5", fontSize: "14px" }}>
                Define content ({files.length} file
                {files.length <= 1 ? "" : "s"} uploaded)
              </p>
            ) : (
              <p
                style={{
                  color: "#E0E2E5",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                {files.length} file{files.length <= 1 ? "" : "s"} added
              </p>
            )}
            <img
              onClick={(e) => {
                e.preventDefault()
                closeModal()
              }}
              src={CancelWhite}
              alt={`Cancel`}
              className={`my-auto mr-1 pointer ${editFilesNow || files.length === 0 ? `` : `d-none`}`}
              style={{
                objectFit: `contain`,
                height: `19px`,
                position: "absolute",
                top: "21px",
                right: "5px",
              }}
            />
          </div>
        ) : (
          <div>
            {props.module ? (
              <p className={`font-weight-bold`} style={{ color: "#E0E2E5", fontSize: "14px" }}>
                {" "}
                {props.module ? `${props.module}/` : ``}
                {props.parentType ? props.topic.title : props.topic.name}
              </p>
            ) : (
              <p className={`font-weight-bold`} style={{ color: "#E0E2E5", fontSize: "14px" }}>
                {" "}
                {props.casestudy ? `${props.casestudy.name}` : ``}
              </p>
            )}
            <p style={{ color: "#E0E2E5", fontWeight: "18px" }}>Upload/Add Content</p>
            <img
              onClick={(e) => {
                e.preventDefault()
                closeModal()
              }}
              src={CancelWhite}
              alt={`Cancel`}
              className={`my-auto mr-1 pointer`}
              style={{
                objectFit: `contain`,
                height: `19px`,
                position: "absolute",
                top: "21px",
                right: "5px",
              }}
            />
          </div>
        )}
      </Modal.Header>
      <Modal.Body
        className="align-items-center"
        style={{
          backgroundColor: files.length > 0 && !editFilesNow ? `#303C54` : "#212C42",
          borderRadius: `0px 0px 8px 8px`,
        }}
      >
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className={`px-0`}
          style={{
            backgroundColor: files.length > 0 ? `transparent` : "#303C54",
            borderRadius: "9px",
            color: "#E0E2E5",
          }}
        >
          {files.length > 0 || showOnlyLinks ? null : (
            <div
              onClick={() => {
                setShowSection(showSection === "upload_files" ? "" : "upload_files")
              }}
              className="py-2 px-3 d-inline-flex w-100 align-items-center pointer"
            >
              <p className="py-0 my-0">Browse files to upload</p>
              <FontAwesomeIcon
                className="ml-auto pointer"
                icon={showSection !== "upload_files" ? faChevronCircleDown : faChevronCircleUp}
                style={{ width: `20px`, height: `20px`, color: `#E0E2E5` }}
              />
            </div>
          )}
          <div
            className={`text-center align-items-center ${showSection !== "upload_files" ? "d-none" : ""} ${files.length > 0 ? `py-0` : `py-3 px-3`}`}
            style={{ borderTop: files.length > 0 ? null : "1px solid #6c7382" }}
          >
            {editFilesNow ? editFiles() : uploadFiles()}
          </div>
        </Col>
        {files.length > 0 || !showOnlyLinks ? null : (
          <>
            <Col
              className="my-2 d-grid px-0"
              lg="12"
              md="12"
              sm="12"
              xs="12"
              style={{
                backgroundColor: "#303C54",
                borderRadius: "9px",
                color: "#E0E2E5",
              }}
            >
              <div
                onClick={(e) => {
                  e.preventDefault()
                  setShowSection(showSection === "upload_links" ? "" : "upload_links")
                }}
                className="py-2 px-3 d-inline-flex w-100 align-items-center pointer"
              >
                <p className="py-0 my-0">Add External Links</p>
                <FontAwesomeIcon
                  className="ml-auto pointer"
                  icon={showSection !== "upload_links" ? faChevronCircleDown : faChevronCircleUp}
                  style={{ width: `20px`, height: `20px`, color: `#E0E2E5` }}
                />
              </div>
              {showSection === "upload_links" ? (
                <div className={`p-3`} style={{ borderTop: "1px solid #6c7382" }}>
                  <div id="add_links">{showInputs()}</div>
                  <div className={`text-center align-items-center`}>
                    {addMore ? (
                      <OverlayTrigger key={`top`} placement={`top`} overlay={<Tooltip>Disabled as you have one already</Tooltip>}>
                        <Button
                          onClick={(e) => {
                            e.preventDefault()
                            addMoreLinks(!addMore)
                          }}
                          className={`float-left ${StylesM.font_14} ${defineFiles !== "0" && showDefineNow ? `` : `mb-3`} ${
                            addMore ? `border-0` : `border_on_hover`
                          }`}
                          style={{
                            backgroundColor: addMore ? "#353b48" : "#586886",
                            boxShadow: "0px 3px 6px #00000029",
                            border: `0px solid`,
                            borderRadius: "36px",
                            color: "#E0E2E5",
                          }}
                        >
                          Add More Links
                        </Button>
                      </OverlayTrigger>
                    ) : (
                      <Button
                        onClick={(e) => {
                          e.preventDefault()
                          setLinkIndex(link_index + 1)
                          setAddMore(true)
                          setDefaultLink(false)
                          setSelectedIndex(-1)
                        }}
                        className={`float-left ${StylesM.font_14} ${addMore ? `border-0` : `border_on_hover`} ${
                          defineFiles !== "0" && showDefineNow ? `` : `mb-3`
                        }`}
                        style={{
                          backgroundColor: addMore ? "#353b48" : "#586886",
                          boxShadow: "0px 3px 6px #00000029",
                          border: `0px solid`,
                          borderRadius: "36px",
                          color: "#E0E2E5",
                        }}
                        disabled={addMore}
                      >
                        Add More Links
                      </Button>
                    )}
                  </div>
                  <div className="d-flex p-2 float-left align-items-center">
                    {loader[`type2`] ? (
                      <svg
                        className={`animate-spin my-auto mr-1`}
                        style={{ height: `16px`, width: `16px` }}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      <img src={CheckIcon} alt={`white check`} className={`my-auto mr-1 pointer`} style={{ objectFit: `contain`, width: `13px` }} />
                    )}
                    <p
                      style={{
                        fontSize: "12px",
                        margin: "0px",
                        color: "#E0E2E5",
                      }}
                    >
                      All changes are saved
                    </p>
                  </div>
                  {defineFiles === "0" && Object.keys(videoArray).filter((key) => videoArray[key] === true).length > 0 ? getDefineNow() : null}
                  {/*defineFiles !== '0' ?
                  <div className={`text-right pb-3`} >
                    <Button onClick={(e)=>{e.preventDefault();setShowDefineModal(true)}} variant="transparent" className={`shadow-none text-white px-1 d-inline-flex ${StylesM.font_12} align-items-center`} > <FontAwesomeIcon color='#212C42' icon={faCheck} style={{ width: `15px`, height: `15px`, color: defineFiles === '1' ? `#E0E2E5` : `transparent`, border: `1px solid white` }} /> &nbsp;Define Now</Button>
                    <Button onClick={(e)=>{e.preventDefault();setShowDefineModal(true)}} variant="transparent" className={`shadow-none text-white px-1 d-inline-flex ${StylesM.font_12} align-items-center ml-1`} > <FontAwesomeIcon color='#212C42' icon={faCheck} style={{ width: `15px`, height: `15px`, color: defineFiles === '2' ? `#E0E2E5` : `transparent`, border: `1px solid white` }} /> &nbsp;Define Later</Button>
                  </div>
                : null*/}
                </div>
              ) : null}
              <div className="py-2 px-3 d-inline-flex w-100 align-items-center pointer">
                <Form.Check
                  className="mt-2"
                  style={{ fontSize: "14px" }}
                  type={"checkbox"}
                  name="is_added_to_library"
                  id={`is_added_to_library_links`}
                  label={`Add this to the resource library`}
                  checked={addToResLib}
                  onChange={() => setAddToResLib((p) => !p)}
                />
              </div>
            </Col>
          </>
        )}
        {defineModal()}
      </Modal.Body>
      {editFilesNow ? (
        <Modal.Footer
          className="p-0 d-block"
          style={{
            backgroundColor: "#42506C",
            color: "#E0E2E5",
            border: "1px solid #707070",
          }}
        >
          <div className="d-flex px-2" style={{ alignItems: "center" }}>
            {loader[`type1`] ? (
              <svg
                className={`animate-spin my-auto mr-1`}
                style={{ height: `16px`, width: `16px` }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              <img src={CheckIcon} alt={`white check`} className={`my-auto mr-1 pointer`} style={{ objectFit: `contain`, width: `13px` }} />
            )}
            <p style={{ fontSize: "12px", margin: "0px", color: "#E0E2E5" }}>All changes are saved</p>
          </div>
        </Modal.Footer>
      ) : null}

      {notificationData && <Notification data={notificationData} setData={setNotificationData} />}
    </Modal>
  )
}

export default UploadFiles
