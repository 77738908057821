import React, { useEffect, useState, useContext } from "react"

import PropTypes from "prop-types"

import { TrainingSocketContext } from "../../../stores/socket/training_socket/trainingSocketProvider"
import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"
import { SET_TRAINING_BREAK } from "../../../stores/classroom_store/ClassroomActions"
import useBreakCountDown from "./_countdown_timer"
import Btn from "../../custom/Button"
import { apiStoptInstantBreak } from "./_break_apis"
import ConfirmEndBreakModal from "./_confirm_end_break"
import ExtendBreakTime from "./_extend_time"
import ExtendBreakTimeSuccess from "./_extend_time_success"
import Image from "../../custom/Image"
import Style from "./session-break.module.scss"

import ExtendTime from "../../../images/svgs/classroom/extend_time.svg"
import HotTea from "../../../images/svgs/classroom/hot_tea.svg"

const OngoingBreakBar = (props) => {
  const [isSocketReady, recvSocketData, sendSocket] = useContext(TrainingSocketContext)
  const [classroomState, dispatch] = useContext(ClassroomContext)
  // Timer
  const [remainingMinutes, remainingSeconds, breakTimeOver] = useBreakCountDown(
    classroomState.trainingBreak.triggered_at,
    classroomState.trainingBreak.duration
  )

  // Modals If any
  // modalComponentId == 1 => End-Break Modal
  // modalComponentId == 2 => Extend-Break Modal
  // modalComponentId == 3 => Extendebreak success Modal
  const [modalComponentId, setModalComponentId] = useState()

  // Below state should be active only if break is extended
  // @todo: think of any better approch and remove this state as it's redundant in most part of the components life-cycle
  const [extendedTime, setExtendedTime] = useState(0)

  useEffect(() => {
    if (recvSocketData.event_type === "training_break") {
      dispatch({ type: SET_TRAINING_BREAK, payload: recvSocketData.payload.break_info })
    }
  }, [recvSocketData])

  const stopTheBreak = async () => {
    // end break api here
    const { success, data } = await apiStoptInstantBreak(classroomState.trainingBreak.id)
    if (success) {
      return true
      // show break ended modal
    }
    return false
  }

  const modalCloseHandler = () => {
    setModalComponentId(null)
  }

  const breakStopConfirmed = async () => {
    const isEnded = await stopTheBreak()
  }

  const onTimeExtended = (extendedTime) => {
    setExtendedTime(extendedTime)
    setModalComponentId(3)
  }

  useEffect(() => {
    // If breaktimeover and status is on-going (i.e. 1), then stop the break
    if (breakTimeOver && classroomState.trainingBreak.status === "1") {
      stopTheBreak()
    }
  }, [breakTimeOver])

  return (
    <>
      <div className={`${Style.bottom_break_bar}`}>
        <div className="d-flex mt-2 justify-content-between px-3">
          <div className="d-flex">
            <Image src={HotTea} alt={"hot tea"} className={`mr-2 ml-4 ${Style.ongoing_break_tea}`} />
            <p className={`mt-2 mr-2`}>You are on a break</p>
            <div className={`mr-3 mt-1 ${Style.timer_break_bar} px-2 py-1`}>
              {" "}
              {remainingMinutes}:{remainingSeconds} Mins Left
            </div>
            <div className="mt-2 pointer d-flex" onClick={() => setModalComponentId(2)}>
              <Image src={ExtendTime} alt={"extend"} className={`mr-2 ${Style.ongoing_break_timer}`} />
              <u>Extend time</u>
            </div>
          </div>
          <Btn className={`px-3 mt-1 pt-1 ${Style.end_break}`} onClick={() => setModalComponentId(1)}>
            End Break
          </Btn>
        </div>
      </div>
      {modalComponentId == 1 && (
        <ConfirmEndBreakModal
          modalCloseHandler={modalCloseHandler}
          onConfirm={breakStopConfirmed}
          minutesLet={remainingMinutes}
          secondsLeft={remainingSeconds}
        />
      )}
      {modalComponentId == 2 && (
        <ExtendBreakTime modalCloseHandler={modalCloseHandler} onTimeExtended={onTimeExtended} setNotificationData={props.setNotificationData} />
      )}
      {modalComponentId === 3 && (
        <ExtendBreakTimeSuccess
          modalCloseHandler={modalCloseHandler}
          extendedTime={extendedTime}
          minutesLet={remainingMinutes}
          secondsLeft={remainingSeconds}
        />
      )}
    </>
  )
}

OngoingBreakBar.propTypes = {
  // Callback to set any error notifications
  setNotificationData: PropTypes.func.isRequired,
}

export default OngoingBreakBar
