import React, { useCallback } from "react"
import _ from "lodash"

import Col from "react-bootstrap/Col"

import Btn from "../custom/Button"

import DeleteResource from "../../images/svgs/delete.svg"
import EditIcon from "../../images/svgs/edit.svg"

import StylesA from "./matching.module.scss"
import Styles from "../../modularscss/styles.module.scss"

function ColumnNamesHeader(props) {
  const matchingData = props.matchingData

  const delayedQuery = useCallback(
    _.debounce((name, value, data, id, index) => sendQuery(name, value, data, id, index), 1000),
    []
  )

  function sendQuery(name, value, data, id, index) {
    props.handleUpdateColumnData(name, value, data, id, index)
  }

  function getStyleForColumnHeadings(col_index, column) {
    if (props.type === "view_detail") {
      let style = { border: "none" }
      return style
    } else {
      if (column?.column?.length === 0 && props.showValidationErrors) {
        let style = { border: "1px solid #ff7458" }
        return style
      } else {
        let style = { borderRight: col_index !== matchingData?.mtfcolumn_set?.length - 1 ? "1px solid #FFFFFF50" : "" }
        return style
      }
    }
  }

  return (
    <Col
      id={`columnname_${props.col_index + 1}`}
      key={`columnname_${props.col_index + 1}_${props.column.id}`}
      xs={props.getWidthOfColumn()}
      className="d-inline-flex w-100 align-items-center py-1"
      style={getStyleForColumnHeadings(props.col_index, props.column)}
    >
      {props.editNameOfColumns[`column_name_${props.col_index + 1}`] && props.type !== "view_detail" ? (
        <input
          id={`column_name_${props.col_index + 1}`}
          maxLength={250}
          name="column"
          defaultValue={props.column?.column}
          onChange={(e) => {
            props.setSavedContent(false)
            delayedQuery(e.target.name, e.target.value, matchingData, props.column.id, props.col_index)
          }}
          onBlur={(e) => {
            e.preventDefault()
            e.stopPropagation()
            let dummy = { ...props.editNameOfColumns }
            dummy[`column_name_${props.col_index + 1}`] = false
            props.setEditNameOfColumns(dummy)
            if (e.target.value !== props.column.column) {
              props.setSavedContent(false)
              props.handleUpdateColumnData(e.target.name, e.target.value, matchingData, props.column.id, props.col_index)
            }
          }}
          type="text"
          className={`${StylesA.inputText} fs-16px mt-0`}
          style={{
            background: "transparent",
            borderColor: "transparent",
            width: matchingData?.mtfcolumn_set?.length === 1 ? "70%" : props.col_index < matchingData?.mtfcolumn_set?.length - 1 ? "100%" : "auto",
          }}
          autoComplete="off"
          autoFocus
          required
        />
      ) : (
        <p
          className={`mb-0 ${props.col_index < matchingData?.mtfcolumn_set?.length - 1 ? Styles.ellipsis_1_effect : Styles.ellipsis_2_effect}`}
          onClick={(e) => {
            props.handleShowInput(props.col_index + 1)
          }}
          style={{ color: "white", maxWidth: matchingData?.mtfcolumn_set?.length === 1 ? `65%` : null }}
        >
          {props.column?.column}
        </p>
      )}
      {!props.editNameOfColumns[`column_name_${props.col_index + 1}`] && props.type !== "view_detail" && (
        <div className="pl-2">
          <Btn
            style={{ backgroundColor: "#42506C", border: "1px solid #FFFFFF27" }}
            onClick={(e) => {
              props.handleShowInput(props.col_index + 1)
            }}
            type={`round`}
          >
            <img
              style={{
                width: "15px",
                height: "15px",
              }}
              alt="Edit column name"
              src={EditIcon}
              className="mb-0 mx-auto"
            />
          </Btn>
        </div>
      )}
      {matchingData?.mtfcolumn_set?.length > 1 && !props.editNameOfColumns[`column_name_${props.col_index + 1}`] && props.type !== "view_detail" && (
        <div className="d-inline-flex align-items-center">
          <div className="pl-2">
            <Btn
              style={{ backgroundColor: "#42506C", border: "1px solid #FFFFFF27" }}
              onClick={(e) => {
                e.preventDefault()
                if (localStorage.getItem("dontShowDeleteColumn") === "true") {
                  props.handleDeleteColumn(props.column?.id)
                } else {
                  props.setShowDeleteModal(true)
                  props.setActiveDeleteColumnData(props.column)
                }
              }}
              type={`round`}
            >
              <img
                style={{
                  width: "15px",
                  height: "15px",
                }}
                alt="delete Column"
                src={DeleteResource}
                className="mb-0 mx-auto"
              />
            </Btn>
          </div>
        </div>
      )}
      {props.col_index === matchingData?.mtfcolumn_set?.length - 1 && props.type !== "view_detail" ? (
        <p
          className="mb-0 ml-auto underline pointer"
          onClick={(e) => {
            e.preventDefault()
            props.handleAddCol()
          }}
          style={{ width: "max-content", fontSize: "14px", color: "white" }}
        >
          + Add more columns
        </p>
      ) : null}
    </Col>
  )
}

export default ColumnNamesHeader
