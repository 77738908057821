import React from "react"

import PropTypes from "prop-types"
import { Dropdown } from "react-bootstrap"

import DeleteResource from "../../images/svgs/delete.svg"
import EllipsisWhite from "../../images/svgs/ellipsis-white.svg"

import StylesA from "./matching.module.scss"
import Styles from "../../modularscss/styles.module.scss"

function DeleteRowDropDown(props) {
  return (
    <Dropdown style={{ lineHeight: 1, color: "#E0E2E5" }} className={``}>
      <Dropdown.Toggle as="div" variant="none" className={`shadow-none p-0 ${Styles.no_caret}`}>
        <img
          src={EllipsisWhite}
          className={`my-auto pointer`}
          style={{
            objectFit: `contain`,
            width: `20px`,
            height: `16px`,
            marginLeft: "3px",
          }}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu className={`${StylesA.resource_option_menu}`}>
        <Dropdown.Item
          className={`d-flex pointer py-2 ${StylesA.resource_option_hover}`}
          style={{ color: "#E0E2E5" }}
          onClick={() => {
            if (localStorage.getItem("dontShowDeleteRow") === "true") {
              props.handleDeleteRow(props.single_row.question_data?.id)
            } else {
              props.setShowRowDeleteModal(true)
              props.setActiveDeleteRowData(props.single_row.question_data)
            }
          }}
        >
          <img src={DeleteResource} className={`my-auto mr-1`} style={{ objectFit: `contain`, width: `13px`, height: `13px` }} />
          <p>Delete Row</p>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default DeleteRowDropDown

DeleteRowDropDown.propTypes = {
  //Data of a particular row
  single_row: PropTypes.object.isRequired,

  // Index of a particular Row
  row_index: PropTypes.number.isRequired,
}
