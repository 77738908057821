import { getRequest, postRequest } from "../../../services/rest_service"

// API's for trainingBreaks

const apiCreateInstantBreak = async (formData) => await postRequest(`/classroom/training-break/`, formData)

const apiStartInstantBreak = async (breakId) => await postRequest(`/classroom/training-break/${breakId}/start_break/`)

const apiExtendTime = async (breakId, formData) => await postRequest(`/classroom/training-break/${breakId}/extend_break_time/`, formData)

const apiStoptInstantBreak = async (breakId) => await postRequest(`/classroom/training-break/${breakId}/end_break/`)

const apiBreakSuggessions = async (training_id) => await getRequest(`/classroom/training-break/break_suggestions/?training=${training_id}`)

export { apiCreateInstantBreak, apiStartInstantBreak, apiExtendTime, apiStoptInstantBreak, apiBreakSuggessions }
