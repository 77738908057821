import React, { useEffect, useState, useContext } from "react"

import PropTypes from "prop-types"
import { Modal } from "react-bootstrap"
import Col from "react-bootstrap/Col"
import Btn from "../../custom/Button"
import Image from "../../custom/Image"
import Style from "./session-break.module.scss"
import { apiCreateInstantBreak, apiStartInstantBreak } from "./_break_apis"
import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"
import { SET_ACTIVE_ACTIVITY_DATA, SHOW_ACTIVE_ACTIVITY } from "../../../stores/classroom_store/ClassroomActions"

import CloseIcon from "../../../images/svgs/close.svg"
import EditIcon from "../../../images/svgs/edit.svg"
import Lightening from "../../../images/svgs/classroom/lightening.svg"

const InstantBreakModal = (props) => {
  var break_name = "instant"

  // Instant break form data
  const [breakDuration, setBreakDuration] = useState(0)
  const [breakName, setBreakName] = useState("")
  const [breakObjective, setBreakObjective] = useState("")

  //State for enabiling edit oprion for break objective
  const [editObjective, setEditObjective] = useState(false)

  const [classroomState, dispatch] = useContext(ClassroomContext)

  useEffect(() => {
    if (Object.keys(props.mostlyUsedBreakData).length > 0) {
      setBreakDuration(props.mostlyUsedBreakData.duration)
      setBreakName(props.mostlyUsedBreakData.title)
      setBreakObjective(props.mostlyUsedBreakData.objective)
    }
  }, [props.mostlyUsedBreakData])

  const handleStartBreakClick = () => {
    if (breakName.trim() === "") {
      props.setNotificationData({ type: "error", title: "Break Type cannot be empty." })
    } else if (breakDuration <= 0) {
      props.setNotificationData({ type: "error", title: "Break duration cannot be 0." })
    } else {
      createAndTriggerInstantBreak()
    }
  }

  const createAndTriggerInstantBreak = async () => {
    let formData = new FormData()
    formData.append("break_type", "instant")
    formData.append("break_name", breakName)
    formData.append("duration", breakDuration)
    formData.append("message", breakObjective)
    formData.append("course", classroomState.template_id)

    let res = await apiCreateInstantBreak(formData)
    if (res.success) {
      triggerBreak(res.data)
    } else {
      props.setNotificationData({ type: "error", title: "Failed to create the break, please try again or contact administrator" })
    }
  }

  const triggerBreak = async (breakInstance) => {
    // Once break is created, it needs to be triggered explicitly

    const { success, data } = await apiStartInstantBreak(breakInstance.id)
    if (success) {
      props.onInstantBreakTriggered()
    } else {
      if (data.current_active_activity) {
        dispatch({
          type: SET_ACTIVE_ACTIVITY_DATA,
          payload: { current_active_activity: data.current_active_activity, active_breakout_session: data.active_breakout_session },
        })
        dispatch({ type: SHOW_ACTIVE_ACTIVITY, payload: true })
      } else {
        props.setNotificationData({ type: "error", title: data })
      }
    }
  }

  return (
    <React.Fragment>
      <Modal
        show={true}
        onHide={props.handleMostlyUsedBreakModalHide}
        centered={true}
        size={"sm"}
        className={`${Style.instant_modal}`}
        contentClassName={`${Style.instant_modal_content}`}
      >
        <Modal.Body className={`p-0`}>
          <div className="pt-3 px-2 pb-1">
            <div className="d-flex justify-content-between px-1 mb-2">
              <div className="d-flex mb-1">
                <Image src={props.mostlyUsedBreakData.icon ? props.mostlyUsedBreakData.icon : Lightening} alt={"break_icon"} className={`my-auto mr-2`} />
                <p className="my-auto text-light">{breakName}</p>
              </div>
              <Image className={`pointer text-light ${Style.close_icon}`} src={CloseIcon} onClick={props.handleMostlyUsedBreakModalHide} alt={`close_icon`} />
            </div>
            <Col lg="12" md="12" sm="12" xs="12" className="px-1 mb-3 d-inline-flex w-100 align-item-center">
              <div className="mr-3">
                <p className={`text-light mb-0 ${Style.mostly_used_break_text}`}>Break Type</p>
                <p className="text-light mb-0 fs-16px">{breakName}</p>
              </div>
              <div>
                <p className={`text-light mb-0 ${Style.mostly_used_break_text}`}>Break Duration</p>
                <p className="text-light mb-0 fs-16px">{breakDuration === 1 ? `01 min` : `${breakDuration} mins`}</p>
              </div>
            </Col>
            <Col lg="12" md="12" sm="12" xs="12" className="px-1">
              <div>
                <p className={`text-light mb-0 ${Style.mostly_used_break_text}`}>Break Objective</p>
                <p className="text-light mb-1 fs-16px">
                  <span className="pr-2">{breakObjective}</span>
                  <Image
                    className={`pointer ${Style.close_icon}`}
                    alt={`edit_icon`}
                    src={EditIcon}
                    onClick={() => {
                      setEditObjective(true)
                    }}
                  />
                </p>
              </div>
            </Col>
          </div>
          <Col className={`py-2 text-center ${Style.take_the_break}`}>
            <div className="d-inline justify-content-start">
              <Btn className={`border_on_hover ${Style.done_btn_break} px-3 py-1`} onClick={() => handleStartBreakClick(break_name)}>
                Take the Break
              </Btn>
            </div>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal
        show={editObjective}
        onHide={() => {
          setEditObjective(false)
        }}
        centered={true}
        size={"sm"}
        className={`${Style.instant_modal} mt-5`}
        dialogClassName={Style.edit_objective_modal}
        contentClassName={`${Style.instant_modal_content}`}
      >
        <Modal.Body className={`p-0 br-9px`}>
          <Col lg="12" md="12" sm="12" xs="12" className={`px-3 py-2 ${Style.edit_objective_div}`}>
            <p className={`text-light mb-0 ml-1 ${Style.mostly_used_break_text}`}>Break Objective</p>
            <textarea
              id={"objective"}
              name={"objective"}
              className={`${Style.inputText} mt-0`}
              defaultValue={breakObjective}
              onChange={(e) => setBreakObjective(e.target.value)}
              rows={"2"}
            />
          </Col>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

InstantBreakModal.propTypes = {
  // Default data for
  mostlyUsedBreakData: PropTypes.object.isRequired,

  // Handle modal close and hide actions
  handleMostlyUsedBreakModalHide: PropTypes.func.isRequired,

  // Callback on break trigger is successfull
  onInstantBreakTriggered: PropTypes.func.isRequired,

  // Callback to set any error notifications
  setNotificationData: PropTypes.func.isRequired,
}

export default InstantBreakModal
