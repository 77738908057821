import React, { useCallback, useEffect } from "react"
import _ from "lodash"

import PropTypes from "prop-types"
import Col from "react-bootstrap/Col"

import DragHandleIcon from "../../images/svgs/drag-handle-gray.svg"

import StylesA from "./matching.module.scss"
import Styles from "../../modularscss/styles.module.scss"

const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

function QuestionColumn(props) {
  const matchingData = props.matchingData

  const delayedQuery = useCallback(
    _.debounce((name, value, data, id, index) => sendQuery(name, value, data, id, index), 1000),
    []
  )

  function sendQuery(name, value, data, id, index) {
    props.handleUpdateQuestionData(name, value, data, id, index)
  }

  useEffect(() => {
    if (props.single_row?.question_data?.question) {
      props.handleUpdateHeightOfRow("question", props.row_index)
    }
  }, [props.single_row?.question_data?.question])

  return (
    <Col
      lg={props.getWidthOfColumn()}
      key={`row_${props.row_index}_question_column_`}
      onClick={(e) => {
        e.preventDefault()
        document.getElementById(`question_${props.row_index}`).focus()
      }}
      className={`pt-2 d-flex align-items-stretch ${props.mtf_type === "1" ? `pb-2 ` : ``}`}
      style={{ borderRight: "1px solid #FFFFFF50" }}
    >
      <Col
        lg="12"
        md="12"
        sm="12"
        xs="12"
        className="px-0 d-inline-flex w-100 h-100 align-items-center"
        style={{ border: props.showValidationErrors && props.single_row?.question_data?.question?.length === 0 ? "1px solid #ff7458" : "none" }}
      >
        <div
          variant="none"
          className={`${Styles.font_16} w-100 shadow-none d-inline-flex align-items-baseline h-100`}
          style={{ color: `#F2F4FF`, backgroundColor: "#42506C" }}
        >
          <div className={`d-inline-flex h-100 pl-2 pr-3 py-1 align-items-baseline`} style={{ borderRight: "1px solid #838B9E" }}>
            {props.type !== "view_detail" ? (
              <img
                src={DragHandleIcon}
                alt={`drag-handle`}
                className={`mt-1 mb-0 mr-1 pointer`}
                style={{ objectFit: `contain`, height: `13px`, width: "16px" }}
              />
            ) : null}
            <p className={`mb-0 pl-2`} style={{ fontSize: "19px", fontWeight: "500", color: "#E0E2E5" }}>
              {alphabets[props.row_index]}
            </p>
          </div>
          <textarea
            id={`question_${props.row_index}`}
            name={`question`}
            className={` ${StylesA.resizing_textarea} shadow-none px-2 py-1 ${StylesA.answer_cell}`}
            style={{ height: "auto" }}
            disabled={props.type === "view_detail" ? true : false}
            placeholder={props.type !== "view_detail" ? "Type here" : ""}
            onKeyDown={(e) => {
              props.handleUpdateHeightOfRow("question", props.row_index)
            }}
            onChange={(e) => {
              props.setSavedContent(false)
              delayedQuery(e.target.name, e.target.value, matchingData, props.single_row?.question_data?.id, props.row_index)
            }}
            onBlur={(e) => {
              e.preventDefault()
              e.stopPropagation()
              if (e.target.value !== props.single_row?.question_data?.question) {
                props.setSavedContent(false)
                props.handleUpdateQuestionData(e.target.name, e.target.value, matchingData, props.single_row?.question_data?.id, props.row_index)
              }
            }}
            defaultValue={props.single_row?.question_data?.question}
          />
        </div>
      </Col>
    </Col>
  )
}

QuestionColumn.propTypes = {
  // Index of a particular Row
  row_index: PropTypes.number.isRequired,

  //Data of a particular row
  single_row: PropTypes.object.isRequired,

  //Catergoy of match the following
  mtf_type: PropTypes.string.isRequired,

  // To handle on height of the answer box
  handleUpdateHeightOfRow: PropTypes.func.isRequired,

  // TO get the column width of a column
  getWidthOfColumn: PropTypes.func.isRequired,
}

export default QuestionColumn
