import React, { useEffect, useState, useCallback } from "react"
import _ from "lodash"
import { Modal } from "react-bootstrap"
import CancelWhite from "../../images/svgs/close.svg"
import MatchingViewDetail from "../match-the-following/matching_view_detail"
import Styles from "../../components/class-room-training/lesson-board.module.scss"

import GroupSelectionModal from "./_group-selection-modal"
import StudentListDropdown from "./_student-list-dropdown"
import CustomChecklistReportCard2 from "../live_class/breakout_session/CustomChecklistReportCard2"

import StylesA from "../match-the-following/matching.module.scss"
import ResourceSelectionDropdown from "./_resource-selection-dropdown"
import ActivitySelectionDropdown from "./_activity-selection-dropdown"
import CaseStudyReportCardView from "../live_class/breakout_session/CaseStudyReportCardView"

import QuizReportCardView from "../live_class/breakout_session/QuizReportCardView"

const resourcesName = {
  matchthefollowing: "Match the following",
  checklist: "Checklist",
  quiz: "Quiz",
  casestudy: "Case study",
}

const BreakoutSessionResourceViewDetail = (props) => {
  const [sharingBoard, setSharingBoard] = useState(false)
  const [stopSharingBoard, setStopSharingBoard] = useState(false)

  const attemptableResources = ["matchthefollowing", "quiz", "checklist", "casestudy"]
  const attemptableResourceList = props.resourceList?.filter((resource) => attemptableResources.includes(resource.resource_type))
  const hasNonZeroLengthOfAttemptableResource = attemptableResourceList?.length > 0

  useEffect(() => {
    if (props?.activeResource?.id === props.activeResourceId) {
      setSharingBoard(true)
    }
  }, [props.activeResource, props.activeResourceId])

  const getModalContent = () => {
    if (props?.activeGroup?.report_card) {
      if (props.activeResource?.resource_type === "matchthefollowing") {
        return (
          <>
            <MatchingViewDetail
              sessionData={props.sessionData}
              training_id={props.training_id}
              activeResource={props.activeResource}
              matching_id={props.activeResource?.resource_data?.id}
              sharingBoard={sharingBoard}
              setSharingBoard={setSharingBoard}
              stopSharingBoard={stopSharingBoard}
              setStopSharingBoard={setStopSharingBoard}
              setShowViewDetailsModal={props.setShowViewDetailsModal}
              showViewDetailsModal={props.showViewDetailsModal}
              activeGroup={props.activeGroup}
            />
          </>
        )
      } else if (props.activeResource?.resource_type === "checklist") {
        return (
          <>
            <CustomChecklistReportCard2
              name={props.activeResource?.resource_data?.name}
              activeGroup={props.activeGroup}
              activeResource={props.activeResource}
              type={"group"}
            />
          </>
        )
      } else if (props.activeResource?.resource_type === "casestudy") {
        return (
          <CaseStudyReportCardView
            activeResource={props.activeResource}
            activeGroup={props.activeGroup}
            setShowViewDetailsModal={props.setShowViewDetailsModal}
            activeBreakoutActivity={props.activeBreakoutActivity}
          />
        )
      } else if (props.activeResource?.resource_type === "quiz") {
        return (
          <QuizReportCardView
            activeResource={props.activeResource}
            activeGroup={props.activeGroup}
            setShowViewDetailsModal={props.setShowViewDetailsModal}
            activeBreakoutActivity={props.activeBreakoutActivity}
          />
        )
      }
    } else {
      return (
        <div className="w-100 text-center py-5">
          <p className="mb-0" style={{ fontSize: "16px", fontWeight: "500", color: "#E0E2E5" }}>
            {!hasNonZeroLengthOfAttemptableResource
              ? "No groups / attemptable resources found for selected activity"
              : "      Students from this group haven't started this activity yet"}
          </p>
        </div>
      )
    }
  }

  const getModalHeaderContent = () => {
    return (
      <>
        <div className={`d-flex align-items-center w-100 py-2 ${StylesA.mtf_modal_header}`}>
          <div className="flex-grow-1">
            {["checklist", "casestudy", "quiz"].includes(props?.activeResource?.resource_type) && props.activeGroup?.report_card && (
              <StudentListDropdown
                title={props?.activeGroup?.title}
                activeGroupId={props.activeGroup?.report_card}
                type={props?.activeResource?.resource_type}
              />
            )}
          </div>
          <div className="px-2 flex-grow-1 text-center text-color-1">
            {hasNonZeroLengthOfAttemptableResource ? (
              <>
                {resourcesName[props?.activeResource?.resource_type]}: &nbsp;
                {props.activeResource?.resource_data?.name}
              </>
            ) : (
              "No attemptable resource"
            )}
          </div>
          <span className="flex-grow-1 d-flex justify-content-end">
            <img
              src={CancelWhite}
              className={`mr-3 pointer icon`}
              onClick={(e) => {
                e.preventDefault()
                if (sharingBoard) {
                  setStopSharingBoard(true)
                } else {
                  props.setShowViewDetailsModal(false)
                  props.setActiveGroup({})
                }
              }}
            />
          </span>
        </div>
      </>
    )
  }

  return (
    <Modal show={props.showViewDetailsModal} centered size="xl" contentClassName={`${StylesA.transparent_modal}`}>
      <div className="d-flex mb-3">
        <ActivitySelectionDropdown
          breakoutActivities={props.breakoutActivities}
          activeBreakoutActivity={props.activeBreakoutActivity}
          setActiveBreakoutActivity={props.setActiveBreakoutActivity}
          handleActivitySeeMoreResponse={props.handleActivitySeeMoreResponse}
          breakoutActivitiesNextUrl={props.breakoutActivitiesNextUrl}
          setActiveGroup={props.setActiveGroup}
        />
        <GroupSelectionModal
          activityWiseGroupsData={props.activityWiseGroupsData}
          activeResource={props.activeResource}
          activeGroup={props.activeGroup}
          setActiveGroup={props.setActiveGroup}
        />
        <ResourceSelectionDropdown
          activeResource={props.activeResource}
          setActiveResource={props.setActiveResource}
          resourceList={props.resourceList}
          attemptableResources={attemptableResources}
          attemptableResourceList={attemptableResourceList}
          hasNonZeroLengthOfAttemptableResource={hasNonZeroLengthOfAttemptableResource}
          setActiveGroup={props.setActiveGroup}
        />
      </div>

      <Modal.Body className={`${Styles.br_view_detail_modal} px-0 py-0`} style={{ border: sharingBoard ? "2px solid #379F94" : `2px solid #FFFFFF` }}>
        {getModalHeaderContent()}
        {getModalContent()}
      </Modal.Body>
    </Modal>
  )
}
export default BreakoutSessionResourceViewDetail
