import React, { useEffect, useRef, useState } from "react"

// Custom Hook for incremental and decremental timer

/**
 * returns the remaining seconds as per the name says and follows the below logic
 * Logic:
 *  1. Convert durationInMins to seconds
 *  2. Calculate the difference of seconds between current time and startedTime in seconds
 *  3. Now remaining time is '1st step value' - '2nd step value'
 * @param {Date} startedTime time at which timer is started
 * @param {number} durationInMins number of muntes timer should run duration in minutes
 * @returns {number} remaining time
 */
const getTotalRemainingTimeInSec = (startedTime, durationInMins) => durationInMins * 60 - (new Date() - new Date(Date.parse(startedTime))) / 1000

/**
 * returns the spent seconds as per the name says and follows the below logic
 * Logic:
 *  1. Calculate the difference of seconds between current time and startedTime in seconds
 *  2. Now spent time is '1st step value' + '1 Second'
 * @param {Date} startedTime time at which timer is started
 * @returns {number} spent time
 */

const getTotalSpentTimeInSec = (startedTime) => (new Date() - new Date(Date.parse(startedTime))) / 1000 + 1

/**
 * Converts given seconds to hours, minutes and seconds
 * Ex: if input is 3661, return value will be [1, 1, 1]
 * @param {number} totalSeconds Total seconds needs to be parsed
 * @returns {List} Array containing minute and number
 */
const getMinAndSec = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 60 / 60).toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false })
  const minutes = Math.floor((totalSeconds / 60) % 60).toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false })
  const seconds = ("0" + Math.floor(totalSeconds % 60)).slice(-2)
  return [hours, minutes, seconds]
}

/**
 * Custom hook for countdown timer
 * @param {Date} startedTime Timer start time
 * @param {number} durationInMins How long timer should run
 */
const useTimer = (startedTime, durationInMins, resetTimer, resetTimerOnTabChange, timerType = "decrement", showTimerEndWarning = false) => {
  const timer = useRef(null)
  const [totalTimeInSec, setTotalTimeInSec] = useState(
    timerType === "decrement" ? getTotalRemainingTimeInSec(startedTime, durationInMins) : getTotalSpentTimeInSec(startedTime, durationInMins)
  )
  const [timerEnded, setTimerEnded] = useState(false)
  const [timerReset, setTimerReset] = useState(false)
  const [timerEndsWarning, setTimerEndsWarning] = useState(false)
  const percentageCompletion = useRef(0)

  // Start Timer function.
  const startTimer = () => {
    if (timerReset) {
      setTimerReset(false)
    }
    timer.current = setInterval(() => {
      const timeInSec = timerType === "decrement" ? getTotalRemainingTimeInSec(startedTime, durationInMins) : getTotalSpentTimeInSec(startedTime)
      percentageCompletion.current = parseInt((timeInSec / 60 / durationInMins) * 100)
      if (showTimerEndWarning && timerType === "decrement" && !timerEndsWarning && percentageCompletion.current <= 21 && percentageCompletion.current >= 19) {
        setTimerEndsWarning(true)
      }
      if ((timerType === "decrement" && timeInSec <= 0) || (timerType === "increment" && durationInMins > 0 && durationInMins * 60 <= timeInSec)) {
        setTotalTimeInSec(timerType === "decrement" ? 0 : timeInSec)
        setTimerEnded(true)
        clearInterval(timer.current)
      } else {
        setTotalTimeInSec(timeInSec)
      }
    }, 1000)
  }

  // This hooks use to detect reset timer event is triggered or not
  useEffect(() => {
    if (resetTimer || resetTimerOnTabChange) {
      setTimerReset(true)
      clearInterval(timer.current)
      startTimer()
    }
  }, [resetTimer, resetTimerOnTabChange])

  // This hooks use to detect new timer has to start or not
  useEffect(() => {
    startTimer()
    return () => {
      clearInterval(timer.current)
    }
  }, [durationInMins, startedTime])

  return [...getMinAndSec(totalTimeInSec), timerEnded, timerReset, timerEndsWarning]
}

export default useTimer
