import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Modal } from "react-bootstrap"

import Btn from "../../custom/Button"

import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"
import { ClassroomSidebarContext } from "../../../stores/classroom_store/classroom_sidebar/classroomSidebarStore"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import TimerComponent from "../../custom/TimerComponent"
import { SET_DISABLED_RESOURCES } from "../../../stores/classroom_store/classroom_sidebar/classroomSidebarActions"

const EndBreakoutModal = (props) => {
  const [sidebarState, sidebarDispatch] = useContext(ClassroomSidebarContext)
  const [classroomState, dispatch] = useContext(ClassroomContext)

  const handleEndBreakoutSession = (e) => {
    props.updateBreakoutSessionStatus("2")
    props.setShowEndBreakoutSessionModal(false)
    sidebarDispatch({ type: SET_DISABLED_RESOURCES, payload: [] })
  }

  return (
    <Modal
      show={props.showEndBreakoutSessionModal}
      onHide={() => {
        props.setShowEndBreakoutSessionModal(false)
      }}
      centered
      // Need to reafactor classroom-delete-modal css class.
      className={`classroom-delete-modal classroom-resource-delete-modal`}
    >
      <div
        className={``}
        style={{
          backgroundColor: "#19253b",
          borderRadius: "8px 8px 0px 0px",
        }}
      >
        <div className="w-100 align-items-center px-3 py-2">
          <div className={`w-100 d-inline-flex  mb-2`}>
            <FontAwesomeIcon
              className={`pointer ml-auto`}
              onClick={() => {
                props.setShowEndBreakoutSessionModal(false)
              }}
              icon={faTimesCircle}
              color="white"
            />
          </div>
          <div className={`w-100 d-inline-flex mb-2`}>
            <div className={`mx-auto d-inline-flex`}>
              <FontAwesomeIcon className="" icon={faExclamationTriangle} color="#E0E2E5" size="sm" style={{ height: "25px" }} />
              <p className="mb-0 ml-2" style={{ fontSize: "18px" }}>
                You still have{" "}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  <TimerComponent
                    startedTime={classroomState?.activeBreakoutSession?.start_time}
                    totalTimerDuration={classroomState?.activeBreakoutSession?.total_duration / 60}
                    timerType={"decrement"}
                  />
                </span>{" "}
                mins left for the session to end
              </p>
            </div>
          </div>
        </div>
      </div>
      <Modal.Body
        className="text-center"
        style={{
          backgroundColor: "#121b2b",
          borderRadius: "0px 0px 8px 8px",
        }}
      >
        <p className={`mb-0`} style={{ fontSize: "18px", fontWeight: "500" }}>
          {" "}
          Are you sure you want to end
        </p>
        <p className={`mb-3`} style={{ fontSize: "18px", fontWeight: "500" }}>
          {" "}
          {classroomState.activeBreakoutSession.name}
        </p>
        <div className="d-flex mb-3 justify-content-center">
          <Btn
            className={`py-1 px-3 border_on_hover mr-2`}
            onClick={handleEndBreakoutSession}
            variant="secondary"
            style={{
              border: "1px solid transparent",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            Yes, end now
          </Btn>
          <Btn
            className={`py-1 px-4 border_on_hover`}
            variant="secondary"
            onClick={() => {
              props.setShowEndBreakoutSessionModal(false)
            }}
            style={{
              border: "1px solid transparent",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            No
          </Btn>
        </div>
      </Modal.Body>
    </Modal>
  )
}

EndBreakoutModal.propTypes = {
  showEndBreakoutSessionModal: PropTypes.bool.isRequired,
  setShowEndBreakoutSessionModal: PropTypes.func.isRequired,
  updateBreakoutSessionStatus: PropTypes.func.isRequired,
}

export default EndBreakoutModal
