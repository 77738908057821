import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import CancelWhite from "../../../images/svgs/close.svg"
import Style from "../../class-room-training/lesson-board.module.scss"
import StylesA from "../../template-resources/breakout-session.module.scss"

const ExtendTimeReplyModal = (props) => {
  const [clickedExtendTime, setClickedExtendTime] = useState(false)
  const [duration, setDuration] = useState(null)

  useEffect(() => {
    if (props.duration) {
      setDuration(parseInt(props.duration))
    }
  }, [props.duration])

  return (
    <div className={`w-100 align-items-center`}>
      <div className="px-0 d-inline-flex w-100">
        <div
          className={`px-3 pointer border_on_hover py-1 float-left fs-16px box-shadow br-4px bg-303C54 text-E0E2E5 mb-0`}
          onClick={(e) => setClickedExtendTime(true)}
        >
          Extend Time
        </div>
      </div>
      <Modal
        show={clickedExtendTime}
        onHide={() => setClickedExtendTime(false)}
        centered
        size="md"
        className={`${StylesA.classroom_delete_modal} zIndex-9999 classroom-resource-delete-modal p-0`}
      >
        <Modal.Body className="float-left text-center p-3 br-8px bg-2">
          {props.type === "suggestion" ? null : (
            <p className={`mb-3 fs-14px text-E0E2E570`}>
              <u className={`bold-500`}>{props.student_name}</u> From{" "}
              <span className={`bold-500`}>
                <u>{props.group_name}</u>
              </span>{" "}
              needs {props.duration} mins more
            </p>
          )}
          <img
            onClick={(e) => {
              setClickedExtendTime(false)
            }}
            src={CancelWhite}
            alt={`Cancel`}
            className={`ml-auto mr-1 mb-0 pointer ${StylesA.img_cancel}`}
          />
          <div className={`d-inline-block text-center py-3 px-5 br-9px box-shadow bg-42506C`}>
            <p className={`mb-2 fs-14px text-E0E2E570`}>Extend time by</p>
            <Form
              onSubmit={(e) => {
                e.preventDefault()
                props.updateSessionDuration(duration, "extend_time_modal")
                setClickedExtendTime(false)
              }}
            >
              <input
                type="number"
                min="1"
                defaultValue={parseInt(duration)}
                onChange={(e) => {
                  let val = props.handleChangeExtendMins(e)
                  setDuration(val)
                }}
                className={`${StylesA.session_input_field} ${StylesA.width_60px} mx-auto form-control shadow-none px-2 ${StylesA.border_on_hover} bold-500 fs-36px`}
              />
              <p className={`mb-3 fs-16px text-E0E2E570`}>Mins</p>
              <button type="submit" className={`poppins_font px-4 py-1 ${Style.breakout_modal_fotter_btn_inactive} border_on_hover`}>
                <p className={`mb-0 poppins_font fs-16px bold-500`}>Extend</p>
              </button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

ExtendTimeReplyModal.propTypes = {}

export default ExtendTimeReplyModal
