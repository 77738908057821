import React from "react"
import { Dropdown } from "react-bootstrap"
import Image from "../custom/Image"
import arrow from "../../images/svgs/down_arrow_white.svg"

import StylesA from "../../components/match-the-following/matching.module.scss"
import StylesBS from "./bs-viewdetail.module.scss"
import SeeMore from "../custom/see_more"

const ActivitySelectionDropdown = (props) => {
  return (
    <Dropdown
      className={`d-inline-flex align-items-center px-2 border-right-0 ${StylesBS.border_white} ${StylesBS.group_selection_dropdown} ${StylesBS.group_select}`}
    >
      <Dropdown.Toggle
        id="group-select-dropdown"
        size="lg"
        className={`d-inline-flex align-items-center caret-none w-100 px-0 ${StylesBS.dropdown_toggle} ${StylesBS.dropdown_bg}`}
      >
        <span className={`mb-0 pr-3 fs-18px text-color-1 ellipsis ${StylesBS.maxw_180px}`}>{props.activeBreakoutActivity?.name}</span>
        <Image className={`ml-auto pointer icon}`} src={arrow} />
      </Dropdown.Toggle>
      <Dropdown.Menu className={`ml-lg-n2 ${StylesA.category_dropdown_menu} ${StylesBS.group_dropdown_menu} `}>
        <div className={`d-flex ${StylesBS.dropdown_menu} ${StylesBS.border_white}`}>
          <div className={`${StylesBS.group_item_div} w-100`}>
            {props.breakoutActivities?.map((activity, index) => {
              return (
                <Dropdown.Item
                  key={`group_${activity.id}`}
                  className={`pl-2 py-1 pr-3 pointer ${StylesA.resource_option_hover} ${StylesBS.group_item_ele}`}
                  onClick={(e) => {
                    props.setActiveGroup((prev) => ({ ...prev, report_card: null }))
                    e.preventDefault()
                    props.setActiveBreakoutActivity(activity)
                  }}
                >
                  <div className="text-color-1 ellipsis">{activity.name}</div>
                </Dropdown.Item>
              )
            })}
            {props.breakoutActivitiesNextUrl && <SeeMore url={props.breakoutActivitiesNextUrl} handleData={props.handleActivitySeeMoreResponse} />}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}
export default ActivitySelectionDropdown
