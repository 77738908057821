import React, { useState, useContext } from "react"

import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"
import PropTypes from "prop-types"
import { Modal } from "react-bootstrap"
import CloseIcon from "../../../images/svgs/close.svg"
import Col from "react-bootstrap/Col"
import Btn from "../../custom/Button"

import Style from "./session-break.module.scss"
import { apiExtendTime } from "./_break_apis"
import ExtendTime from "../../../images/svgs/classroom/extend_time.svg"
import TextArea from "../../custom/TextArea"
import Image from "../../custom/Image"
import SelectBreakExtendTime from "./_select_extend_time"

const ExtendBreakTime = (props) => {
  const [classroomState, dispatch] = useContext(ClassroomContext)
  // Default selectable values in the UI
  const durations = [10, 15, 20, 30]

  // Formdata
  const [extendMessage, setExtendMessage] = useState("")
  const [breakDuration, setBreakDuration] = useState(0)

  // Handle extendtime submit action
  const handleExtendBreak = () => {
    if (breakDuration <= 0) {
      props.setNotificationData({ type: "error", title: "Duration must be greater than zero" })
      return
    }
    extendTheBreakTime()
  }

  const extendTheBreakTime = async () => {
    let formData = new FormData()
    formData.append("extend_time", breakDuration)
    formData.append("message", extendMessage)
    const res = await apiExtendTime(classroomState.trainingBreak.id, formData)
    if (res.success) {
      props.onTimeExtended(breakDuration)
    }
  }

  return (
    <Modal
      show={true}
      onHide={props.modalCloseHandler}
      centered={true}
      size={"lg"}
      className={`${Style.instant_modal} text-light`}
      contentClassName={`${Style.extend_modal_content}`}
    >
      <Modal.Body className={`${Style.extend_time_modal}`}>
        <div>
          <div className="d-flex justify-content-between">
            <div className="d-flex mb-4 align-items-center">
              <Image src={ExtendTime} alt={"extend"} className={`mr-2`} />
              <p className="mb-0">Extend time by</p>
            </div>
            <Image className={`pointer text-light ${Style.extend_time_close}`} src={CloseIcon} onClick={props.modalCloseHandler} alt={`close_icon`} />
          </div>
          <SelectBreakExtendTime durations={durations} onValueChange={setBreakDuration} />
          <Col lg="12" md="12" sm="12" xs="12" className="mt-3">
            <TextArea className={`${Style.extend_time_text_area}`} type="text" label={`Add Message`} onChange={(e) => setExtendMessage(e.target.value)} />
          </Col>
          <div className="px-3">
            <Btn className={`px-3 mt-1 ${Style.extend_time_button}`} onClick={() => handleExtendBreak()}>
              Extend Time
            </Btn>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

ExtendBreakTime.propTypes = {
  // To handle on close
  modalCloseHandler: PropTypes.func.isRequired,

  // Callback function to handle positive response
  onTimeExtended: PropTypes.func.isRequired,

  // Callback to set any error notifications
  setNotificationData: PropTypes.func.isRequired,
}

export default ExtendBreakTime
