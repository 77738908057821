import React from "react"

import PropTypes from "prop-types"
import { Modal } from "react-bootstrap"
import Btn from "../../custom/Button"

import Style from "./session-break.module.scss"

const ConfirmEndBreakModal = (props) => {
  return (
    <Modal
      show={true}
      onHide={props.modalCloseHandler}
      centered={true}
      size={"md"}
      className={`${Style.instant_modal} text-light`}
      contentClassName={`${Style.extend_modal_content}`}
    >
      <Modal.Body className={``}>
        <div className="d-flex flex-column align-items-center pt-4 pb-4">
          <p className="m-0 fs-18px">Are you sure you want to end the break?</p>
          <p className={`fs-14px`}>
            You still have {props.minutesLet}:{props.secondsLeft} Mins left
          </p>
          <Btn className={`px-3 mt-1 ${Style.confirm_end_break}`} onClick={props.onConfirm}>
            Yes, I want to end
          </Btn>
        </div>
      </Modal.Body>
    </Modal>
  )
}

ConfirmEndBreakModal.propTypes = {
  // To handle on close
  modalCloseHandler: PropTypes.func.isRequired,

  // Callback function to handle positive response
  onConfirm: PropTypes.func.isRequired,

  // Remaining time parameters
  minutesLet: PropTypes.string.isRequired,
  secondsLeft: PropTypes.string.isRequired,
}

export default ConfirmEndBreakModal
