import React, { useContext, useEffect, useState } from "react"

import { getRequest, postRequest } from "../../services/rest_service"
import Modal from "react-bootstrap/Modal"
import Image from "../custom/Image"
import Style from "./style.module.scss"

import LiveDiscussionBoard from "./live-discussion-board"
import LiveDiscussionStart from "./live-discussion-start"
import CollapsableBox from "./_collapsable_box"

import closeCircle from "../../images/svgs/close.svg"

import Loader from "../custom/Loader"

import TimeDifference from "../utilities/timeDifference"
import SelectTemplate from "./_select_template"
import { ClassroomContext } from "../../stores/classroom_store/ClassroomStore"
import { ClassroomSidebarContext } from "../../stores/classroom_store/classroom_sidebar/classroomSidebarStore"
import { SET_DISCUSSION_BOARD } from "../../stores/classroom_store/ClassroomActions"
import { SET_CURRENT_NAV_TAB } from "../../stores/classroom_store/classroom_sidebar/classroomSidebarActions"

const LiveDiscussion = () => {
  const [classroomState, dispatch] = useContext(ClassroomContext)
  const [classroomSidebarState, classroomSidebarDispatch] = useContext(ClassroomSidebarContext)

  const [discussionTab, setDiscussionTab] = useState("start")
  const [discussionList, setDiscussionList] = useState([])
  const [discussionLoader, setDiscussionLoader] = useState(false)
  const [discussionResultModal, setDiscussionResultModal] = useState(null)

  useEffect(() => {
    fetchPreviousDiscussionCount()
  }, [])

  useEffect(() => {
    if (discussionTab === "list" && discussionList.length != classroomState.discussionBoard.count) {
      fetchDiscussionBoardList(classroomState.training_id)
    }
  }, [discussionTab])

  const fetchDiscussionBoardList = async (trainingId) => {
    setDiscussionLoader(true)
    let response = await getRequest(`/live-board/live-discussion-board/?training=${trainingId}&session_status=2`)
    if (response.success) {
      setDiscussionList(response.data)
      setDiscussionLoader(false)
    }
  }

  async function fetchPreviousDiscussionCount() {
    let res = await getRequest(`/live-board/live-discussion-board/get_only_count/?training=${classroomState.training_id}&session_status=2`)
    if (res.success) {
      dispatch({ type: SET_DISCUSSION_BOARD, payload: { ...classroomState.discussionBoard, count: res.data.count } })
    }
  }

  function handleLiveDiscussionModalHide() {
    dispatch({ type: SET_DISCUSSION_BOARD, payload: { ...classroomState.discussionBoard, modal: false } })
    classroomSidebarDispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
    setDiscussionTab("start")
  }

  async function closePublishDiscussion(id) {
    let formData = new FormData()
    formData.append("publish_action", "close_ldb")
    let res = await postRequest(`/live-board/live-discussion-board/${id}/publish_ldb_to_student/`, formData)
    if (res.success) {
      setDiscussionResultModal(null)
    }
  }

  function handleDiscussionBoardResultModalHide() {
    closePublishDiscussion(discussionResultModal.id)
  }

  function liveDiscussionBoardResultModal() {
    return (
      <Modal
        show={discussionResultModal !== null}
        onHide={() => {
          handleDiscussionBoardResultModalHide()
        }}
        backdrop="static"
        size="md"
        centered
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName={`${Style.live_discussion_result_modal}`}
        contentClassName={`${Style.questions_modal}`}
      >
        <Modal.Header className={`px-2 pt-1 pb-0 ${Style.border_transparent}`}>
          <div className={`w-100 d-flex justify-content-between`}>
            <div className={`d-flex fs-16px align-items-center`}>
              <div className={`mr-1`}>Live Discussion Board</div>
            </div>
            <div>
              <Image className={`icon`} onClick={handleDiscussionBoardResultModalHide} src={closeCircle} alt={`close`} />
            </div>
          </div>
        </Modal.Header>
        <hr className={`my-0 mx-auto ${Style.horizontal_line}`} />
        <Modal.Body
          className={`p-0 overflow-auto`}
          onScroll={(e) => {
            e.stopPropagation()
          }}
          style={{ maxHeight: "650px" }}
        >
          {discussionResultModal?.id && <LiveDiscussionBoard type="result" boardId={discussionResultModal.id} endShare={closePublishDiscussion} />}
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <>
      <Modal
        show={classroomState.discussionBoard.modal}
        onHide={() => {
          handleLiveDiscussionModalHide()
        }}
        size="md"
        centered
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName={`${Style.live_discussion_modal}`}
        contentClassName={`${Style.questions_modal}`}
      >
        <Modal.Header className={`pt-1 pb-0 ${Style.border_transparent}`}>
          <div className={`w-100 d-flex justify-content-between`}>
            <div className={`d-flex fs-16px align-items-center`}>
              <div
                className={`pointer mr-2 ${discussionTab === "start" ? Style.active_tab : Style.inactive_tab}`}
                onClick={(e) => {
                  setDiscussionTab("start")
                }}
              >
                Live Discussion Board
              </div>
              <div
                className={`pointer ${discussionTab === "list" ? Style.active_tab : Style.inactive_tab}`}
                onClick={(e) => {
                  setDiscussionTab("list")
                }}
              >
                Previous Discussions ({classroomState.discussionBoard.count})
              </div>
            </div>
            <div>
              <Image className={`icon`} onClick={handleLiveDiscussionModalHide} src={closeCircle} alt={`close`} />
            </div>
          </div>
        </Modal.Header>
        <hr className={`my-0 mx-auto position-relative ${Style.tab_horizontal_line}`} />
        <Modal.Body
          className={`p-0 overflow-auto`}
          onScroll={(e) => {
            e.stopPropagation()
          }}
          style={{ maxHeight: "560px" }}
        >
          {discussionTab === "start" && (
            <div className={`d-flex flex-column`}>
              <SelectTemplate />
              <div className={`${Style.hr_with_center_text} mx-auto mb-2`}>
                <span className={`px-2`}>or</span>
              </div>
              <div className={`mx-3 mb-2`}>
                <CollapsableBox title="Create a new discussion">
                  <LiveDiscussionStart />
                </CollapsableBox>
              </div>
            </div>
          )}
          {discussionTab === "list" && (
            <div className={`d-flex flex-column px-3 py-2`}>
              {discussionLoader && (
                <div className="w-100 text-center">
                  <Loader />
                </div>
              )}
              {discussionList.length === 0 && <div className="w-100 text-center">No discussion board found.</div>}
              {discussionList.map((data, index) => {
                return (
                  <div key={`board_${index}`} className={`p-2 mb-2 d-flex justify-content-between ${Style.dicussion_list_item}`}>
                    <div className="d-flex flex-column">
                      <div className="w-100 fs-16px">{data.name}</div>
                      <div className="fs-14px text-grey">
                        <span>Ended {TimeDifference.getHowMuchTimeAgo(data.ended_at)} ago</span>
                        <span className="ml-3">{data.total_responses} responses</span>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setDiscussionResultModal(data)
                      }}
                      className="link align-self-center"
                    >
                      View
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </Modal.Body>
      </Modal>
      {liveDiscussionBoardResultModal()}
    </>
  )
}

export default LiveDiscussion
