import React, { useContext, useState } from "react"
import PropTypes from "prop-types"

import Btn from "../../custom/Button"
import AvatarCircle from "../../custom/AvatarCircle"
import Loader from "../../custom/Loader"
import ReplyResource from "./replyResource"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/free-solid-svg-icons"
import ArrowDown from "../../../images/svgs/arrow-dropdown-white-circle.svg"
import ArrowUp from "../../../images/svgs/arrow-dropup-white-circle.svg"

import { getRequest } from "../../../services/rest_service"
import utcDateTime from "../../../services/utcdatetime"

import Style from "../style.module.scss"

import { QuestionContext } from "../../../stores/question_store/QuestionStore"

import { SET_REPLY_DATA, SET_REPLY_NEXT_DATA, SET_SHOW_QUESTION_MODAL } from "../../../stores/question_store/QuestionActions"
import TopicsDropdown from "../TopicsDropdown"
import Image from "../../custom/Image"

/**
 * Component for single expandable question
 * @param {object} props.data - question data
 * @param {bool} props.isSidebarList - boolean to check source is sidebar or not
 * @returns {React.ReactElement} Question Box
 */

const QuestionBox = ({ data, isSidebarList }) => {
  const [questionStore, questionDispatch] = useContext(QuestionContext)

  const [expand, setExpand] = useState(false)
  const [loader, setLoader] = useState(false)

  let questionReplyData = questionStore.reply_data
  let questionReplyNextUrl = questionStore.reply_next_data

  const fetchReplies = async (object_id) => {
    try {
      let key = `ques_${object_id}`
      if (!(questionReplyData && questionReplyData[key])) {
        setLoader(true)
        let url = `&query=${object_id}`
        let res = await getRequest(`/replies/?basic-data=true&published=true&ordering=-id${url}`)
        if (res.success) {
          let data = { ...questionReplyData }
          let urlData = { ...questionReplyNextUrl }
          data[key] = res.data.results
          urlData[key] = res.data.next
          questionDispatch({ type: SET_REPLY_DATA, payload: data })
          questionDispatch({ type: SET_REPLY_NEXT_DATA, payload: urlData })
          setLoader(false)
        }
      }
      setExpand((prev) => !prev)
    } catch (error) {}
  }

  const fetchNextReplies = async () => {
    let key = `ques_${data.id}`
    if (!data?.id && !questionReplyNextUrl[key]) return

    setLoader(true)
    let response = await getRequest(questionReplyNextUrl[key])
    if (response.success) {
      let urlData = { ...questionReplyNextUrl }
      urlData[key] = response.data.next
      let replyData = { ...questionReplyData }
      let quesData = replyData[key]
      quesData = [...response.data.results, ...quesData]
      replyData[key] = quesData
      questionDispatch({ type: SET_REPLY_DATA, payload: replyData })
      questionDispatch({ type: SET_REPLY_NEXT_DATA, payload: urlData })
      setLoader(false)
    } else {
      console.error(response.data.msg)
    }
  }

  const handleReplyClick = (e) => {
    let modalData = { type: "question", title: "All Questions", data: data }
    questionDispatch({ type: SET_SHOW_QUESTION_MODAL, payload: modalData })
  }

  const handleQuestionScroll = (e) => {
    e.stopPropagation()
    let div = e.target
    if (div.scrollTop + div.clientHeight >= div.scrollHeight - 20 && questionReplyNextUrl[`ques_${data.id}`] && !loader) {
      fetchNextReplies()
    }
  }

  return (
    <div className={`${Style.modal_box} mb-2`}>
      <div className={`d-flex justify-content-between px-3 pt-2 pb-3 ${expand && "mb-2"} ${Style.border_bottom}`}>
        <div className={`d-inline-flex flex-grow-1`}>
          <AvatarCircle name={data?.owner?.first_name} avatar={data?.owner?.profile_pic} />
          <div className={`ml-2`}>
            <div className={`fs-16px ellipsis_1_effect font-weight-bold`}>{data.title}</div>
            <div className={`d-inline-flex`}>
              {!isSidebarList && questionStore.show_question_modal?.id === "modal_all_questions" ? <TopicsDropdown questionData={data} /> : null}
              <div className={`mr-1 fs-14px align-self-center`}>
                <span className={`${!isSidebarList && "ml-2 "}text-grey`}>Asked by </span>
                <span className={`underline`}>{data?.owner?.first_name}</span>
                <span className={`text-grey`}> on </span>
                {`${utcDateTime.utcDateTime(data?.updated_at, "month_name")} ${utcDateTime.utcDateTime(data?.updated_at, "date")}, ${utcDateTime.utcDateTime(
                  data?.updated_at,
                  "year"
                )}`}
              </div>
              {data?.votes?.count > 0 && (
                <div className={`mx-2 fs-14px align-self-center px-2 py-1 flex-shrink-0 ${Style.like_count}`}>
                  <FontAwesomeIcon className={`mr-1`} icon={faHeart} /> {data.votes.count}
                </div>
              )}
            </div>
          </div>
        </div>
        {data.reply_count > 0 && (
          <div>
            <Image onClick={(e) => fetchReplies(data.id)} className={`icon`} src={expand ? ArrowUp : ArrowDown} />
          </div>
        )}
      </div>
      {expand && questionReplyData && questionReplyData[`ques_${data.id}`] ? (
        <div onScroll={handleQuestionScroll} className={`mb-2 pl-4 pr-2 overflow-auto`} style={{ maxHeight: "260px" }}>
          {questionReplyData[`ques_${data.id}`].map((data, index) => {
            return (
              <div key={`reply1_${index}`} style={{ backgroundColor: "#4F6080" }} className={`${Style.modal_box} fs-16px mb-2 p-2`}>
                <div className={`d-inline-flex`}>
                  <AvatarCircle index={index} name={data?.owner?.first_name} avatar={data?.owner?.profile_pic} />
                  <div className={`ml-2 fs-14px align-self-center d-flex flex-column`}>
                    <div className={`text-grey`}>{`${utcDateTime.utcDateTime(data.updated_at, "time")} ${utcDateTime.utcDateTime(
                      data.updated_at,
                      "month_name"
                    )} ${utcDateTime.utcDateTime(data.updated_at, "date")}, ${utcDateTime.utcDateTime(data.updated_at, "year")}`}</div>
                    <div>{data.title}</div>
                    <div className={`d-flex flex-column my-2`}>
                      {data?.resources?.map((item) => {
                        return <ReplyResource key={item.id} item={item} />
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      ) : null}
      {loader && (
        <div className={`w-100 text-center`}>
          <Loader />
        </div>
      )}
      <div
        className={`d-inline-flex py-2 ${isSidebarList && "px-3"} w-100 ${expand && Style.border_top}`}
        style={!isSidebarList ? { paddingLeft: `${expand ? "30px" : "60px"}` } : null}
      >
        <Btn className={"px-3"} onClick={handleReplyClick}>
          Reply
        </Btn>
        <div className={`align-self-center fs-12px ml-2 `}>{data.reply_count > 0 ? data.reply_count + " Answers" : "You are yet to reply!"}</div>
      </div>
    </div>
  )
}

QuestionBox.propTypes = {
  data: PropTypes.object.isRequired,
  isSidebarList: PropTypes.bool,
}

export default QuestionBox
