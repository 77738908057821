import React, { createContext, useReducer } from "react"
import LessonboardReducer from "./LessonboardReducer"

const initalState = {
  modules: [],
  topics: {},
  resources: {},
  pendingTopics: 0,
  draggedItem: null,
}

const LessonboardStore = ({ children }) => {
  const [state, dispatch] = useReducer(LessonboardReducer, initalState)
  return <LessonboardContext.Provider value={[state, dispatch]}>{children}</LessonboardContext.Provider>
}

export const LessonboardContext = createContext()
export default LessonboardStore
