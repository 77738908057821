import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Modal } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import CancelWhite from "../../../../images/svgs/close.svg"
import Style from "../../../class-room-training/lesson-board.module.scss"
import StylesA from "../../../template-resources/breakout-session.module.scss"

const ExtendTimeModalFromNotification = (props) => {
  const [duration, setDuration] = useState(parseInt(props.notification_data?.duration))

  useEffect(() => {
    if (props.notification_data?.duration) {
      setDuration(parseInt(props.notification_data?.duration))
    }
  }, [props.notification_data?.duration])

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      centered
      size="md"
      className={`${StylesA.classroom_delete_modal} zIndex-9999 classroom-resource-delete-modal p-0`}
    >
      <Modal.Body className="float-left text-center p-3 br-8px bg-2">
        <p className={`mb-3 fs-14px text-E0E2E570`}>
          <u className={`bold-500`}>{props.notification_data?.student_name}</u> From{" "}
          <span className={`bold-500`}>
            <u>{props.notification_data?.group_name}</u>
          </span>{" "}
          needs {props.notification_data?.duration} mins more
        </p>
        <img
          onClick={(e) => {
            props.onHide()
          }}
          src={CancelWhite}
          alt={`Cancel`}
          className={`ml-auto mr-1 mb-0 pointer ${StylesA.img_cancel}`}
        />
        <div className={`d-inline-block text-center py-3 px-5 br-9px box-shadow bg-42506C`}>
          <p className={`mb-2 fs-14px text-E0E2E570`}>Extend time by</p>
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              props.updateSessionDuration(duration, "extend_time_modal")
              props.onHide()
            }}
          >
            <input
              type="number"
              min="1"
              defaultValue={parseInt(duration)}
              onChange={(e) => {
                let val = props.handleChangeExtendMins(e)
                setDuration(val)
              }}
              className={`${StylesA.session_input_field} ${StylesA.width_60px} mx-auto form-control shadow-none px-2 ${StylesA.border_on_hover} bold-500 fs-36px`}
            />
            <p className={`mb-3 fs-16px text-E0E2E570`}>Mins</p>
            <button type="submit" className={`poppins_font px-4 py-1 ${Style.breakout_modal_fotter_btn_inactive} border_on_hover`}>
              <p className={`mb-0 poppins_font fs-16px bold-500`}>Extend</p>
            </button>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  )
}

ExtendTimeModalFromNotification.propTypes = {
  notification_data: PropTypes.object.isRequired, //Contains all the notiication data which is needed
  show: PropTypes.bool.isRequired, // For showing modal
  onHide: PropTypes.func.isRequired, // For hiding  the modal
  updateSessionDuration: PropTypes.func, //For updating session duration in parent comp
  handleChangeExtendMins: PropTypes.func,
}

export default ExtendTimeModalFromNotification
