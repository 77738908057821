import React, { useEffect, useState } from "react"
import Style from "./style.module.scss"
import CommentCard from "./_comment-card"

const SubTopic = (props) => {
  const [showComments, setShowComments] = useState(false)

  useEffect(() => {
    if (props?.commentsData[`subtopic_${props.subTopicData.id}`]) {
      setShowComments(true)
    }
  }, [props?.commentsData[`subtopic_${props.subTopicData.id}`]])

  return (
    <div
      className={`${Style.subtopic} ${
        props.topics_length >= 4 ? "col-lg-3" : props.topics_length == 3 ? "col-lg-4" : props.topics_length == 2 ? "col-lg-6" : "col-lg-12"
      }`}
    >
      <div>{props.subTopicData.name !== null ? props.subTopicData.name : ""}</div>
      {showComments && (
        <div className="row">
          {props?.commentsData[`subtopic_${props.subTopicData.id}`]?.map((comment, index) => {
            return <CommentCard index={index} key={`comment_${index}`} data={comment} topicCount={props.topics_length} />
          })}
        </div>
      )}
    </div>
  )
}

export default SubTopic
