import React, { useContext } from "react"
import Modal from "react-bootstrap/Modal"
import Btn from "../../../custom/Button"
import Image from "../../../custom/Image"
import getImageToBeRendered from "../../../utilities/resourceIcons"
import { SHOW_ACTIVE_ACTIVITY } from "../../../../stores/classroom_store/ClassroomActions"
import { ClassroomContext } from "../../../../stores/classroom_store/ClassroomStore"

import StyleA from "./style.module.scss"

import DiscussionIcon from "../../../../images/svgs/classroom/discussionnav.svg"
import TeaCup from "../../../../images/svgs/classroom/tea_cup.svg"
import breakoutsession from "../../../../images/svgs/breakoutsession.svg"
import MuralIcon from "../../../../images/svgs/mural-icon.svg"
import otherLinkIcon from "../../../../images/svgs/link.svg"

const ICONSOBJECT = {
  training_breaks: TeaCup,
  live_discussion: DiscussionIcon,
  mural: MuralIcon,
  otherLink: otherLinkIcon,
}

const ActivityWarning = (props) => {
  const [classroomState, dispatch] = useContext(ClassroomContext)
  let current_active_activity = classroomState.activeActivityData?.current_active_activity
  let resource = current_active_activity?.resource
  let activity_title = null
  let iconData = {}
  let iconSrc = breakoutsession
  if (resource?.id) {
    iconData = getImageToBeRendered(resource)
    activity_title = resource?.resource_data?.name
    iconSrc = iconData?.imgObj
  } else if (current_active_activity?.trigger_type === 2) {
    activity_title = current_active_activity?.triggered_data?.type ?? ""
    if (activity_title) {
      iconSrc = ICONSOBJECT[`${activity_title}`]
    }
  } else {
    activity_title = current_active_activity?.name ?? ""
    if (current_active_activity?.type) {
      iconSrc = ICONSOBJECT[`${current_active_activity?.type}`]
    }
  }

  return (
    <Modal
      show={classroomState.showActiveActivity}
      onHide={(e) => {
        dispatch({ type: SHOW_ACTIVE_ACTIVITY, payload: false })
      }}
      centered
      className={`${StyleA.instant_modal} text-light`}
      contentClassName={`${StyleA.extend_modal_content}`}
    >
      <Modal.Body className={`${StyleA.warning_body}`}>
        <div className="d-flex flex-column pb-3 pt-4">
          <React.Fragment>
            <p className={`${StyleA.active_warning_title} ${StyleA.warning_text_color} pl-4`}>Please close the active task to begin a new one</p>
            <p className={`${StyleA.active_warning_details} ${StyleA.warning_text_color} ${StyleA.active_lable} pl-4 pb-2 pt-2`}>
              <span>Active :</span>
              <Image className={`my-auto ${StyleA.activity_icon} ${StyleA.warning_icon_color} pl-1 pr-1`} src={iconSrc} alt={activity_title} />
              <span>{activity_title}</span>
            </p>
            <div className={`d-inline-flex px-4 py-2 w-100`}>
              <Btn
                onClick={(e) => {
                  e.preventDefault()
                  dispatch({ type: SHOW_ACTIVE_ACTIVITY, payload: false })
                }}
                type="small"
                className={`${StyleA.warning_button} ${StyleA.warning_text_color} ${StyleA.active_lable}`}
              >
                {" "}
                <span className={`p-4`}>Got It</span>{" "}
              </Btn>
            </div>
          </React.Fragment>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ActivityWarning
