import React, { useState } from "react"
import { getRequest } from "../../services/rest_service"

import Image from "../custom/Image"
import arrow from "../../images/svgs/down_arrow_white.svg"
import Dropdown from "react-bootstrap/Dropdown"
import Style from "../class-room-training/lesson-board.module.scss"
import Loader from "../custom/Loader"

const StudentListDropdown = (props) => {
  const [groupStudentList, setGroupStudentList] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  let url, code

  switch (props?.type) {
    case "checklist": {
      code = "ck"
      url = `/checklist/group/report_card/${props.activeGroupId}/student_list/?limit=8`
      break
    }
    case "casestudy": {
      code = "cs"
      url = `/case-study-group-report-card/${props.activeGroupId}/students_list/?limit=8`
      break
    }
    case "quiz": {
      code = "quiz"
      url = `/quiz-group-report-card/${props.activeGroupId}/students_list/?limit=8`
    }
  }

  const fetchGroupMemberList = async () => {
    setIsLoading(true)
    let response = await getRequest(url)
    if (response.success) {
      let data = { ...groupStudentList }
      data[`${code}_${props.activeGroupId}`] = response.data
      setGroupStudentList(data)
      setIsLoading(false)
    }
  }

  const fetchGroupMemberListNext = async (next_url) => {
    setIsLoading(true)
    let response = await getRequest(next_url)
    if (response.success) {
      let data = { ...groupStudentList }
      let currentData = data[`${code}_${props.activeGroupId}`]
      currentData.results = [...currentData.results, ...response.data.results]
      currentData.next = response.data.next
      data[`${code}_${props.activeGroupId}`] = currentData
      setGroupStudentList(data)
      setIsLoading(false)
    }
  }

  const studentListData = props.studentListData || groupStudentList[`${code}_${props.activeGroupId}`]

  const handleToggle = () => {
    if (props.activeGroupId && groupStudentList && !groupStudentList[`${code}_${props.activeGroupId}`]) {
      fetchGroupMemberList()
    }
  }
  return (
    <Dropdown className={"session-time-drop-down px-0 w-fit-content"} onToggle={handleToggle}>
      <Dropdown.Toggle as="div" id="dropdown-custom-components">
        <div className={`inner-element-box-shadow px-2 mx-2 pointer border_on_hover position-relative bg-586886 text-E0E2E5 br-6px`}>
          <span> {props?.title} Members</span>
          <Image className={`ml-2 pointer icon}`} src={arrow} />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className={`${Style.active_input_drop_down}`}>
        <div className={`${Style.dropdown_list} font-weight-bold py-2 fs-16px`}>{props?.title} Members</div>
        <div className="max-h-280px overflow-auto">
          {studentListData?.results && studentListData?.results.length > 0 ? (
            <>
              {studentListData?.results.map((student, index) => {
                return (
                  <div key={`student_${index}`} className={`d-flex align-items-center ${Style.dropdown_list}`}>
                    <div className={`circle text-center font-weight-bolder bg-212C42 p-1 h-2rem w-2rem`}>{student?.first_name?.charAt(0) ?? "S"}</div>
                    <div className={`ml-2`}>{student?.first_name}</div>
                  </div>
                )
              })}
              {isLoading && (
                <div className="text-center my-3">
                  <Loader class="text-white" />
                </div>
              )}
            </>
          ) : isLoading ? (
            <div className="text-center my-3">
              <Loader class="text-white" />
            </div>
          ) : (
            "No Students"
          )}
          {studentListData?.next && (
            <div className="fs-12px d-flex justify-content-start pb-0">
              <div
                className={`underline pointer`}
                onClick={() => {
                  fetchGroupMemberListNext(studentListData?.next)
                }}
              >
                See More
              </div>
            </div>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default StudentListDropdown
