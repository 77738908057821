import { postRequest, getRequest, putRequest, deleteRequest } from "../../services/rest_service"

//API's for match-the-following

const createMatching = async (formData) => await postRequest(`/match-the-followings/`, formData)

const getMatchingData = async (matchingId) => await getRequest(`/match-the-followings/${matchingId}/`)

const updateMatchingData = async (formData, matchingId) => await putRequest(`/match-the-followings/${matchingId}/`, formData)

const addMatchingRow = async (matchingId) => await postRequest(`/match-the-followings/${matchingId}/add_new_row/`)

const deleteMatchingRow = async (matchingId, question_id) => await postRequest(`/match-the-followings/${matchingId}/delete_row/${question_id}/`)

const addMatchingColumn = async (matchingId) => await postRequest(`/match-the-followings/${matchingId}/add_new_column/`)

const deleteMatchingColumn = async (matchingId, column_id) => await postRequest(`/match-the-followings/${matchingId}/delete_column/${column_id}/`)

const addMatchingAnswerCell = async (formData) => await postRequest(`/match-the-followings/answer/`, formData)

const deleteMatchingAnswerCell = async (formData, answer_id) => await deleteRequest(`/match-the-followings/answer/${answer_id}/`, formData)

const updateColumnData = async (formData, column_id) => await putRequest(`/match-the-followings/column/${column_id}/`, formData)

const updateQuestionData = async (formData, question_id) => await putRequest(`/match-the-followings/question/${question_id}/`, formData)

const updateAnswerCellData = async (formData, answer_id) => await putRequest(`/match-the-followings/answer/${answer_id}/`, formData)

const publishMatchTheFollowing = async (matchingId, formData) => await postRequest(`/match-the-followings/${matchingId}/publish/`, formData)

const getGroupReportMatchingData = async (report_card_id) => await getRequest(`/match-the-followings/reportcard/${report_card_id}/`)

const shareMatchingResultBoard = async (formData) => await postRequest(`/match-the-followings/actions/share/`, formData)

const stopSharingMatchingBoard = async (formData) => await postRequest(`/match-the-followings/actions/stop_share/`, formData)

export {
  createMatching,
  getMatchingData,
  updateMatchingData,
  addMatchingRow,
  deleteMatchingRow,
  addMatchingColumn,
  deleteMatchingColumn,
  addMatchingAnswerCell,
  deleteMatchingAnswerCell,
  updateColumnData,
  updateQuestionData,
  updateAnswerCellData,
  publishMatchTheFollowing,
  getGroupReportMatchingData,
  shareMatchingResultBoard,
  stopSharingMatchingBoard,
}
