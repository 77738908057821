import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Style from "./style.module.scss"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleUp, faChevronCircleDown } from "@fortawesome/free-solid-svg-icons"

const CollapsableBox = (props) => {
  const [collapse, setCollapse] = useState(false)

  function handleToggleCollapse() {
    setCollapse((prev) => !prev)
  }

  useEffect(() => {
    if (props.defaultClose) {
      setCollapse(true)
    }
  }, [props.defaultClose])

  return (
    <div className={`my-2 ${Style.wrapper}`}>
      <div className={`${Style.header_block} ${!collapse && Style.header_border_bottom} px-3 py-2`}>
        <div>{props.title}</div>
        <div>
          <FontAwesomeIcon className={`pointer`} onClick={handleToggleCollapse} icon={collapse ? faChevronCircleDown : faChevronCircleUp} />
        </div>
      </div>
      {!collapse && <div>{props.children}</div>}
    </div>
  )
}

export default CollapsableBox

CollapsableBox.propTypes = {
  //title of the container
  title: PropTypes.string,

  //initial state of box i.e. open or collapsed by default it is open
  defaultClose: PropTypes.bool,
}
