import React, { useEffect, useState, useContext } from "react"
import { ClassroomSidebarContext } from "../../../stores/classroom_store/classroom_sidebar/classroomSidebarStore"
import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"
import { SET_CURRENT_NAV_TAB } from "../../../stores/classroom_store/classroom_sidebar/classroomSidebarActions"
import { SET_TRAINING_BREAK } from "../../../stores/classroom_store/ClassroomActions"

import Notification from "../../custom/Notification"
import ScheduleBreakModal from "./_schedule_break"
import InstantBreakModal from "./_instant_break"
import MostlyUsedBreakModal from "./_mostly_used_break"
import OngoingBreakBar from "./_ongoing_break"
import BreakEndedMessage from "./_break_ended"

const SessionBreak = () => {
  // States
  const [modalComponent, setModalComponent] = useState(null)
  const [notificationData, setNotificationData] = useState(null)
  const [sidebarState, sidebarDispatch] = useContext(ClassroomSidebarContext)
  const [classroomState, dispatch] = useContext(ClassroomContext)

  // Callback methods from modals
  // 1. Close the modal and cleanup
  const handleSessionBreakModalHide = (type) => {
    sidebarDispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
    setModalComponent(null)
    //empty training break prop when break ends.
    if (type == "end-break") dispatch({ type: SET_TRAINING_BREAK, payload: null })
  }

  // 2. called when 'instant beak' is selected in 'ScheduleBreakModal'
  const onClickScheduleInstantBreak = () => {
    // Set modal component to 'InstantBreakModal' in order to schedule a break
    setModalComponent(getInstantBreakModal())
  }

  // 3. Called when a break got triggered successfully
  const onBreakTriggered = () => {
    handleSessionBreakModalHide()
  }

  //4. Called when any one of mostly used breaks clicked.
  const onClickOfMostlyUsedBreaks = (break_data) => {
    setModalComponent(getMostlyUsedBreaksModal(break_data))
  }

  // Modal components
  // 1. Schedule break (should be shown when clicked on left-nav 'tea' icon)
  const getScheduleBreakModal = () => (
    <ScheduleBreakModal
      handleSessionBreakModalHide={handleSessionBreakModalHide}
      onClickScheduleInstantBreak={onClickScheduleInstantBreak}
      onClickOfMostlyUsedBreaks={onClickOfMostlyUsedBreaks}
    />
  )

  // 2. Create instant break
  const getInstantBreakModal = () => (
    <InstantBreakModal
      handleInstantBreakModalHide={handleSessionBreakModalHide}
      onInstantBreakTriggered={onBreakTriggered}
      setNotificationData={setNotificationData}
    />
  )

  // 3. Break Ended modal
  const getBreakEndedModal = () => (
    <BreakEndedMessage modalCloseHandler={() => handleSessionBreakModalHide("end-break")} breakName={classroomState.trainingBreak.break_name} />
  )

  //4. Mostly used break modal with content auto filled
  const getMostlyUsedBreaksModal = (break_data) => (
    <MostlyUsedBreakModal
      mostlyUsedBreakData={break_data}
      handleMostlyUsedBreakModalHide={handleSessionBreakModalHide}
      onInstantBreakTriggered={onBreakTriggered}
      setNotificationData={setNotificationData}
    />
  )

  // Define use effects below
  useEffect(() => {
    // By default modal component will be "schedule instant break". so whenever prop.showSessionBreak is True - show Schedule break modal
    if (classroomState.trainingBreak === null) setModalComponent(getScheduleBreakModal())
  }, [])

  useEffect(() => {
    // If, break status is ended - then show Break ended modal
    if (classroomState.trainingBreak?.status == "2") setModalComponent(getBreakEndedModal())
  }, [classroomState.trainingBreak])

  return (
    <>
      {modalComponent}
      {classroomState.trainingBreak && classroomState.trainingBreak?.status === "1" && <OngoingBreakBar setNotificationData={setNotificationData} />}
      <Notification data={notificationData} setData={setNotificationData} />
    </>
  )
}

export default SessionBreak
