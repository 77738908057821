import React from "react"
import _ from "lodash"

import PropTypes from "prop-types"
import Col from "react-bootstrap/Col"

import AnswerCell from "./_answer_cell"

function AnswerColumn(props) {
  const matchingData = props.matchingData

  return (
    <Col
      id={`row_${props.row_index}_column_${props.col_index}`}
      key={`row_${props.row_index}_column_${props.col_index}_${matchingData?.mtfcolumn_set[props.col_index]?.id}`}
      xs={props.getWidthOfColumn()}
      className={`pt-2 ${props.mtf_type === "1" ? `d-flex flex-column pb-2` : `d-flex`} align-items-stretch`}
      style={{ borderRight: props.col_index !== props.single_row?.answers_list?.length - 1 ? "1px solid #FFFFFF50" : "" }}
    >
      {props.answers.map((answer_cell, col_child_index) => {
        return (
          <AnswerCell
            type={props.type}
            key={`answers_list_${col_child_index}_${answer_cell?.id}`}
            single_row={props.single_row}
            answers={props.answers}
            mtf_type={matchingData?.mtf_type}
            answer_cell={answer_cell}
            col_child_index={col_child_index}
            row_index={props.row_index}
            col_index={props.col_index}
            matchingData={matchingData}
            handleUpdateHeightOfRow={props.handleUpdateHeightOfRow}
            setSavedContent={props.setSavedContent}
            handleDeleteRow={props.handleDeleteRow}
            handleUpdateAnswerCellData={props.handleUpdateAnswerCellData}
            setShowRowDeleteModal={props.setShowRowDeleteModal}
            setActiveDeleteRowData={props.setActiveDeleteRowData}
            handleDeleteAnswerField={props.handleDeleteAnswerField}
            showValidationErrors={props.showValidationErrors}
          />
        )
      })}
      {props.mtf_type === "1" && props.type !== "view_detail" ? (
        <p
          key={`add_options_row_${props.row_index}_column_${props.col_index}`}
          className="mb-0 underline pointer pl-2 pt-1"
          onClick={(e) => {
            e.preventDefault()
            props.handleAddMultiAnswer(props.single_row?.question_data?.id, props.column_id, props.row_index, props.col_index)
          }}
          style={{ width: "max-content", fontSize: "12px", color: "white" }}
        >
          + Add more options
        </p>
      ) : null}
    </Col>
  )
}

AnswerColumn.propTypes = {
  // Index of a particular Row
  row_index: PropTypes.number.isRequired,

  // Index of a particular Column
  col_index: PropTypes.number.isRequired,

  //Data of a particular row
  single_row: PropTypes.object.isRequired,

  //Array of answers list for a particular row-col field
  answers: PropTypes.array.isRequired,

  //Catergoy of match the following
  mtf_type: PropTypes.string.isRequired,

  // To handle on height of the answer box
  handleUpdateHeightOfRow: PropTypes.func.isRequired,

  // TO get the column width of a column
  getWidthOfColumn: PropTypes.func.isRequired,

  // Callback to add the answers for a one-to-many MTF
  handleAddMultiAnswer: PropTypes.func,
}

export default AnswerColumn
