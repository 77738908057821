import React, { useState } from "react"
import Style from "./style.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// !definition of component
/**
 *
 * @param props --> name, required, label, icon, defaultValue, onChange and onBlur function
 * @description -->  name=> for id and name, required=> boolean, icon => object {name: faIcon}
 * @returns TextArea component
 */
// ! component
const TextArea = (props) => {
  const [error, setError] = useState(null)

  function handleChange(e) {
    setError(null)
    if (props.onChange) {
      props.onChange(e, props.name)
    }
  }

  return (
    <div style={props?.style} className={`${props?.className} ${Style.input_group} ${error && Style.red_border}`}>
      <div className="p-2 w-100 d-flex">
        {props.icon && (
          <div className={`p-1`}>
            <FontAwesomeIcon
              className="h-100"
              style={{
                color: "#838B9E",
                fontSize: "18px",
                width: "25px",
              }}
              icon={props?.icon?.name}
            />
          </div>
        )}
        <div className={`flex-grow-1 ${props?.icon ? "ml-2" : ""}`}>
          <textarea
            id={props?.name}
            name={props?.name}
            defaultValue={props.defaultValue ? props.defaultValue : ""}
            onChange={handleChange}
            className={`${Style.inputText}`}
            onInvalid={(e) => {
              e.preventDefault()
              e.target.focus()
              setError("true")
            }}
            rows={props.rows ? props.rows : "3"}
            onBlur={props?.onBlur}
            required={props?.required}
          />
          <span style={props?.icon && { left: "auto" }} className={`${Style.floating_label} d-flex-align-center`}>
            {props.label}
            {!props.required && <span className={`ml-1`}>(Optional)</span>}
          </span>
        </div>
      </div>
    </div>
  )
}

export default TextArea
