import React, { useEffect, useState, useContext } from "react"
import _ from "lodash"

import Col from "react-bootstrap/Col"

import Loader from "../custom/Loader"
import Btn from "../custom/Button"
import Notification from "../custom/Notification"
import { BreakoutGroupContext } from "../../stores/socket/breakout_group_socket/breakoutGroupProvider"

import RowColumnTable from "./_row_column_table"

import { getMatchingData, getGroupReportMatchingData, shareMatchingResultBoard, stopSharingMatchingBoard } from "./_matching_apis"

import StylesA from "./matching.module.scss"

function MatchingViewDetail(props) {
  const [allMatchingsData, setAllMatchingsData] = useState({})

  const [activeSection, setActiveSection] = useState("submitted")
  const [fetchedMatchingData, setFetchedMatchingData] = useState(false)

  const [notificationData, setNotificationData] = useState(null)

  const [breakoutWsReady, breakoutWsVal, breakoutWsSend] = useContext(BreakoutGroupContext)

  const key = `group_${props.activeGroup.report_card}`

  useEffect(() => {
    if (
      breakoutWsVal.event_type === "match_the_following" &&
      breakoutWsVal.group_id === props.activeGroup?.group_id &&
      breakoutWsVal.data?.instance?.report_card === props.activeGroup?.report_card
    ) {
      const createdOrUpdatedAnswer =
        breakoutWsVal.action === "answer_created" || breakoutWsVal.action === "answer_updated" || breakoutWsVal.action === "answer_deleted"
      if (createdOrUpdatedAnswer && props.activeGroup?.report_card && activeSection === "submitted") {
        fetchGroupSubmittedMatchingAnswers(false)
      } else if (breakoutWsVal.action === "report_card_created_for_group") {
        fetchGroupSubmittedMatchingAnswers()
      }
    }
  }, [breakoutWsVal])

  useEffect(() => {
    if (activeSection === "submitted" && props.activeGroup.report_card) {
      fetchGroupSubmittedMatchingAnswers()
    } else {
      if (props.matching_id) {
        if (!allMatchingsData[key] || !allMatchingsData[key][activeSection] || Object.keys(allMatchingsData[key][activeSection]).length === 0) {
          fetchMatchTheFollowingCorrectData()
        }
      }
    }
  }, [activeSection, props.matching_id, props.activeGroup.report_card])

  //This useeffect is for sharing board every time u cchange the group or section(submitted, correct)
  useEffect(() => {
    if (props.sharingBoard) {
      shareBoard()
    }
  }, [activeSection, props.activeGroup.report_card])

  //This useEfftect is for stop sharing board if educator closes the modal
  useEffect(() => {
    if (props.stopSharingBoard) {
      if (props.sharingBoard) {
        stopSharingBoard()
      }
    }
  }, [props.stopSharingBoard])

  //Function for fetching the match-the-following data
  async function fetchMatchTheFollowingCorrectData() {
    setFetchedMatchingData(false)
    const res = await getMatchingData(props.matching_id)
    if (res.success) {
      let tempMatchingData = { ...allMatchingsData }
      if (tempMatchingData[key]) {
        tempMatchingData[key][`correct`] = res.data
      } else {
        tempMatchingData[key] = { submitted: {}, correct: {} }
        tempMatchingData[key][`correct`] = res.data
      }
      setAllMatchingsData(tempMatchingData)
      setFetchedMatchingData(true)
    } else {
      // showErrorMessage(res.msg)
    }
  }

  //Function for fetching the match-the-following group_report_card data
  async function fetchGroupSubmittedMatchingAnswers(showLoader = true) {
    showLoader && setFetchedMatchingData(false)
    const res = await getGroupReportMatchingData(props.activeGroup.report_card)
    if (res.success) {
      let dummy = { ...res.data }
      dummy.name = dummy.mtf_name
      delete dummy.mtf_name
      dummy.instructions = dummy.mtf_instruction
      delete dummy.mtf_instruction
      dummy.question_column_name = dummy.mtf_question_column
      delete dummy.mtf_question_column
      dummy.mtfcolumn_set = dummy.columns
      delete dummy.columns

      let dummy2 = { ...allMatchingsData }
      if (dummy2[key]) {
        dummy2[key][`submitted`] = dummy
      } else {
        dummy2[key] = { submitted: {}, correct: {} }
        dummy2[key][`submitted`] = dummy
      }
      setAllMatchingsData(dummy2)
      showLoader && setFetchedMatchingData(true)
    } else {
      // showErrorMessage(res.msg)
    }
  }

  async function shareBoard() {
    let formData = new FormData()
    formData.append("training", props.training_id)
    formData.append("resource", props.activeResource.id)
    formData.append("tab", activeSection === "submitted" ? "0" : "1")
    formData.append("report_card", props.activeGroup.report_card)
    formData.append("tab_title", props.activeGroup?.group_name) //This field is for student side modal usage
    const res = await shareMatchingResultBoard(formData)
    if (res.success) {
      if (!props.sharingBoard) {
        setNotificationData({
          type: "success",
          title: "This board has been shared successfully",
        })
        props.setSharingBoard(true)
      }
    } else {
      // showErrorMessage(res.msg)
    }
  }

  async function stopSharingBoard() {
    let formData = new FormData()
    formData.append("training", props.training_id)
    formData.append("resource", props.activeResource.id)
    formData.append("mtfversionmap__trigger_counter", "0")
    formData.append("tab_title", props.activeGroup?.group_name) //This field is for student side modal usage
    const res = await stopSharingMatchingBoard(formData)
    if (res.success) {
      setNotificationData({
        type: "success",
        title: "Sharing board stopped successfully",
      })
      if (props.stopSharingBoard && props.sharingBoard) {
        props.setShowViewDetailsModal(false)
      }
      props.setSharingBoard(false)
      props.setStopSharingBoard(false)
    } else {
      // showErrorMessage(res.msg)
    }
  }

  return (
    <Col xs="12" className="px-0 position-relative">
      {/* CODE FOR HEADER */}
      <Col xs="12" className="d-flex w-100 align-items-center px-0">
        <Col
          xs="6"
          className={`text-center py-1 pointer ${activeSection === "submitted" ? StylesA.active_section : StylesA.in_active_section}`}
          onClick={(e) => {
            setActiveSection("submitted")
          }}
          style={{ borderRight: activeSection === "submitted" ? "1px solid white" : "" }}
        >
          <p className="mb-0" style={{ fontSize: "16px", fontWeight: "500" }}>
            Submitted Answers
          </p>
        </Col>
        <Col
          xs="6"
          className={`text-center py-1 pointer ${activeSection === "correct" ? StylesA.active_section : StylesA.in_active_section}`}
          onClick={(e) => {
            setActiveSection("correct")
          }}
          style={{ borderLeft: activeSection === "correct" ? "1px solid white" : "" }}
        >
          <p className="mb-0" style={{ fontSize: "16px", fontWeight: "500" }}>
            Correct Answers
          </p>
        </Col>
      </Col>

      {/* CODE FOR BODY */}
      {fetchedMatchingData ? (
        <Col
          xs="12"
          className={`px-0 ${allMatchingsData[key] && allMatchingsData[key][activeSection]?.mtf_type === "0" ? "px-3" : "px-3"} pb-3`}
          style={{
            maxHeight: "500px",
            overflowY: "scroll",
            backgroundColor: allMatchingsData[key] && allMatchingsData[key][activeSection]?.mtf_type === "0" ? "#303c54" : '"#212d42"',
          }}
        >
          {allMatchingsData[key] && <RowColumnTable matchingData={allMatchingsData[key][activeSection]} type={`view_detail`} />}
        </Col>
      ) : (
        <div className="text-center my-5">
          <Loader class="text-white" />
        </div>
      )}

      {/* CODE FOR FOTTER. Will show only if session is ended */}
      {props.sessionData?.status === "2" && (
        <Col xs="12" className={`px-4 py-2`} style={{ backgroundColor: "#303C54", borderTop: "1px solid #FFFFFF70" }}>
          <Btn
            type="small"
            onClick={(e) => {
              e.preventDefault()
              if (props.sharingBoard) {
                stopSharingBoard()
              } else {
                shareBoard()
              }
            }}
            className={`px-4`}
            style={{
              backgroundColor: props.sharingBoard ? "#E88373" : "#586886",
              color: props.sharingBoard ? "#121B2B" : "#E0E2E5",
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            {props.sharingBoard ? `Stop Sharing` : `Share Board`}
          </Btn>
        </Col>
      )}

      {/*This Component is for showing Sucess and Error messages */}
      {notificationData && Object.keys(notificationData)?.length > 0 && (
        <Notification style={{ position: "absolute", top: "40%", left: "30%" }} data={notificationData} setData={setNotificationData} />
      )}
    </Col>
  )
}

export default MatchingViewDetail
