import React, { useContext } from "react"
import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"

import PropTypes from "prop-types"
import { Modal } from "react-bootstrap"

import Style from "./session-break.module.scss"
import SuccessBreak from "../../../images/svgs/classroom/success_break.svg"
import Image from "../../custom/Image"

const ExtendBreakTimeSuccess = (props) => {
  const [classroomState, dispatch] = useContext(ClassroomContext)
  return (
    <Modal
      show={true}
      onHide={props.modalCloseHandler}
      centered={true}
      size={"md"}
      className={`${Style.instant_modal} text-light`}
      contentClassName={`${Style.extend_modal_content}`}
    >
      <Modal.Body className={``}>
        <div className="d-flex flex-column align-items-center pt-5 pb-3">
          <Image src={SuccessBreak} alt={"success"} className={`m-1 ${Style.success_break}`} />
          <p className="mt-1 mb-0 fs-14px">{classroomState.trainingBreak.break_name} has been extended by</p>
          <p className={`font-weight-bold mt-0 pt-0 ${Style.extended_time}`}>{props.extendedTime} Mins</p>
          <div className={`mt-1 ${Style.timer_break_bar} px-2 py-1`}>
            Time left: {props.minutesLet}:{props.secondsLeft} Mins
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

ExtendBreakTimeSuccess.propTypes = {
  // To handle on close
  modalCloseHandler: PropTypes.func.isRequired,

  // Extended time
  extendedTime: PropTypes.number.isRequired,

  // Remaining time parameters
  minutesLet: PropTypes.string.isRequired,
  secondsLeft: PropTypes.string.isRequired,
}

export default ExtendBreakTimeSuccess
