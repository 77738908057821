import {
  SET_DRAGGED_ITEM,
  SET_MODULES_DATA,
  SET_PENDING_TOPICS,
  SET_RESOURCE_DATA,
  SET_TOPIC_DATA,
  SET_TOPIC_DATA_EMPTY,
  SET_TOPIC_DATA_ON_DROP,
} from "./LessonboardActions"

const LessonboardReducer = (state, action) => {
  switch (action.type) {
    case SET_MODULES_DATA:
      return { ...state, modules: action.payload }
    case SET_TOPIC_DATA:
      let data = { ...state }
      data.topics[action.payload.key] = action.payload.value
      return data
    case SET_TOPIC_DATA_ON_DROP:
      let stateData = { ...state }
      stateData.topics[action.payload.sourceKey] = action.payload.sourceValue
      stateData.topics[action.payload.destinationKey] = action.payload.destinationValue
      return stateData
    case SET_TOPIC_DATA_EMPTY:
      return { ...state, topics: {} }
    case SET_PENDING_TOPICS:
      return { ...state, pendingTopics: action.payload }
    case SET_RESOURCE_DATA:
      let res_data = { ...state }
      res_data.resources[action.payload.key] = action.payload.value
      return res_data
    case SET_DRAGGED_ITEM:
      return { ...state, draggedItem: action.payload }
    default:
      return state
  }
}

export default LessonboardReducer
