import React, { useEffect, useState, useCallback } from "react"
import _ from "lodash"

import Col from "react-bootstrap/Col"

import DeleteRowDropDown from "./_delete_row_dropdown"
import QuestionColumn from "./_question_column"
import AnswerColumn from "./_answer_column"

import StylesA from "./matching.module.scss"

function GetAllRows(props) {
  const matchingData = props.matchingData

  function getWidthOfColumn() {
    let noOfColumns = matchingData?.mtfcolumn_set?.length + 1
    if (noOfColumns > 3) {
      return `4`
    } else {
      let width = 12 / noOfColumns
      return `${width}`
    }
  }

  //This function is for scrolling the header div and all rows horizantally
  function scrollHeaderDiv(e, row_index = null) {
    let ele = document.getElementById("header_div")
    if (ele) {
      // For scrolling header
      ele.scrollLeft = e.target.scrollLeft
    }
    if (row_index >= 0) {
      for (let i = 0; i < matchingData?.rows?.length; i++) {
        // For scrolling all rows
        if (i !== row_index) {
          let ele = document.getElementById(`row_${i}`)
          if (ele) {
            ele.scrollLeft = e.target.scrollLeft
          }
        }
      }
    }
  }

  //This function is for auto increasing the height of the Answer cell
  function handleUpdateHeightOfRow(type, row_index, col_index, col_child_index) {
    let ele
    if (type === "question") {
      ele = document.getElementById(`question_${row_index}`)
    } else {
      ele = document.getElementById(`answer_${row_index}_${col_index}_${col_child_index}`)
    }
    if (ele) {
      setTimeout(() => {
        ele.style.cssText = "height:auto;"
        ele.style.cssText = "height:" + ele.scrollHeight + "px"
      }, 0)
    }
  }

  return (
    <Col lg="12" className="px-0 ">
      {matchingData?.rows?.map((single_row, row_index) => {
        return (
          <Col className="px-0 d-flex align-items-stretch w-100" key={`row_${single_row?.question_data?.id}`}>
            <div
              id={`row_${row_index}`}
              key={`row_${single_row?.question_data?.id}`}
              xs="12"
              onScroll={(e) => {
                e.preventDefault()
                if (!props.disableEventListner) {
                  if (matchingData?.mtf_type === "1") scrollHeaderDiv(e, row_index)
                }
              }}
              className={`d-flex align-items-stretch w-100 ${
                matchingData?.mtf_type === "1" ? `mb-2 mr-1 border_on_hover ${StylesA.singleRowStyle}` : `col-12`
              } px-0 `}
              style={{
                borderRadius: matchingData?.mtf_type === "1" ? "11px" : "",
              }}
            >
              <QuestionColumn
                type={props.type}
                single_row={single_row}
                mtf_type={matchingData?.mtf_type}
                matchingData={matchingData}
                row_index={row_index}
                handleUpdateHeightOfRow={handleUpdateHeightOfRow}
                getWidthOfColumn={getWidthOfColumn}
                handleUpdateQuestionData={props.handleUpdateQuestionData}
                setSavedContent={props.setSavedContent}
                showValidationErrors={props.showValidationErrors}
              />
              {single_row?.answers_list?.map((answers, col_index) => {
                return (
                  <AnswerColumn
                    type={props.type}
                    matchingData={matchingData}
                    single_row={single_row}
                    answers={answers}
                    key={`row_${row_index}_column_${col_index}_${matchingData?.mtfcolumn_set[col_index]?.id}`}
                    column_id={matchingData?.mtfcolumn_set[col_index]?.id}
                    col_index={col_index}
                    mtf_type={matchingData?.mtf_type}
                    row_index={row_index}
                    handleUpdateHeightOfRow={handleUpdateHeightOfRow}
                    getWidthOfColumn={getWidthOfColumn}
                    setShowRowDeleteModal={props.setShowRowDeleteModal}
                    handleDeleteRow={props.handleDeleteRow}
                    setActiveDeleteRowData={props.setActiveDeleteRowData}
                    handleAddMultiAnswer={props.handleAddMultiAnswer}
                    handleDeleteAnswerField={props.handleDeleteAnswerField}
                    handleUpdateAnswerCellData={props.handleUpdateAnswerCellData}
                    setSavedContent={props.setSavedContent}
                    showValidationErrors={props.showValidationErrors}
                  />
                )
              })}
            </div>
            {matchingData?.mtf_type === "1" && props.type !== "view_detail" && (
              <DeleteRowDropDown
                handleDeleteRow={props.handleDeleteRow}
                single_row={single_row}
                row_index={row_index}
                setShowRowDeleteModal={props.setShowRowDeleteModal}
                setActiveDeleteRowData={props.setActiveDeleteRowData}
              />
            )}
          </Col>
        )
      })}
    </Col>
  )
}

export default GetAllRows
