import React, { useState } from "react"
import Style from "./style.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"

// !definition of component
/**
 *
 * @param props --> type , name, defaultValue, required, label, icon, onChange, onDelete and onBlur function
 * @description -->  type=> input type, name=> for id and name, required=> boolean, icon => object {name: faIcon}
 * @returns Input Field component
 */
// ! component
const InputField = (props) => {
  const [error, setError] = useState(null)

  function handleChange(e) {
    setError(null)
    if (props.onChange) {
      props.onChange(e)
    }
  }
  return (
    <div style={props?.style} className={`${props?.className} ${Style.input_group} ${error && Style.red_border}`}>
      {props?.type !== "number" ? (
        <div className="p-2 w-100 d-flex">
          {props.icon && (
            <div className={`p-1`}>
              <FontAwesomeIcon
                className="h-100"
                style={{
                  color: "#838B9E",
                  fontSize: "18px",
                  width: "25px",
                }}
                icon={props?.icon?.name}
              />
            </div>
          )}
          <div className={`flex-grow-1 ${props?.icon ? "ml-2" : ""}`}>
            <input
              id={props?.name}
              name={props?.name}
              defaultValue={props.defaultValue ? props.defaultValue : ""}
              // value={props?.value ? props.value : ""}
              onChange={handleChange}
              type={props?.type}
              className={`${Style.inputText}`}
              onInvalid={(e) => {
                e.preventDefault()
                e.target.focus()
                setError("true")
              }}
              onBlur={props?.onBlur}
              required={props?.required}
              autoComplete="off"
            />
            <span style={props?.icon && { left: "auto" }} className={`${Style.floating_label} d-flex-align-center`}>
              {props.label}
              {!props.required && <span className={`ml-1`}>(Optional)</span>}
            </span>
          </div>
          {props?.onDelete && (
            <FontAwesomeIcon
              onClick={(e) => {
                props.onDelete()
              }}
              className="align-self-center pointer"
              icon={faTrash}
            />
          )}
        </div>
      ) : (
        <div className="d-flex align-items-center px-2 py-2">
          <div className={Style.label}>{props?.label}</div>
          <input
            type="number"
            id={props?.name}
            name={props?.name}
            placeholder={props.placeholder ? props.placeholder : ""}
            defaultValue={props.defaultValue ? props.defaultValue : ""}
            onChange={handleChange}
            className={`${Style.inputText}`}
            onInvalid={(e) => {
              e.preventDefault()
              e.target.focus()
              setError("true")
            }}
            onBlur={props?.onBlur}
            required={props?.required}
            min={props.min ? props.min : 0}
            max={props.max ? props.max : 999999}
            style={{ width: "40px", paddingTop: "0px" }}
            autoComplete="off"
          />
          <div>{props.children}</div>
        </div>
      )}
    </div>
  )
}

export default InputField
