import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import useTimer from "../../../hooks/_countdown_timer"
import useEventListener from "../../../hooks/_event_listener"

const TimerComponent = (props) => {
  const [resetTimerOnTabChange, setResetTimerOnTabChange] = useState(false)

  const changeOfVisibility = (event) => {
    // This function will on visible of same tab
    if (!document.hidden) {
      props.onTabChange(event)
      setResetTimerOnTabChange(true)
    }
  }
  useEventListener("visibilitychange", changeOfVisibility)

  const [remainingHours, remainingMinutes, remainingSeconds, timerEnded, timerReset, timerEndsWarning] = useTimer(
    props.startedTime,
    props.totalTimerDuration,
    props.resetTimer,
    resetTimerOnTabChange,
    props.timerType,
    props.showTimerEndWarning
  )
  useEffect(() => {
    props.onTimerAction(timerEnded)
  }, [timerEnded])

  useEffect(() => {
    if (timerReset) {
      setResetTimerOnTabChange(false)
      props.onResetTimer()
    }
  }, [timerReset])

  useEffect(() => {
    if (timerEndsWarning) {
      props.onShowTimerEndWarning(timerEndsWarning)
    }
  }, [timerEndsWarning])
  return remainingHours >= 0 && remainingMinutes >= 0 && remainingSeconds >= 0
    ? `${remainingHours > 0 ? remainingHours : ""}${remainingHours > 0 ? ":" : ""}${remainingMinutes}:${remainingSeconds}`
    : `00:00`
}

TimerComponent.defaultProps = {
  totalTimerDuration: 0,
  startedTime: new Date(),
  onTimerAction: (event) => {},
  timerType: "increment",
  resetTimer: false,
  onResetTimer: (event) => {},
  onTabChange: (event) => {},
  showTimerEndWarning: false,
  onShowTimerEndWarning: (event) => {},
}

TimerComponent.propTypes = {
  totalTimerDuration: PropTypes.number,
  startedTime: PropTypes.any,
  onTimerAction: PropTypes.func,
  timerType: PropTypes.string,
  resetTimer: PropTypes.bool,
  onResetTimer: PropTypes.func,
  onTabChange: PropTypes.func,
  showTimerEndWarning: PropTypes.bool,
}

export default TimerComponent
