import React, { useEffect, useState } from "react"

import Modal from "react-bootstrap/Modal"
import Loader from "../custom/Loader"
import Image from "../custom/Image"
import Btn from "../custom/Button"
import InputField from "../custom/InputField"
import Notification from "../custom/Notification"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"

import idea from "../../images/svgs/idea_buld.svg"
import closeCircle from "../../images/svgs/close.svg"

import Style from "./style.module.scss"

const SaveTemplateModal = (props) => {
  const [templateName, setTemplateName] = useState(props.newCreatedTemplate?.template_name || "")
  const [validationError, setValidationError] = useState("")
  const [savingTemplateLoader, setSavingTemplateLoader] = useState(false)
  const [templateNameEditedAgain, setTemplateNameEditedAgain] = useState(false)
  const [disableSaveButton, setDisableSaveButton] = useState(false)
  const [notificationData, setNotificationData] = useState(null)

  useEffect(() => {
    if (props.templateCreated) {
      setSavingTemplateLoader(false)
      setTemplateNameEditedAgain(false)
      // setDisableSaveButton(true)
      // setNotificationData({ type: "success", title: "Your template has been saved successfully" })
    }
  }, [props.templateCreated])

  const handleCloseModal = () => {
    props.setShow(false)
  }

  const handleTemplateNameChange = (name) => {
    //When template is saved once and template name is edited again enable save template button
    if (props.templateCreated && !templateNameEditedAgain) {
      setTemplateNameEditedAgain(true)
      setDisableSaveButton(false)
    }
    setTemplateName(name)
  }

  const validateTemplateName = () => {
    if (templateName) {
      setValidationError("")
      setSavingTemplateLoader(true)
      if (props.newCreatedTemplate) {
        props.handleEditDiscussionBoardTemplate(props.newCreatedTemplate.id, templateName)
      } else {
        props.handleCreateDiscussionBoardTemplate(templateName)
      }
    } else {
      setValidationError("Please provide template name")
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={handleCloseModal}
      size="md"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      dialogClassName={`${Style.live_discussion_modal} ${Style.left_5}`}
      contentClassName={`${Style.questions_modal}`}
    >
      <Modal.Header className={`px-2 pt-1 pb-0 ${Style.border_transparent}`}>
        <div className={`w-100 d-flex justify-content-between`}>
          <div className={`d-flex fs-16px align-items-center`}>
            <div>Save as a Template</div>
          </div>
          <div>
            <Image src={closeCircle} alt={"close"} onClick={handleCloseModal} />
          </div>
        </div>
      </Modal.Header>
      <hr className={`my-0 mx-auto ${Style.horizontal_line}`} />
      <Modal.Body>
        <div className={`d-flex fs-14px`}>
          <div className="mr-2">
            <Image src={idea} />
          </div>
          <div className="text-grey">
            Saving as a template enables you to use the layout any number of times.
            <br />
            You can view your saved discussions in <span className={`${Style.yellow_text}`}> resources -&gt; discussions</span>
          </div>
        </div>
        <div className={`row mt-2`}>
          <div className="col-12">
            <InputField
              onChange={(e) => {
                handleTemplateNameChange(e.target.value)
              }}
              style={{ backgroundColor: "#42506C" }}
              defaultValue={templateName}
              label="Name of the template"
              name="template_name"
              required
            />
          </div>
        </div>
        <div className={"d-flex align-items-center position-relative"}>
          {/*Save button is disabled if template name is empty or saving template is in progress.
          Once it is saved then it will stay disabled till the content is edited again.*/}
          <Btn
            className={"d-flex"}
            onClick={() => validateTemplateName()}
            type="small"
            disabled={disableSaveButton || templateName === "" || savingTemplateLoader}
          >
            {savingTemplateLoader && <Loader />}
            {props.newCreatedTemplate ? "Update template" : "Save as a template"}
          </Btn>
          <Notification style={{ position: "absolute", left: "145px", top: "-7px" }} data={notificationData} setData={setNotificationData} />
          {validationError && (
            <div className="d-inline-flex px-2 py-0" style={{ borderRadius: "23px" }}>
              <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "13px" }} />
              <p className="mb-0" style={{ fontSize: "14px", color: "#F4F411" }}>
                {validationError}
              </p>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SaveTemplateModal
