import React, { useCallback } from "react"
import _ from "lodash"

import Col from "react-bootstrap/Col"

import Btn from "../custom/Button"

import EditIcon from "../../images/svgs/edit.svg"

import StylesA from "./matching.module.scss"
import Styles from "../../modularscss/styles.module.scss"

function QuestionNameHeader(props) {
  const matchingData = props.matchingData

  const delayedQuery = useCallback(
    _.debounce((name, value, data) => sendQuery(name, value, data), 1000),
    []
  )

  function sendQuery(name, value, data) {
    props.handleUpdateMatchingData(name, value, data)
  }

  return (
    <Col
      id={`columnname_0`}
      key={`columnname_0`}
      xs={props.getWidthOfColumn()}
      className="d-inline-flex w-100 align-items-center py-1"
      style={{
        borderRight: "1px solid #FFFFFF50",
        border:
          props.type === "view_detail"
            ? "none"
            : (matchingData?.question_column_name?.length === 0 || matchingData?.question_column_name === null) && props.showValidationErrors
            ? "1px solid #ff7458"
            : "1px solid #FFFFFF50",
        borderWidth:
          (matchingData?.question_column_name?.length === 0 || matchingData?.question_column_name === null) && props.showValidationErrors
            ? "1px"
            : "0px 1px 0px 0px",
      }}
    >
      {props.editNameOfColumns[`column_name_0`] && props.type !== "view_detail" ? (
        <input
          id={`column_name_0`}
          name="question_column_name"
          maxLength={250}
          defaultValue={matchingData?.question_column_name}
          disabled={props.type === "view_detail"}
          onChange={(e) => {
            props.setSavedContent(false)
            delayedQuery(e.target.name, e.target.value, matchingData)
          }}
          onBlur={(e) => {
            e.preventDefault()
            e.stopPropagation()
            let dummy = { ...props.editNameOfColumns }
            dummy[`column_name_0`] = false
            props.setEditNameOfColumns(dummy)
            if (e.target.value !== matchingData?.question_column_name) {
              props.setSavedContent(false)
              props.handleUpdateMatchingData(e.target.name, e.target.value, matchingData)
            }
          }}
          type="text"
          className={`${StylesA.inputText} fs-16px mt-0`}
          style={{ background: "transparent", borderColor: "transparent", width: "100%" }}
          autoComplete="off"
          autoFocus
          required
        />
      ) : (
        <p
          className={`mb-0 ${Styles.ellipsis_1_effect}`}
          onClick={(e) => {
            props.handleShowInput(0)
          }}
          style={{ color: "white" }}
        >
          {matchingData?.question_column_name}
        </p>
      )}
      {!props.editNameOfColumns[`column_name_0`] && props.type !== "view_detail" && (
        <div className="pl-2">
          <Btn
            style={{ backgroundColor: "#42506C", border: "1px solid #FFFFFF27" }}
            onClick={(e) => {
              props.handleShowInput(0)
            }}
            type={`round`}
          >
            <img
              style={{
                width: "15px",
                height: "15px",
              }}
              alt="Edit column name"
              src={EditIcon}
              className="mb-0 mx-auto"
            />
          </Btn>
        </div>
      )}
    </Col>
  )
}

export default QuestionNameHeader
