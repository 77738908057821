import React from "react"
import { Dropdown } from "react-bootstrap"
import Image from "../custom/Image"
import arrow from "../../images/svgs/down_arrow_white.svg"

import StylesA from "../../components/match-the-following/matching.module.scss"
import StylesBS from "./bs-viewdetail.module.scss"
import getImageToBeRendered from "../utilities/resourceIcons"

const ResourceSelectionDropdown = (props) => {
  if (props.hasNonZeroLengthOfAttemptableResource && !props.attemptableResources.includes(props?.activeResource?.resource_type)) {
    props.setActiveResource(props.attemptableResourceList[0])
  }

  const selectedResourceImage = getImageToBeRendered(props.activeResource)

  return (
    <Dropdown className={`d-inline-flex align-items-center px-2 ${StylesBS.border_white} ${StylesBS.group_selection_dropdown} ${StylesBS.resource_select}`}>
      <Dropdown.Toggle
        id="resource-select-dropdown"
        size="lg"
        className={`d-inline-flex align-items-center caret-none w-100 px-0 ${StylesBS.dropdown_toggle} ${StylesBS.dropdown_bg}`}
      >
        <span className="mb-0 pr-3 fs-18px text-color-1">
          {props.hasNonZeroLengthOfAttemptableResource ? (
            <div className="d-flex align-items-center">
              <span>
                <img alt={`image_${selectedResourceImage.imgType}`} src={selectedResourceImage.imgObj} className={`mb-0`} height="25px" width="35px" />
              </span>{" "}
              <span className={`ellipsis ${StylesBS.maxw_180px}`}>{props?.activeResource?.resource_data?.name}</span>
            </div>
          ) : (
            "No attemptable resource"
          )}
        </span>
        <Image className={`ml-auto pointer icon}`} src={arrow} />
      </Dropdown.Toggle>
      <Dropdown.Menu className={`ml-lg-n2 ${StylesA.category_dropdown_menu} ${StylesBS.group_dropdown_menu} `} onScroll={props.handleActivityResourcesScroll}>
        {props.hasNonZeroLengthOfAttemptableResource && (
          <div className={`d-flex ${StylesBS.dropdown_menu} ${StylesBS.border_white}`}>
            <div className={`${StylesBS.group_item_div} w-100`}>
              {props.attemptableResourceList?.map((resource) => {
                const resourceImage = getImageToBeRendered(resource)
                return (
                  <Dropdown.Item
                    key={`resource_${resource.id}`}
                    className={`pl-2 py-1 pr-3 pointer ${StylesA.resource_option_hover} ${StylesBS.group_item_ele}`}
                    onClick={(e) => {
                      e.preventDefault()
                      resource?.resource_type !== props.activeResource?.resource_type && props.setActiveGroup((prev) => ({ ...prev, report_card: null }))
                      props.setActiveResource(resource)
                    }}
                  >
                    <div className="text-color-1 d-flex align-items-center">
                      <span>
                        <img alt={`image_${resourceImage.imgType}`} src={resourceImage.imgObj} className={`mb-0`} height="25px" width="35px" />
                      </span>{" "}
                      <span className="ellipsis">{resource?.resource_data?.name}</span>
                    </div>
                  </Dropdown.Item>
                )
              })}
            </div>
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}
export default ResourceSelectionDropdown
