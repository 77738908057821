import React, { useContext, useEffect, useState } from "react"
import _ from "lodash"

import utcDateTime from "../../services/utcdatetime"

import Style from "../class-room-training/lesson-board.module.scss"
import Styles from "./style.module.scss"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import Dropdown from "react-bootstrap/Dropdown"

import Modal from "react-bootstrap/Modal"
import Notification from "../custom/Notification"
import Btn from "../custom/Button"
import SubTopic from "./_sub-topic"
import InputField from "../custom/InputField"
import { getRequest, postRequest } from "../../services/rest_service"
import { TrainingSocketContext } from "../../stores/socket/training_socket/trainingSocketProvider"
import { ClassroomContext } from "../../stores/classroom_store/ClassroomStore"
import { ClassroomSidebarContext } from "../../stores/classroom_store/classroom_sidebar/classroomSidebarStore"
import { SET_ACTIVE_SECTION, SET_DISCUSSION_BOARD } from "../../stores/classroom_store/ClassroomActions"
import { SET_CURRENT_NAV_TAB } from "../../stores/classroom_store/classroom_sidebar/classroomSidebarActions"

const Timer = (props) => {
  let timer

  const [minLeft, setMinLeft] = useState("00")
  const [secLeft, setSecLeft] = useState("00")

  useEffect(() => {
    if (props.timeLeft > 0) {
      clearInterval(timer)
      startTimer(props.timeLeft)
    }
    return () => {
      clearInterval(timer)
    }
  }, [props.timeLeft])

  function startTimer(difference) {
    let x = difference
    timer = setInterval(() => {
      if (x > 0) {
        let min = Math.floor(x / 60)
        let sec = Math.floor(x % 60)
        setMinLeft(min < 10 ? "0" + min : min.toString())
        setSecLeft(sec < 10 ? "0" + sec : sec.toString())
        x--
      } else {
        //exit action
        props.actionOnEnd()
      }
    }, 1000)
    // Clear Timer
    setTimeout(() => {
      clearInterval(timer)
    }, (x + 10) * 1000)
  }

  return (
    <div className={`d-flex py-1 px-2 align-items-center`} style={{ backgroundColor: "#0D131F", borderRadius: "3px", width: "max-content", fontSize: "14px" }}>
      <div className={`mr-2`}>
        {minLeft}:{secLeft}
      </div>
      <div>mins remaining</div>
    </div>
  )
}

const LiveDiscussionBoard = (props) => {
  const [classroomState, dispatch] = useContext(ClassroomContext)
  const [classroomSidebarState, classroomSidebarDispatch] = useContext(ClassroomSidebarContext)
  const [ready, socketData, send] = useContext(TrainingSocketContext)

  const [discussionBoardDetails, setDiscussionBoardDetails] = useState(null)
  const [discussionBoardLoaded, setDiscussionBoardLoaded] = useState(false)

  // const [socketData, setSocketData] = useState(null)
  const [timeDifference, setTimeDifference] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const [notificationData, setNotificationData] = useState(null)
  const [positionedNotificationData, setPositionedNotificationData] = useState(null)
  const [extendTime, setExtendTime] = useState(10)
  const [extendTimeLoader, setExtendTimeLoader] = useState(false)
  const [allComments, setAllComments] = useState({})
  const [commentsLoaded, setCommentsLoaded] = useState(false)
  const [isShared, setIsShared] = useState(false)

  let boardId = classroomState.discussionBoard.active || props.boardId

  function timeCalculation() {
    let duration = parseInt(discussionBoardDetails?.total_duration)
    let start_time = utcDateTime.utcDateTime(discussionBoardDetails?.created_at)
    let end_time = new Date(start_time.getTime() + duration * 60000)
    let current_date = utcDateTime.utcDateTime("today")
    let time_difference = end_time.getTime() - current_date.getTime()
    if (time_difference < 0 && discussionBoardDetails?.session_status == "0") {
      stopDiscussion()
    }
    setTimeDifference(Math.round(time_difference / 1000))
  }

  useEffect(() => {
    if (discussionBoardDetails?.total_duration) {
      timeCalculation()
    }
    if (discussionBoardDetails?.id) {
      fetchAllComments(discussionBoardDetails.id)
    }
  }, [discussionBoardDetails])

  async function fetchAllComments(id) {
    let { success, data } = await getRequest(`/live-board/live-discussion-board-comments/?live_discussion_board=${id}`)
    if (success) {
      let topic_list = discussionBoardDetails?.ldbtopics_set
      let obj = {}
      topic_list.forEach((topic) => {
        obj[[`subtopic_${topic.id}`]] = []
      })
      let list = data
      list.forEach((comment) => {
        if (obj[`subtopic_${comment.topic}`]) {
          obj[`subtopic_${comment.topic}`].push(comment)
        }
      })
      setAllComments(obj)
    }
  }
  useEffect(() => {
    if (boardId !== null) {
      fetchDiscussionBoardDetail()
    }
  }, [boardId])

  useEffect(() => {
    if (socketData?.event_type === "live_discussion_board") {
      if (socketData !== null && socketData?.liveboard_id == discussionBoardDetails?.id) {
        if (socketData.action === "new_comment") {
          // let voteData = { likes: 0, dislikes: 0, vote_type: null, id: null }
          let commentsData = { ...allComments }
          commentsData[`subtopic_${socketData.payload.topic}`] = [socketData.payload, ...commentsData[`subtopic_${socketData?.payload?.topic}`]]
          setAllComments(commentsData)
        }
        if (socketData.action === "comment_vote_changed") {
          let commentsData = { ...allComments }
          let index = commentsData[`subtopic_${socketData?.payload?.topic_id}`].findIndex((res) => res.id === socketData?.payload?.comment_id)
          if (commentsData[`subtopic_${socketData?.payload?.topic_id}`][index]) {
            commentsData[`subtopic_${socketData?.payload?.topic_id}`][index].votes.likes = socketData?.payload?.vote_count
            setAllComments(commentsData)
          }
        }
        if (socketData?.action === "comment_deleted") {
          let commentsData = { ...allComments }
          let index = commentsData[`subtopic_${socketData.payload.topic}`].findIndex((res) => res.id === socketData.payload.id)
          if (commentsData[`subtopic_${socketData.payload.topic}`][index]) {
            commentsData[`subtopic_${socketData.payload.topic}`].splice(index, 1)
          }
          setAllComments(commentsData)
          // fetchVotesRemaining()
        }
        if (socketData?.action === "comment_updated") {
          let commentsData = { ...allComments }
          let index = commentsData[`subtopic_${socketData.payload.topic}`].findIndex((res) => res.id === socketData.payload.id)
          if (commentsData[`subtopic_${socketData.payload.topic}`][index]) {
            commentsData[`subtopic_${socketData.payload.topic}`][index] = socketData.payload
          }
          setAllComments(commentsData)
        } else if (socketData?.action === "stopped") {
          setDiscussionBoardDetails((prev) => ({ ...prev, session_status: 1 }))
        } else if (socketData?.action === "submitted") {
          endDiscussion()
        } else if (socketData?.action === "time_extended") {
          setExtendTime(10)
          setDiscussionBoardDetails((prev) => ({ ...prev, session_status: "0", total_duration: socketData.payload?.total_duration }))
          setNotificationData({ type: "success", title: "Time Updated Successfully" })
        }
      }
    }
  }, [socketData])

  async function fetchDiscussionBoardDetail() {
    let res = await getRequest(`/live-board/live-discussion-board/${boardId}/`)
    if (res.success) {
      setDiscussionBoardDetails(res.data)
      setDiscussionBoardLoaded(true)
    }
  }

  async function stopDiscussion() {
    // stop discussion when timer ends or click on End Session => backend status ENDED
    let res = await postRequest(`/live-board/live-discussion-board/${boardId}/stop_discussion/`)
    if (res.success) {
      // setIsSessionOver(true)
      setDiscussionBoardDetails(res.data)
    }
  }

  async function endDiscussion() {
    // stop, submit and exit from discussion board => backend status SUBMITTED
    let res = await postRequest(`/live-board/live-discussion-board/${boardId}/submit_discussion/`)
    if (res.success) {
      setDiscussionBoardDetails(res.data)
      dispatch({ type: SET_DISCUSSION_BOARD, payload: { ...classroomState.discussionBoard, active: null } })
      dispatch({ type: SET_ACTIVE_SECTION, payload: "LessonBoard" })
      classroomSidebarDispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
    }
  }

  function handleDuraionInputChange(e) {
    setExtendTime(e.target.value)
  }

  async function handleExtendTime(e) {
    e.preventDefault()
    if (extendTime > 0) {
      setExtendTimeLoader(true)
      let formData = new FormData()
      formData.append("extended_time", extendTime)
      let res = await postRequest(`/live-board/live-discussion-board/${discussionBoardDetails?.id}/extend_time/`, formData)
      if (res.success) {
        setExtendTime(10)
        setDiscussionBoardDetails(res.data)
        document.getElementById(`dropdown-timer-component`).click() //close timer dropdown
        setNotificationData({ type: "success", title: "Time Updated Successfully" })
      }
    } else {
      setNotificationData({ type: "error", title: "Invalid duration" })
    }
    setExtendTimeLoader(false)
  }

  async function shareToggleLiveDiscussionBoard(e) {
    e.preventDefault()
    if (isShared) {
      props.endShare(props.boardId)
      setIsShared(false)
    } else {
      const { data, status, success, msg } = await postRequest(`/live-board/live-discussion-board/${discussionBoardDetails?.id}/publish_ldb_to_student/`)
      if (success) {
        setIsShared(true)
        setPositionedNotificationData({
          type: "success",
          title: "The board has been shared successfully",
        })
      } else {
        setPositionedNotificationData({
          type: "error",
          title: msg,
        })
      }
    }
  }

  function instructionModal() {
    return (
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false)
        }}
        size="md"
        centered
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName={`${Style.live_discussion_modal}`}
        contentClassName={`${Style.questions_modal}`}
      >
        <Modal.Header className={`p-2`} style={{ borderBottom: "1px solid #929BBD" }}>
          <div className={`w-100 d-flex justify-content-between`}>
            <div className={`d-flex fs-16px align-items-center`}>
              <div>Instructions</div>
            </div>
            <div>
              <FontAwesomeIcon
                className={`pointer`}
                onClick={() => {
                  setShowModal(false)
                }}
                icon={faTimesCircle}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body
          className={`p-0`}
          onScroll={(e) => {
            e.stopPropagation()
          }}
        >
          <div className={`p-2 w-100`}>{discussionBoardDetails?.instructions}</div>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <div
      className={`w-100 h-100 ${Style.cs_ques_box} ${discussionBoardDetails?.session_status != 2 ? Style.border_1 : ""} d-flex flex-column`}
      style={{
        backgroundColor: "#19253B",
        color: "#E0E2E5",
        boxShadow: "0px 3px 12px #00000099",
        maxHeight: "83vh",
        overflowY: "auto",
      }}
    >
      {instructionModal()}
      <div className={`px-3 py-1 ${Style.border_bottom}`}>
        {timeDifference !== null && (
          <React.Fragment>
            <div className={`d-flex align-items-center`}>
              <div style={{ fontSize: "15px" }} className={`font-weight-bold`}>
                {discussionBoardDetails?.name}
              </div>
              {discussionBoardDetails.session_status != 2 && <FontAwesomeIcon className={`pointer ml-auto`} onClick={endDiscussion} icon={faTimesCircle} />}
            </div>
            <div className={`d-flex`}>
              {discussionBoardDetails.session_status == "0" ? (
                <Timer actionOnEnd={stopDiscussion} timeLeft={timeDifference} />
              ) : (
                <div className="fs-14px text-grey">Session Ended</div>
              )}
              {discussionBoardDetails?.instructions && (
                <div
                  className="ml-auto link"
                  onClick={(e) => {
                    setShowModal(true)
                  }}
                >
                  Instructions
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
      <div className={`d-flex my-3 h-100`} style={{ overflow: "scroll" }}>
        {discussionBoardDetails?.ldbtopics_set?.map((topic, index) => {
          return (
            <SubTopic
              key={`sub_topic_${index}`}
              topics_length={discussionBoardDetails?.ldbtopics_set?.length}
              subTopicData={topic}
              commentsData={allComments}
            />
          )
        })}
      </div>
      {(discussionBoardDetails?.session_status == "0" || discussionBoardDetails?.session_status == "2") && (
        <div className={`d-flex ${Styles.footer} px-3 py-2`}>
          <Notification
            data={positionedNotificationData}
            setData={setPositionedNotificationData}
            style={{ position: "absolute", left: "8px", top: "-50px", maxWidth: "fit-content" }}
          />
          {discussionBoardDetails?.session_status == "2" && (
            <Btn onClick={shareToggleLiveDiscussionBoard} className={`mr-2`}>
              {isShared ? "End Share" : "Share Board"}
            </Btn>
          )}

          {discussionBoardDetails?.session_status == "0" && (
            <>
              <Btn
                onClick={(e) => {
                  e.preventDefault()
                  stopDiscussion()
                }}
                className={`mr-2`}
              >
                End Session
              </Btn>
              <Dropdown className={"session-time-drop-down px-0"}>
                <Dropdown.Toggle as="div" id="dropdown-timer-component">
                  <Btn>Extend Time</Btn>
                </Dropdown.Toggle>
                <Dropdown.Menu className={`${Styles.timer_dropdown}`} style={{ width: "max-content" }}>
                  <div className={`my-2 py-2 d-flex flex-column justify-content-between`}>
                    <div className={`d-flex align-items-center`} style={{ borderBottom: "1px solid #42506D" }}>
                      <div className={`font-weight-bold px-2 text-grey fs-12px`}>Extend Time By :</div>
                      <div className={`d-flex align-items-center`}>
                        <InputField type="number" onChange={handleDuraionInputChange} defaultValue={extendTime} label="" name="timer" />
                        <div className={`ml-1 text-grey fs-12px`}>Mins</div>
                      </div>
                    </div>

                    <div className={`px-2 pt-2`}>
                      <Btn disabled={extendTimeLoader} onClick={handleExtendTime}>
                        Done
                      </Btn>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </div>
      )}

      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default LiveDiscussionBoard
