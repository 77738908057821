import React from "react"

import PropTypes from "prop-types"
import { Modal } from "react-bootstrap"
import Image from "../../custom/Image"

import Style from "./session-break.module.scss"
import SuccessBreak from "../../../images/svgs/classroom/success_break.svg"

const BreakEndedMessage = (props) => {
  return (
    <Modal
      show={true}
      onHide={props.modalCloseHandler}
      centered={true}
      size={"md"}
      className={`${Style.instant_modal} text-light`}
      contentClassName={`${Style.extend_modal_content}`}
    >
      <Modal.Body className={``}>
        <div className="d-flex flex-column align-items-center pt-4 pb-4">
          <Image src={SuccessBreak} alt={"success"} className={`m-1 ${Style.success_break}`} />
          <p className={`fs-16px`}>{props.breakName} has ended!</p>
        </div>
      </Modal.Body>
    </Modal>
  )
}

BreakEndedMessage.propTypes = {
  // To handle on close
  modalCloseHandler: PropTypes.func.isRequired,

  // Breakname
  breakName: PropTypes.string.isRequired,
}

export default BreakEndedMessage
