import React, { useState, useCallback, useEffect, useContext } from "react"
import _ from "lodash"
import Styles from "../../../../modularscss/styles.module.scss"
import Style from "../../../class-room-training/lesson-board.module.scss"
import StylesA from "../../../template-resources/breakout-session.module.scss"

import { ReportBox } from "../active_breakout_session"
import Btn from "../../../custom/Button"
import CheckIcon from "../../../../images/svgs/check-white.svg"
import Image from "../../../custom/Image"
import Loader from "../../../custom/Loader"
import PlayBtn from "../../../../images/svgs/classroom/play.svg"
import ArrowDropupCircle from "../../../../images/svgs/arrow-dropup-white-circle.svg"
import ArrowDropdownCircle from "../../../../images/svgs/arrow-dropdown-white-circle.svg"
import ExclaimationTriangleYellow from "../../../../images/svgs/exclamation-triangle-yellow.svg"

import SeeMore from "../../../custom/see_more"

import { putRequest, getRequest } from "../../../../services/rest_service"
import { convertToTime } from "../active_breakout_session"
import { BreakoutGroupContext } from "../../../../stores/socket/breakout_group_socket/breakoutGroupProvider"

const QuizReportCardView = (props) => {
  const [quizGroupDataFetched, setQuizGroupDataFetched] = useState({})
  const [quizFetchLimit, setQuizFetchLimit] = useState(8)
  const [quizGroupClassAverage, setQuizGroupClassAverage] = useState({})
  const [quizGroupData, setQuizGroupData] = useState({})
  const [quizGroupDataExpandData, setQuizGroupDataExpandData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [publishMsg, setPublishMsg] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [breakoutWsReady, breakoutWsVal, breakoutWsSend] = useContext(BreakoutGroupContext)

  useEffect(() => {
    if (
      breakoutWsVal?.action === "report_card_updated" &&
      breakoutWsVal?.source === "quiz" &&
      breakoutWsVal.resource === props.activeResource.id &&
      props.activeGroup?.group_id === breakoutWsVal.group_id
    ) {
      props.activeGroup?.report_card && fetchQuizGroupReport(props.activeGroup?.report_card, props.activeResource.id, false)
    }
  }, [breakoutWsVal])

  useEffect(() => {
    props.activeGroup?.report_card && fetchQuizGroupReport(props.activeGroup?.report_card, props.activeResource.id)
  }, [props.activeGroup?.report_card])

  const fetchQuizGroupReport = async (cs_report_card_id, res_id, showLoader = true) => {
    showLoader && setIsLoading(true)
    let fetchData = { ...quizGroupDataFetched }
    if (fetchData[`quiz_${cs_report_card_id}`] === undefined) {
      fetchData[`quiz_${cs_report_card_id}`] = false
    }
    let response = await getRequest(`/quiz-group-report-card/${cs_report_card_id}/report_card_data/?limit=${quizFetchLimit}`)
    if (response.success) {
      let data = { ...quizGroupData }
      let expandData = { ...quizGroupDataExpandData }
      let classAverage = { ...quizGroupClassAverage }
      classAverage[`res_${res_id}`] = response.data.class_average
      if (!fetchData[`quiz_${cs_report_card_id}`]) {
        expandData[`quiz_${cs_report_card_id}`] = Array(response.data.count).fill(true)
        fetchData[`quiz_${cs_report_card_id}`] = true
      }
      data[`quiz_${cs_report_card_id}`] = response.data
      setQuizGroupClassAverage(classAverage)
      setQuizGroupData(data)
      setQuizGroupDataExpandData(expandData)
      setQuizGroupDataFetched(fetchData)
      showLoader && setIsLoading(false)
    }
  }

  const publishQuizReport = async (cs_report_card_id) => {
    let response = await getRequest(`/quiz-group-report-card/${cs_report_card_id}/submit_group_report/?publish=true`)
    if (response.success) {
      let data = { ...quizGroupData }
      data[`quiz_${cs_report_card_id}`].report_track = {
        ...data[`quiz_${cs_report_card_id}`].report_track,
        published: response.data.published,
        current_status: response.data.current_status,
      }
      setQuizGroupData(data)
      setPublishMsg(true)
    }
  }

  function showQuestionsQuiz() {
    let alphabets = "ABCDEFGHIJKLMNOPQRSTUWXYZ"
    let csReport = quizGroupData["quiz_" + props.activeGroup?.report_card]?.report_track
    if (
      quizGroupData &&
      quizGroupData["quiz_" + props.activeGroup?.report_card] &&
      quizGroupData["quiz_" + props.activeGroup?.report_card].results.length > 0
    ) {
      return (
        <>
          {quizGroupData["quiz_" + props.activeGroup?.report_card].results.map((data, index) => {
            let expand = false
            if (quizGroupDataExpandData[`quiz_${props.activeGroup?.report_card}`]) {
              expand = quizGroupDataExpandData[`quiz_${props.activeGroup?.report_card}`][index]
            }
            if (data.question_type === "2") {
              return (
                <div key={`csquestion${index}`} className={`d-flex mb-3`}>
                  <div className={`font-weight-bold text-center fs-32px w-3rem text-9BA1C4`}>{index + 1}</div>
                  <div className={`inner-element-box-shadow w-100 ml-2 fs-16px ${Style.cs_ques_box}`}>
                    <div className={`d-flex justify-content-between m-3`}>
                      <div className={`w-100 d-flex flex-column mr-3`}>
                        <div className={`p-2 br-9px ${expand ? "" : `${Style.cs_ques_box}`} fs-18px`}>{data?.title}</div>
                        {expand ? (
                          <React.Fragment>
                            {data?.subjective_answers?.length > 0 ? (
                              <div className={`my-1`}>
                                {data?.subjective_answers?.map((ans, index) => {
                                  return (
                                    <div key={`cs_subjective_answer_${index}`} className={`w-100 p-2 mb-2 bg-42506C ${Style.cs_ques_box}`}>
                                      <p
                                        className={`mb-0 ${Style.richtext_bg}`}
                                        dangerouslySetInnerHTML={{
                                          __html: ans?.answer,
                                        }}
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                            ) : (
                              <div className={`mt-1`}>
                                <div className="opacity-69">No Answers yet</div>
                              </div>
                            )}
                          </React.Fragment>
                        ) : null}
                      </div>
                      <div className={`${expand ? "pt-2" : "align-self-center"}`}>
                        <Image
                          src={expand ? ArrowDropupCircle : ArrowDropdownCircle}
                          className="w-20px pointer"
                          onClick={(e) => {
                            expandQuizToggle(props.activeGroup?.report_card, index)
                          }}
                          alt={expand ? "up-arrow" : "down-arrow"}
                        />
                      </div>
                    </div>
                    {data?.answer_map !== null && csReport?.grading && (
                      <React.Fragment>
                        <div className={`d-flex align-items-center px-2 py-3 border-top-6C7382`}>
                          <div>Score : </div>
                          <div className={`mx-2`}>
                            <input
                              type="number"
                              min="0"
                              max={data?.answer_map?.total_marks}
                              value={data?.answer_map?.marks_gained ?? ""}
                              onChange={(e) => {
                                updateGradeGroupActivityQuiz(e, data?.answer_map, data?.id, index)
                              }}
                              className={`${StylesA.session_input_field} mx-auto form-control shadow-none text-center px-2 w-100 fs-18px bold-500 ${StylesA.border_on_hover}`}
                            />
                          </div>
                          <div>Out of {data?.answer_map?.total_marks} marks </div>
                        </div>
                        {errorMsg !== null && errorMsg?.id === data?.answer_map?.id && (
                          <div className={`mb-2 px-0 `}>
                            <div className="d-inline-flex px-2 py-0 br-23px">
                              <Image src={ExclaimationTriangleYellow} className="mt-1 mr-1 h-13px" alt="exclaimation-icon" />
                              <p className="mb-0 fs-14px color-F4F411">{errorMsg?.msg}</p>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )
            } else if (data.question_type === "1") {
              return (
                <div key={`csquestion${index}`} className={`d-flex mb-3`}>
                  <div className={`font-weight-bold text-center fs-32px w-3rem text-9BA1C4`}>{index + 1}</div>
                  <div className={`inner-element-box-shadow w-100 ml-2 fs-16px ${Style.cs_ques_box}`}>
                    <div className={`d-flex justify-content-between m-3`}>
                      <div className={`w-100 d-flex flex-column mr-3`}>
                        <div className={`p-2 ${Style.cs_ques_box} fs-18px`}>{data?.title}</div>
                        {expand ? (
                          <React.Fragment>
                            {data?.options.map((ans, i) => (
                              <div key={`csans${i}`} className={`d-flex align-items-center my-1`}>
                                <div className={`ml-2 `}>
                                  <Image src={PlayBtn} alt="play-btn" className={`w-14px ${ans.is_selected ? "opacity-1" : "opacity-0"}`} />
                                </div>
                                <div className={`font-weight-bold mx-2 fs-20px text-9BA1C4`}>{alphabets[i]}</div>
                                <div className={`flex-grow-1 p-2 ${Style.cs_ques_box} ${ans.is_correct ? "bg-197C7C" : "bg-42506C"}`}>{ans?.name}</div>
                              </div>
                            ))}
                          </React.Fragment>
                        ) : null}
                      </div>
                      <div className={`${expand ? "pt-2" : "align-self-center"}`}>
                        <Image
                          src={expand ? ArrowDropupCircle : ArrowDropdownCircle}
                          className="w-20px pointer"
                          onClick={(e) => {
                            expandQuizToggle(props.activeGroup?.report_card, index)
                          }}
                          alt={expand ? "up-arrow" : "down-arrow"}
                        />
                      </div>
                    </div>
                    {expand && data?.answer_map !== null && csReport?.grading ? (
                      <div className={`d-flex align-items-center font-weight-bold px-2 py-3 mt-3 border-top-6C7382`}>
                        <div>Score : </div>
                        <div className={`mx-1`}>
                          {" "}
                          {data?.answer_map?.marks_gained}/{data?.answer_map?.total_marks} marks
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              )
            } else {
              return null
            }
          })}
          {quizGroupData["quiz_" + props.activeGroup?.report_card].next !== null && (
            <div className="ml-5 mb-3">
              <SeeMore url={quizGroupData["quiz_" + props.activeGroup?.report_card].next} handleData={handleSeeMoreQuiz} />
            </div>
          )}
        </>
      )
    } else {
      return null
    }
  }

  function expandQuizToggle(id, index) {
    let data = { ...quizGroupDataExpandData }
    data[`quiz_${id}`][index] = !data[`quiz_${id}`][index]
    setQuizGroupDataExpandData(data)
  }

  const updateGradeGroupActivityQuiz = async (e, answer_map, question, index) => {
    let marks = e.target.value
    if (marks > answer_map?.total_marks || marks < 0) {
      setErrorMsg({
        msg: `Please enter marks between 0 and ${answer_map?.total_marks}`,
        id: answer_map?.id,
      })
    } else {
      setErrorMsg(null)

      let answerMap = { ...[...quizGroupData[`quiz_${props.activeGroup?.report_card}`].results][index]?.answer_map, marks_gained: marks }
      let questionCopy = { ...[...quizGroupData[`quiz_${props.activeGroup?.report_card}`].results][index], answer_map: answerMap }
      let resultsCopy = [...quizGroupData[`quiz_${props.activeGroup?.report_card}`].results]
      resultsCopy[index] = questionCopy
      let quizGroupDataCopy = {
        ...quizGroupData,
        [`quiz_${props.activeGroup?.report_card}`]: { ...quizGroupData[`quiz_${props.activeGroup?.report_card}`], results: resultsCopy },
      }
      setQuizGroupData(quizGroupDataCopy)

      if (marks !== "") {
        delayedUpdateScore(marks, question, answer_map)
      }
    }
  }

  const handleSeeMoreQuiz = (response) => {
    let data = { ...quizGroupData }
    data["quiz_" + props.activeGroup?.report_card].results = [...data["quiz_" + props.activeGroup?.report_card].results, ...response.data.results]
    data["quiz_" + props.activeGroup?.report_card].next = response.data.next
    setQuizGroupData(data)
    setQuizFetchLimit(quizGroupData["quiz_" + props.activeGroup?.report_card].results.length)
  }

  const autoSaveQuizRemark = useCallback(
    _.debounce(
      (value, resource, session, report_track, activeCaseStudyGroupData, group_name) =>
        handleQuizRemarkUpdate(value, resource, session, report_track, activeCaseStudyGroupData, group_name),
      1000
    ),
    [quizGroupData]
  )

  const delayedUpdateScore = useCallback(
    _.debounce((marks, question, answer_map) => updateScore(marks, question, answer_map), 1000),
    []
  )

  const updateScore = async (marks, question, answer_map) => {
    let formData = new FormData()
    formData.append("marks_gained", marks)
    formData.append("question", question)
    formData.append("group_report_card", props.activeGroup?.report_card)
    await putRequest(`/quiz-group-answer-submit/${answer_map?.id}/`, formData)
  }

  function handleQuizRemarkUpdate(value, resource_id, session, report_track, cs_report_card_id, group_name) {
    let formData = new FormData()
    let resource = resource_id
    let breakout_session = session

    formData.append("remark", value)
    formData.append("resource", resource)
    formData.append("breakout_session", breakout_session)
    formData.append("group_id", report_track.group_id)
    formData.append("group_name", group_name)
    formData.append("quiz_version", report_track.quiz_version)
    updateQuizGroupReportCard(formData, cs_report_card_id)
  }
  const updateQuizGroupReportCard = async (formData, cs_report_card_id) => {
    let response = await putRequest(`/quiz-group-report-card/${cs_report_card_id}/`, formData)
    if (response.success) {
      let data = { ...quizGroupData }
      data["quiz_" + cs_report_card_id].report_track.remark = response?.data?.remark
      setQuizGroupData(data)
    }
  }

  function showQuizReports() {
    let data = quizGroupData["quiz_" + props.activeGroup?.report_card]
    let results = "Ongoing "
    let status = data?.report_track?.current_status
    let avg = quizGroupClassAverage[`res_${props.activeResource?.id}`]
    if (status === "2") {
      let perc = (data?.report_track?.score / data?.report_track?.total_score) * 100
      if (perc >= data?.report_track?.passing_marks) {
        results = "Passed"
      } else {
        results = "Fail"
      }
    }
    return (
      <div className={`d-flex mb-4 justify-content-between`}>
        <ReportBox header={"Total Attempts"} footer={avg !== null ? `Class Avg. : ${avg?.avg_attempts}` : null}>
          <div className={`font-weight-bolder flex-fill py-4`}>{data?.report_track?.total_attempts}</div>
        </ReportBox>
        <ReportBox header={"Results"} footer={avg !== null ? `Class Success Rate : ${avg?.is_class_pass_percentage}%` : null}>
          <div className={`font-weight-bolder flex-fill py-4 ${results === "Fail" && "text-danger"}`}>{results}</div>
        </ReportBox>
        <ReportBox
          header={`${status === "2" ? "Final" : "Current"} Score`}
          footer={avg !== null ? `Class Avg. : ${avg?.avg_marks} (${avg?.avg_marks_percent}%)` : null}
        >
          <div className={`font-weight-bolder flex-fill py-4`}>
            {data?.report_track?.score}/{data?.report_track?.total_score}
          </div>
        </ReportBox>
        <ReportBox header={"Time Taken"} footer={avg !== null ? `Class Avg : ${convertToTime(avg?.time_taken)}` : null}>
          <div className={`font-weight-bolder flex-fill py-4`}>{convertToTime(data?.report_track?.time_taken)}</div>
        </ReportBox>
      </div>
    )
  }

  if (!isLoading) {
    return (
      <div className={`d-flex flex-column text-color-1 pr-3 py-3`}>
        {props.activeGroup?.report_card && quizGroupData && quizGroupData["quiz_" + props.activeGroup?.report_card]?.report_track?.grading
          ? showQuizReports()
          : null}
        {showQuestionsQuiz()}
        {props.activeGroup?.report_card && quizGroupData["quiz_" + props.activeGroup?.report_card]?.report_track?.current_status === "2" ? (
          <div>
            <div className={`${Style.remarks} ${Style.cs_ques_box} p-2`}>
              <div className={`fs-14px font-weight-bold`}>Add Remarks</div>
              <textarea
                defaultValue={quizGroupData["quiz_" + props.activeGroup?.report_card]?.report_track?.remark}
                className={`${Styles.font_16} ${Style.text_area} p-2 border-none`}
                onChange={(e) => {
                  autoSaveQuizRemark(
                    e.target.value,
                    props.activeResource?.id,
                    props.activeBreakoutActivity.breakout_session,
                    quizGroupData["quiz_" + props.activeGroup?.report_card]?.report_track,
                    props.activeGroup?.report_card,
                    props.activeGroup?.title
                  )
                }}
              />
            </div>
            <div className={`${Style.remarks} mt-2 d-inline-flex`}>
              {props.activeGroup?.report_card && quizGroupData["quiz_" + props.activeGroup?.report_card]?.report_track?.published ? (
                <Btn
                  onClick={() => {
                    props.setShowViewDetailsModal(false)
                  }}
                  className={`${StylesA.add_button_large} ${StylesA.border_on_hover}`}
                  variant="none"
                >
                  Done
                </Btn>
              ) : (
                <Btn
                  onClick={() => {
                    publishQuizReport(props.activeGroup?.report_card)
                  }}
                  className={`${StylesA.add_button_large} ${StylesA.border_on_hover}`}
                  variant="none"
                >
                  Publish
                </Btn>
              )}
              {publishMsg && (
                <div className={`mb-0 ml-3 fs-12px align-self-center`}>
                  <img src={CheckIcon} alt={`white check`} className={`my-auto mr-1 pointer object-fit-contain w-13px`} />
                  All changes are saved
                </div>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    )
  } else {
    return (
      <div className="text-center my-5">
        <Loader class="text-white" />
      </div>
    )
  }
}

export default QuizReportCardView
