import React, { useEffect, useState, useCallback } from "react"
import _ from "lodash"

import Dropdown from "react-bootstrap/Dropdown"
import LiveDiscussionStart from "./live-discussion-start"
import Notification from "../custom/Notification"
import Loader from "../custom/Loader"
import { getRequest } from "../../services/rest_service"

import Style from "./style.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleDown, faSearch } from "@fortawesome/free-solid-svg-icons"

const SelectTemplate = (props) => {
  const [list, setList] = useState([])
  const [currentTemplate, setCurrentTemplate] = useState(null)
  const [templateFetchLoader, settemplateFetchLoader] = useState(false)
  const [templatedFetched, setTemplatedFetched] = useState(false)
  const [toastData, setToastData] = useState(null)
  const [searchLoader, setSearchLoader] = useState(false)

  async function fetchTemplates(search_word = "") {
    if (!templatedFetched) {
      settemplateFetchLoader(true)
      setTemplatedFetched(false)
      let search_param = search_word ? `?search=${search_word}` : ""
      let res = await getRequest(`/live-board/live-discussion-board-template/${search_param}`)
      if (res.success) {
        setList(res.data)
        setTemplatedFetched(true)
        settemplateFetchLoader(false)
        setSearchLoader(false)
      } else {
        setToastData({ type: "error", title: "Something went wrong" })
      }
    }
  }

  const handleTemplateSelect = (data) => {
    setCurrentTemplate(data)
    document.getElementById("dropdown-template-list").click()
  }

  const delayedQuery = useCallback(
    _.debounce((value) => {
      fetchTemplates(value)
    }, 1000),
    []
  )

  const showTemplates = () => {
    if (templatedFetched) {
      if (list.length > 0) {
        return list.map((template, index) => {
          return (
            <div
              key={`ldb_template_${index}`}
              onClick={() => {
                handleTemplateSelect(template)
              }}
              className={`d-flex flex-column ${Style.dropdown_list}`}
            >
              <div className={`${Style.template_title}`}>{template.template_name}</div>
              {/*<div className={`${Style.template_info}`}>Used once in last 30 days</div>*/}
            </div>
          )
        })
      } else {
        return (
          <p className="my-2" style={{ fontSize: "14px", textAlign: "center" }}>
            There are no saved templates!
          </p>
        )
      }
    } else {
      return (
        <div className="text-center my-2">
          {" "}
          <Loader />{" "}
        </div>
      )
    }
  }

  return (
    <div className={`mx-3 my-2 ${Style.wrapper} ${Style.dropdown_toggle}`}>
      <Dropdown className={"session-time-drop-down px-0"}>
        <Dropdown.Toggle as="div" id="dropdown-template-list">
          <div className={`py-2 px-3 align-items-center d-flex justify-content-between`} onClick={() => fetchTemplates()}>
            <div>Select an existing discussion template</div>
            <FontAwesomeIcon icon={faChevronCircleDown} />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className={`${Style.active_input_drop_down} m-0 p-0`}>
          <div className={`${Style.search_dropdown_list} px-3 py-1`}>
            <div>
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <input
              onChange={(e) => {
                delayedQuery(e.target.value)
                setSearchLoader(true)
              }}
              type="text"
              className={`${Style.search_field}`}
              placeholder={`Search template`}
            />
            {searchLoader ? <Loader /> : null}
          </div>
          <div style={{ maxHeight: "300px", overflowY: "auto", borderRadius: "8px" }}>{showTemplates()}</div>
        </Dropdown.Menu>
      </Dropdown>
      {currentTemplate && (
        <div>
          <LiveDiscussionStart isTemplate={true} template={currentTemplate} />
        </div>
      )}
      <Notification data={toastData} setData={setToastData} />
    </div>
  )
}

export default SelectTemplate
