import React, { useState, useCallback, useEffect } from "react"
import _ from "lodash"

import Col from "react-bootstrap/Col"
import Lottie from "react-lottie"

import Btn from "../custom/Button"

import ScrollRightAnimation from "../../../src/lotties/scrollRight.json"
import ScrollLeftAnimation from "../../../src/lotties/scrollLeft.json"

import AllRows from "./_all_rows"
import QuestionNameHeader from "./_question_name_header"
import ColumnNamesHeader from "./_column_names_header"

import StylesA from "./matching.module.scss"

function RowColumnTableData(props) {
  const matchingData = props.matchingData

  const [editNameOfColumns, setEditNameOfColumns] = useState({})

  const [hoveredResourcesSection, setHoveredResourcesSection] = useState(false)
  const [showScrollRight, setShowScrollRight] = useState(false)
  const [showScrollLeft, setShowScrollLeft] = useState(false)

  const [disableEventListner, setDisableEventListner] = useState(false)

  const scrollLeftOptions = {
    autoplay: true,
    loop: true,
    animationData: ScrollLeftAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const scrollRightOptions = {
    autoplay: true,
    loop: true,
    animationData: ScrollRightAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const delayedQuery = useCallback(
    _.debounce((name, value, data, id, index) => sendQuery(name, value, data, id, index), 1000),
    []
  )

  function sendQuery(name, value, data, id, index) {
    if (name === "column") {
      props.handleUpdateColumnData(name, value, data, id, index)
    } else {
      props.handleUpdateMatchingData(name, value, data)
    }
  }

  function getWidthOfColumn() {
    let noOfColumns = matchingData?.mtfcolumn_set?.length + 1
    if (noOfColumns > 3) {
      return `4`
    } else {
      let width = 12 / noOfColumns
      return `${width}`
    }
  }

  //This function is for scrolling the header div and all rows horizantally
  function scrollHeaderDiv(e, row_index = null) {
    let ele = document.getElementById("header_div")
    if (ele) {
      // For scrolling header
      ele.scrollLeft = e.target.scrollLeft
    }
    if (row_index) {
      for (let i = 0; i < matchingData?.rows?.length; i++) {
        // For scrolling all rows
        if (i !== row_index) {
          let ele = document.getElementById(`row_${i}`)
          if (ele) {
            ele.scrollLeft = e.target.scrollLeft
          }
        }
      }
    }
  }

  function scrollBottomDiv(e) {
    let ele = document.getElementById("bottom_div")
    if (ele) {
      ele.scrollLeft = e.target.scrollLeft
    }
  }

  function handleShowInput(col_index) {
    let dummy = { ...editNameOfColumns }
    dummy[`column_name_${col_index}`] = true
    setEditNameOfColumns(dummy)
  }

  function displayHeader() {
    return (
      <Col
        sm="12"
        onScroll={(e) => {
          e.stopPropagation()
        }}
        id="header_div"
        key={`header_div`}
        className={`d-inline-flex align-items-center ${props.type === "view_detail" ? `mb-0` : `mb-2`} px-0`}
        style={{
          backgroundColor: props.type === "view_detail" ? "transparent" : "#42506C",
          boxShadow: props.type === "view_detail" ? "none" : " 0px 3px 6px #00000029",
          borderRadius: "5px",
          overflowX: "hidden",
          width: matchingData?.mtf_type === "1" ? "98%" : "100%",
        }}
      >
        <QuestionNameHeader
          type={props.type}
          getWidthOfColumn={getWidthOfColumn}
          matchingData={matchingData}
          showValidationErrors={props.showValidationErrors}
          editNameOfColumns={editNameOfColumns}
          setEditNameOfColumns={setEditNameOfColumns}
          setSavedContent={props.setSavedContent}
          handleUpdateMatchingData={props.handleUpdateMatchingData}
          handleShowInput={handleShowInput}
        />
        {matchingData?.mtfcolumn_set?.map((column, col_index) => {
          return (
            <ColumnNamesHeader
              type={props.type}
              key={`columnname_${col_index + 1}_${column?.id}`}
              getWidthOfColumn={getWidthOfColumn}
              matchingData={matchingData}
              showValidationErrors={props.showValidationErrors}
              editNameOfColumns={editNameOfColumns}
              setEditNameOfColumns={setEditNameOfColumns}
              setSavedContent={props.setSavedContent}
              handleUpdateColumnData={props.handleUpdateColumnData}
              handleShowInput={handleShowInput}
              handleAddCol={props.handleAddCol}
              setShowDeleteModal={props.setShowDeleteModal}
              handleDeleteColumn={props.handleDeleteColumn}
              setActiveDeleteColumnData={props.setActiveDeleteColumnData}
              col_index={col_index}
              column={column}
            />
          )
        })}
      </Col>
    )
  }

  function scrollRight(id) {
    let ele2 = document.getElementById("header_div")
    let ele1 = document.getElementById("bottom_div")

    if (ele1) {
      ele1.scrollTo({ top: 0, left: ele1.scrollLeft + 400, behavior: "smooth" })
    }

    if (ele2) {
      ele2.scrollTo({ top: 0, left: ele2.scrollLeft + 400, behavior: "smooth" })
    }

    for (let i = 0; i < matchingData?.rows?.length; i++) {
      // For scrolling all rows
      let ele = document.getElementById(`row_${i}`)
      if (ele) {
        // ele.scrollLeft = ele.scrollLeft + 400
        ele.scrollTo({ top: 0, left: ele.scrollLeft + 400, behavior: "smooth" })
      }
    }
  }

  function scrollLeft(id) {
    let ele2 = document.getElementById("header_div")
    let ele1 = document.getElementById("bottom_div")

    if (ele1) {
      ele1.scrollTo({ top: 0, left: ele1.scrollLeft - 400, behavior: "smooth" })
    }

    if (ele2) {
      ele2.scrollTo({ top: 0, left: ele2.scrollLeft - 400, behavior: "smooth" })
    }

    for (let i = 0; i < matchingData?.rows?.length; i++) {
      // For scrolling all rows
      let ele = document.getElementById(`row_${i}`)
      if (ele) {
        ele.scrollTo({ top: 0, left: ele.scrollLeft - 400, behavior: "smooth" })
      }
    }
  }

  function displayScrollButtons(id) {
    setHoveredResourcesSection(true)
    let ele = document.getElementById(id)
    if (ele) {
      if (ele.scrollLeft == 0) {
        setShowScrollLeft(false)
      } else {
        setShowScrollLeft(true)
      }
      if (ele.scrollLeft + ele.offsetWidth >= ele.scrollWidth) {
        setShowScrollRight(false)
      } else {
        setShowScrollRight(true)
      }
    }
  }

  return (
    <Col
      className={`d-inline-block w-100 align-items-center px-2 ${props.type === "view_detail" ? `pt-0` : `pt-3`} mt-2 position-relative`}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
    >
      {props.type === "create" && (
        <div
          className="position-absolute w-100 h-100"
          onClick={(e) => {
            e.preventDefault()
            if (matchingData?.name?.length === 0) {
              props.showError("name")
            }
          }}
          style={{ backgroundColor: "#303c54", opacity: "0.4", zIndex: "10", height: "95%" }}
        ></div>
      )}
      <div className="w-100">
        {displayHeader()}
        <div
          className={`d-inline-flex w-100 align-items-center pb-2 ${matchingData?.mtf_type === "1" ? `` : StylesA.singleRowStyle}`}
          id="bottom_div"
          key={`bottom_div`}
          onMouseOver={() => {
            displayScrollButtons(matchingData?.mtf_type === "0" ? `bottom_div` : `row_0`)
          }}
          onMouseOut={() => {
            setHoveredResourcesSection(false)
          }}
          onScroll={(e) => {
            if (!disableEventListner) {
              scrollHeaderDiv(e)
            }
          }}
          style={{ backgroundColor: matchingData?.mtf_type === "0" && props.type === "view_detail" ? `transparent` : null }}
        >
          <div
            className={`${matchingData?.mtf_type === "0" ? `pr-3` : `pr-5`} pointer ${hoveredResourcesSection && showScrollRight ? `` : `d-none`}`}
            onClick={() => {
              scrollRight(`bottom_div`)
            }}
            onMouseOver={() => {
              setDisableEventListner(true)
            }}
            onMouseOut={() => {
              setDisableEventListner(false)
            }}
            style={{
              position: "absolute",
              right: 0,
              top: "45%",
              zIndex: 5,
            }}
          >
            <Btn
              className={`align-items-center text-center`}
              style={{ backgroundColor: "#212c42", height: "50px", width: "50px", boxShadow: "0px 3px 12px #00000099" }}
              type={`round`}
            >
              <Lottie options={scrollRightOptions} height={40} width={40} />
            </Btn>
          </div>
          <div
            className={`pl-3 pointer ${hoveredResourcesSection && showScrollLeft ? `` : `d-none`}`}
            onClick={() => {
              scrollLeft(`bottom_div`)
            }}
            onMouseOver={() => {
              setDisableEventListner(true)
            }}
            onMouseOut={() => {
              setDisableEventListner(false)
            }}
            style={{
              position: "absolute",
              left: 0,
              top: "45%",
              zIndex: 5,
            }}
          >
            <Btn
              className={`align-items-center text-center`}
              style={{
                backgroundColor: "#212c42",
                height: "50px",
                width: "50px",
                boxShadow: "0px 3px 12px #00000099",
              }}
              type={`round`}
            >
              <Lottie options={scrollLeftOptions} height={40} width={40} />
            </Btn>
          </div>
          <AllRows
            type={props.type}
            matchingData={matchingData}
            handleDeleteRow={props.handleDeleteRow}
            handleAddMultiAnswer={props.handleAddMultiAnswer}
            handleDeleteAnswerField={props.handleDeleteAnswerField}
            setShowRowDeleteModal={props.setShowRowDeleteModal}
            setActiveDeleteRowData={props.setActiveDeleteRowData}
            handleUpdateQuestionData={props.handleUpdateQuestionData}
            handleUpdateAnswerCellData={props.handleUpdateAnswerCellData}
            setSavedContent={props.setSavedContent}
            showValidationErrors={props.showValidationErrors}
            disableEventListner={disableEventListner}
          />
        </div>
      </div>
      {props.type !== "view_detail" && (
        <p
          className="mb-0 underline pointer pl-2 py-2"
          onClick={(e) => {
            e.preventDefault()
            props.handleAddRow()
          }}
          style={{ width: "max-content", fontSize: "14px", color: "white" }}
        >
          + Add more rows
        </p>
      )}
    </Col>
  )
}

export default RowColumnTableData
