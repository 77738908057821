import React, { useEffect, useState, useContext } from "react"
import Col from "react-bootstrap/Col"
import { getRequest } from "../../../services/rest_service"
import { TrainingSocketContext } from "../../../stores/socket/training_socket/trainingSocketProvider"
import { BreakoutGroupContext } from "../../../stores/socket/breakout_group_socket/breakoutGroupProvider"
import Image from "../../custom/Image"
import Styles from "./CustomChecklistReportCard2.module.scss"
import CaretRight from "../../../images/svgs/CaretRightGreen.svg"
import ChevronCircleDown from "../../../images/svgs/arrow-dropdown-white-circle.svg"
import ChevronCircleUp from "../../../images/svgs/arrow-dropup-white-circle.svg"
import Loader from "../../custom/Loader"

const CheckListFocusArea = (props) => {
  const groupData = props.focusarea
  const g_index = props.index
  const mainData = props.mainData
  const [groupCollapsed, setGroupCollapsed] = useState(false)

  return (
    <div key={groupData.group_name + "_" + g_index} className={`bg-42506C br-9px w-100 mb-3 ${groupCollapsed ? `` : `pb-1`} ${Styles.container_box_shadow}`}>
      <div className={`d-inline-flex py-2 ${groupCollapsed ? `` : `mb-2`} ${groupCollapsed ? "" : Styles.group_border} w-100 align-items-center`}>
        <p className={`mb-0 w-100 pl-2 bold-500 fs-19px`}>Focus area - {groupData.group_name}</p>
        <Image
          className="mr-2 float-right ml-auto"
          onClick={(e) => {
            e.preventDefault()
            setGroupCollapsed(!groupCollapsed)
          }}
          src={groupCollapsed ? ChevronCircleDown : ChevronCircleUp}
          width={25}
          height={25}
          alt={`up/down arrow`}
        />
      </div>
      <div className={`px-2 ${groupCollapsed ? `d-none` : ``}`}>
        {groupData.children.map((group, g_index) => {
          return <CheckListGroup group={group} index={g_index} mainData={mainData} type="focus" />
        })}
      </div>
    </div>
  )
}

const CheckListGroup = (props) => {
  const groupData = props.group
  const g_index = props.index
  const mainData = props.mainData
  const [groupCollapsed, setGroupCollapsed] = useState(false)

  return (
    <div key={groupData.group_name + "_" + g_index} className={`w-100 mb-3 br-9px bg-303C54 ${groupCollapsed ? `` : `pb-1`} ${Styles.container_box_shadow}`}>
      <div className={`d-inline-flex py-2 ${groupCollapsed ? `` : `mb-2`} ${groupCollapsed ? "" : Styles.group_border} w-100 align-items-center`}>
        <p className={`mb-0 w-100 pl-2 bold-500 fs-19px`}>{props.type === "focus" ? groupData.sub_group_name : groupData.group_name}</p>
        <Image
          className="mr-2 float-right ml-auto"
          onClick={(e) => {
            e.preventDefault()
            setGroupCollapsed(!groupCollapsed)
          }}
          src={groupCollapsed ? ChevronCircleDown : ChevronCircleUp}
          width={25}
          height={25}
          alt={`up/down arrow`}
        />
      </div>
      <div className={`px-2 ${groupCollapsed ? `d-none` : ``}`}>
        {groupData.children.map((question, q_index) => {
          return (
            <div key={question.item_name + "_" + question.item_id} className={`w-100 mb-3 bg-42506C ${Styles.container_box_shadow}`}>
              <p className={`mb-0 w-100 py-2 pl-2 fs-19px ${Styles.group_border}`}>{question.item_name}</p>
              <div className={`d-inline-flex w-100 align-items-center`}>
                <Image src={CaretRight} width={17} height={43} alt={`caret right`} />
                <p className={`mb-0 w-100 py-2 pl-2 fs-16px`}>{mainData.options[question.selected_answer - 1]}</p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const CustomChecklistReportCard2 = (props) => {
  const repCardID = props.activeGroup?.report_card || 0
  const [isLoading, setIsLoading] = useState(false)
  const [mainData, setMainData] = useState(null)
  const [trainingWSready, trainingWSval, trainingWSsend] = useContext(TrainingSocketContext)
  const [breakoutWsReady, breakoutWsVal, breakoutWsSend] = useContext(BreakoutGroupContext)

  useEffect(() => {
    if (
      breakoutWsVal?.action === "update_answer" &&
      breakoutWsVal?.source === "checklist" &&
      breakoutWsVal.resource === props.activeResource.id &&
      breakoutWsVal.group_id === props.activeGroup?.group_id
    ) {
      repCardID && fetchCheckListDetailedData(false)
    }
  }, [breakoutWsVal])

  useEffect(() => {
    repCardID && fetchCheckListDetailedData()
  }, [repCardID])

  useEffect(() => {
    let data = props.wsData
    if (data && Object.keys(data).length > 0) {
      if (data.report_card_id == repCardID && props["type"] != "group") {
        fetchCheckListDetailedData()
      }
    }
  }, [props.wsData])

  useEffect(() => {
    if (trainingWSval) {
      if (trainingWSval.report_card_id == repCardID && props["type"] != "group") {
        fetchCheckListDetailedData()
      }
    }
  }, [trainingWSval])

  async function fetchCheckListDetailedData(showLoader = true) {
    showLoader && setIsLoading(true)
    let url = `/checklist/student/report_card/${repCardID}/report_card_detailed_view/?limit=0&offset=0`
    if (props?.type === "group") {
      url = `/checklist/group/report_card/${repCardID}/report_card_detailed_view/`
    }
    const response = await getRequest(url)
    if (response.success) {
      setMainData(response.data.results)
      showLoader && setIsLoading(false)
    }
  }

  function getQuestionsData(type) {
    if (type === "self-assessment-simple") {
      return (
        <div>
          {mainData.children.map((question, q_index) => {
            return (
              <div key={question.item_name + "_" + question.item_id} className={`w-100 mb-3 bg-42506C ${Styles.container_box_shadow}`}>
                <p className={`mb-0 w-100 py-2 pl-2 fs-19px ${Styles.group_border}`}>{question.item_name}</p>
                <div className={`d-inline-flex w-100 align-items-center`}>
                  <Image src={CaretRight} width={17} height={43} alt={`caret right`} />
                  <p className={`mb-0 w-100 py-2 pl-2 fs-16px`}>{mainData.options[question.selected_answer - 1]}</p>
                </div>
              </div>
            )
          })}
        </div>
      )
    } else if (type === "self-assessment-adv1") {
      return (
        <div>
          {mainData.children.map((group, g_index) => {
            return <CheckListGroup group={group} index={g_index} mainData={mainData} />
          })}
        </div>
      )
    } else if (type === "self-assessment-adv2") {
      return (
        <div>
          {mainData.children.map((focusarea, f_index) => {
            return <CheckListFocusArea focusarea={focusarea} index={f_index} mainData={mainData} />
          })}
        </div>
      )
    }
  }

  if (!isLoading) {
    return (
      <Col lg="12" md="12" sm="12" className="float-left px-4 text-color-1">
        <div className={`d-inline-flex w-100 my-4`}>
          <div className={`w-100 align-items-center`}>{mainData ? getQuestionsData(mainData.checklist_type) : null}</div>
        </div>
      </Col>
    )
  } else {
    return (
      <div className="text-center my-5">
        <Loader class="text-white" />
      </div>
    )
  }
}

export default CustomChecklistReportCard2
