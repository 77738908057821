import React, { useState, useContext } from "react"

import { SET_ACTIVE_ACTIVITY_DATA, SHOW_ACTIVE_ACTIVITY } from "../../../stores/classroom_store/ClassroomActions"

import PropTypes from "prop-types"
import { Modal } from "react-bootstrap"
import Col from "react-bootstrap/Col"
import Btn from "../../custom/Button"

import Style from "./session-break.module.scss"
import Image from "../../custom/Image"
import InputField from "../../custom/InputField"
import Lightening from "../../../images/svgs/classroom/lightening.svg"
import CloseIcon from "../../../images/svgs/close.svg"
import TextArea from "../../custom/TextArea"
import SelectBreakTime from "./_select_break_time"
import { apiCreateInstantBreak, apiStartInstantBreak } from "./_break_apis"
import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"

const InstantBreakModal = (props) => {
  var break_name = "instant"
  const durations = [10, 15, 20, 30]

  // Instant break form data
  const [breakDuration, setBreakDuration] = useState(0)
  const [breakName, setBreakName] = useState("")
  const [breakObjective, setBreakObjective] = useState("")
  const [classroomState, dispatch] = useContext(ClassroomContext)

  const handleStartBreakClick = () => {
    if (breakName.trim() === "") {
      props.setNotificationData({ type: "error", title: "Break Type cannot be empty." })
    } else if (breakDuration <= 0) {
      props.setNotificationData({ type: "error", title: "Break duration cannot be 0." })
    } else {
      createAndTriggerInstantBreak()
    }
  }

  const createAndTriggerInstantBreak = async () => {
    let formData = new FormData()
    formData.append("break_type", "instant")
    formData.append("break_name", breakName)
    formData.append("duration", breakDuration)
    formData.append("message", breakObjective)
    formData.append("course", classroomState.template_id)

    let res = await apiCreateInstantBreak(formData)
    if (res.success) {
      triggerBreak(res.data)
    } else {
      props.setNotificationData({ type: "error", title: "Failed to create the break, please try again or contact administrator" })
    }
  }

  const triggerBreak = async (breakInstance) => {
    // Once break is created, it needs to be triggered explicitly

    const { success, data } = await apiStartInstantBreak(breakInstance.id)
    if (success) {
      props.onInstantBreakTriggered()
    } else {
      if (data.current_active_activity) {
        dispatch({
          type: SET_ACTIVE_ACTIVITY_DATA,
          payload: { current_active_activity: data.current_active_activity, active_breakout_session: data.active_breakout_session },
        })
        dispatch({ type: SHOW_ACTIVE_ACTIVITY, payload: true })
      } else {
        props.setNotificationData({ type: "error", title: data })
      }
    }
  }

  return (
    <Modal
      show={true}
      onHide={props.handleInstantBreakModalHide}
      centered={true}
      size={"lg"}
      className={`${Style.instant_modal}`}
      contentClassName={`${Style.instant_modal_content}`}
    >
      <Modal.Body className={`p-0`}>
        <div className="pt-3 px-2 pb-1">
          <div className="d-flex justify-content-between px-1">
            <div className="d-flex mb-1">
              <Image src={Lightening} alt={"lightening"} className={`my-auto mr-2`} />
              <p className="my-auto text-light">Instant Break</p>
            </div>
            <Image className={`pointer text-light ${Style.close_icon}`} src={CloseIcon} onClick={props.handleInstantBreakModalHide} alt={`close-icon`} />
          </div>
          <Col lg="12" md="12" sm="12" xs="12" className="px-1">
            <InputField
              className={`${Style.input_filed_bg}`}
              type="text"
              label={`Break Type: eg Tea break`}
              onChange={(e) => setBreakName(e.target.value)}
              required
            />
          </Col>
          <div className={`py-2 mx-1 ${Style.duration_instant_break} mb-2 px-3 border_on_hover`}>
            <p className={`text-light mb-1 ${Style.break_duration}`}>Break Duration</p>
            <SelectBreakTime durations={durations} onValueChange={setBreakDuration} />
          </div>
          <Col lg="12" md="12" sm="12" xs="12" className="px-1">
            <TextArea
              className={`${Style.break_objective_text_area}`}
              type="text"
              label={`Break Objective`}
              onChange={(e) => setBreakObjective(e.target.value)}
            />
          </Col>
        </div>
        <Col className={`py-2 ${Style.instant_break_footer}`}>
          <div className="d-inline justify-content-start">
            <Btn className={`border_on_hover ${Style.done_btn_break} px-3 py-1`} onClick={() => handleStartBreakClick(break_name)}>
              Take the Break
            </Btn>
          </div>
        </Col>
      </Modal.Body>
    </Modal>
  )
}

InstantBreakModal.propTypes = {
  // Handle modal close and hide actions
  handleInstantBreakModalHide: PropTypes.func.isRequired,

  // Callback on break trigger is successfull
  onInstantBreakTriggered: PropTypes.func.isRequired,

  // Callback to set any error notifications
  setNotificationData: PropTypes.func.isRequired,
}

export default InstantBreakModal
