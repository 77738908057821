import { useEffect, useState } from "react"

// Custom Hook for countdowntimer for trainerside break

/**
 * returns the remaining seconds as per the name says and follows the below logic
 * Logic:
 *  1. Convert durationInMins to seconds
 *  2. Calculate the difference of seconds between current time and startedTime in seconds
 *  3. Now remaining time is '1st step value' - '2nd step value'
 * @param {Date} startedTime time at which timer is started
 * @param {number} durationInMins number of muntes timer should run duration in minutes
 * @returns {number} remaining time
 */
const getTotalRemainingTimeInSec = (startedTime, durationInMins) => durationInMins * 60 - (Date.parse(new Date()) - Date.parse(startedTime)) / 1000

/**
 * Converts given seconds to minutes and seconds
 * Ex: if input is 128, return value will be [2, 8]
 * @param {number} totalSeconds Total seconds needs to be parsed
 * @returns {List} Array containing minute and number
 */
const getMinAndSec = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60).toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false })
  const seconds = ("0" + Math.floor(totalSeconds % 60)).slice(-2)
  return [minutes, seconds]
}

/**
 * Custom hook for countdown timer
 * @param {Date} startedTime Timer start time
 * @param {number} durationInMins How long timer should run
 */
const useBreakCountDown = (startedTime, durationInMins) => {
  const [totalRemainingTimeInSec, setTotalRemainingTimeInSec] = useState(getTotalRemainingTimeInSec(startedTime, durationInMins))
  const [breakTimeOver, setBreakTimerOver] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      const remainingTimeInSec = getTotalRemainingTimeInSec(startedTime, durationInMins)
      if (remainingTimeInSec <= 0) {
        setTotalRemainingTimeInSec(0)
        setBreakTimerOver(true)
        clearInterval(interval)
      } else {
        setTotalRemainingTimeInSec(remainingTimeInSec)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [durationInMins])

  return [...getMinAndSec(totalRemainingTimeInSec), breakTimeOver]
}

export default useBreakCountDown
