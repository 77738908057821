import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"

import { postRequest, putRequest } from "../../services/rest_service"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import Image from "../custom/Image"

import TextArea from "../custom/TextArea"
import InputField from "../custom/InputField"
import Notification from "../custom/Notification"
import Btn from "../custom/Button"
import SaveTemplateModal from "./_save-template-modal"
import SaveOverwriteTemplateModal from "./_save-overwrite-template-modal"

import templateIcon from "../../images/svgs/templateLdb.svg"
import savedTemplateIcon from "../../images/svgs/live-discussion-board/template_saved_green_icon.svg"
import idea from "../../images/svgs/idea_buld.svg"

import Style from "./style.module.scss"
import { ClassroomContext } from "../../stores/classroom_store/ClassroomStore"
import { SET_ACTIVE_ACTIVITY_DATA, SET_ACTIVE_SECTION, SET_DISCUSSION_BOARD, SHOW_ACTIVE_ACTIVITY } from "../../stores/classroom_store/ClassroomActions"

const LiveDiscussionStart = (props) => {
  const [classroomState, dispatch] = useContext(ClassroomContext)

  const [discussionTopic, setDiscussionTopic] = useState("")
  const [discussionInstructions, setDiscussionInstructions] = useState("")
  const [discussionTime, setDiscussionTime] = useState(0)
  const [voteCount, setVoteCount] = useState(1)
  const [boardError, setBoardError] = useState(null)
  const [restrictVote, setRestrictVote] = useState(false)
  const [subTopics, setSubTopics] = useState([])

  const [openTemplateModal, setOpenTemplateModal] = useState(false)
  const [boardSavedAsTemplate, setBoardSavedAsTemplate] = useState(false)
  const [templateCreated, settemplateCreated] = useState(false)
  const [newCreatedTemplate, setNewCreatedTemplate] = useState("")
  const [notificationData, setNotificationData] = useState(null)
  const [contentEditedAgain, setContentEditedAgain] = useState(false)

  useEffect(() => {
    if (props.template) {
      let template = props.template
      setDiscussionTopic(template.name)
      setDiscussionInstructions(template.instructions)
      setDiscussionTime(parseInt(template.total_duration))
      setVoteCount(template.allowed_votes)
      if (template.allowed_votes > 0) {
        setRestrictVote(true)
      }
      let sub_topics = []
      template.ldbtemplatetopics_set.forEach((topic) => {
        if (topic.name) {
          sub_topics.push({ name: topic.name })
        }
      })
      setSubTopics(sub_topics)
    }
  }, [props.template])

  // useEffect(() => {
  //   if(templateCreated){
  //     setContentEditedAgain(true)
  //   }
  // }, [discussionTopic, discussionInstructions, discussionTime, voteCount, restrictVote, subTopics])

  const createFormData = (type = "template") => {
    let formData = {}
    formData["name"] = discussionTopic
    if (subTopics.length > 0) {
      formData[type === "template" ? "ldbtemplatetopics_set" : "ldbtopics_set"] = subTopics
    }
    formData["instructions"] = discussionInstructions
    formData["total_duration"] = parseInt(discussionTime)
    formData["allowed_votes"] = restrictVote ? parseInt(voteCount) : 0
    if (type !== "template") {
      formData["training"] = classroomState.training_id
      // Pending:  Will implement after breakout session implememntation
      /* if (props.breakoutSessionActive?.id !== undefined) {
        formData["breakout_session"] = props.breakoutSessionActive?.id
      } */
      if (props.template) {
        formData["template"] = props.template.id
      }
    }
    return formData
  }

  async function handleCreateAndStartDiscussionBoard() {
    if (validation()) {
      let formData = createFormData("discussion_board")
      let res = await postRequest(`/live-board/live-discussion-board/`, formData)
      if (res.success) {
        let startSessionData = await startLiveDiscussionBoard(res.data.id)
        if (startSessionData) {
          dispatch({ type: SET_ACTIVE_SECTION, payload: "DiscussionBoard" })
          dispatch({ type: SET_DISCUSSION_BOARD, payload: { active: startSessionData.id, modal: false, count: classroomState.discussionBoard.count + 1 } })
          setBoardError(null)
        }
      } else {
        // setBoardError(res?.data["total_duration"].join())
      }
    }
  }

  async function startLiveDiscussionBoard(id) {
    let res = await postRequest(`/live-board/live-discussion-board/${id}/trigger_live_discussion_board/`)
    if (res.success) {
      dispatch({ type: SET_ACTIVE_ACTIVITY_DATA, payload: {} })
      return res.data
    } else {
      if (res?.data?.current_active_activity) {
        dispatch({
          type: SET_ACTIVE_ACTIVITY_DATA,
          payload: {
            current_active_activity: res?.data?.current_active_activity,
            active_breakout_session: res?.data?.active_breakout_session,
          },
        })
        dispatch({ type: SHOW_ACTIVE_ACTIVITY, payload: true })
      }
    }
  }

  async function handleCreateDiscussionBoardTemplate(template_name) {
    if (validation()) {
      settemplateCreated(false)
      let formData = createFormData("template")
      formData["template_name"] = template_name
      let res = await postRequest(`/live-board/live-discussion-board-template/`, formData)
      if (res.success) {
        setBoardSavedAsTemplate(true)
        settemplateCreated(true)
        setNewCreatedTemplate(res.data)
        setNotificationData({ type: "success", title: "Your template has been saved successfully" })
        setOpenTemplateModal(false)
        setContentEditedAgain(false)
      } else {
        // setBoardError(res?.data["total_duration"].join())
      }
    }
  }

  async function handleEditDiscussionBoardTemplate(id, template_name) {
    if (validation()) {
      settemplateCreated(false)
      let formData = createFormData("template")
      formData["template_name"] = template_name
      let res = await putRequest(`/live-board/live-discussion-board-template/${id}/`, formData)
      if (res.success) {
        setBoardSavedAsTemplate(true)
        settemplateCreated(true)
        setNotificationData({ type: "success", title: "Your template has been saved successfully" })
        setOpenTemplateModal(false)
        setContentEditedAgain(false)
      } else {
        // setBoardError(res?.data["total_duration"].join())
      }
    }
  }

  function validation() {
    let isValid = false
    let subTopicError = false
    subTopics.forEach((data) => {
      if (data.name == "") {
        subTopicError = true
      }
    })
    if (discussionTopic === "") {
      setBoardError("Topic cannot be empty.")
    } else if (parseInt(discussionTime) < 1) {
      setBoardError("Invalid duration.")
    } else if (parseInt(voteCount) < 1 && restrictVote) {
      setBoardError("Invalid vote count.")
    } else if (subTopicError) {
      setBoardError("Sub topic cannot be empty.")
    } else {
      isValid = true
    }
    return isValid
  }

  function handleSubtopicsChanges(e, index) {
    let sub_topics = [...subTopics]
    sub_topics[index] = { name: e.target.value }
    setSubTopics(sub_topics)
    setContentEditedAgain(true)
  }

  function handleAddSubTopics() {
    let sub_topics = [...subTopics]
    sub_topics.push({ name: "" })
    setSubTopics(sub_topics)
    setContentEditedAgain(true)
  }

  function handleDeleteSubTopics(index) {
    let sub_topics = [...subTopics]
    sub_topics.splice(index, 1)
    setSubTopics(sub_topics)
    setContentEditedAgain(true)
  }

  function handleAddTemplateClick() {
    if (validation()) {
      setOpenTemplateModal(true)
    }
  }

  function handleTopicChange(e) {
    setDiscussionTopic(e.target.value)
    setContentEditedAgain(true)
  }

  function handleInstructionChange(e) {
    setDiscussionInstructions(e.target.value)
    setContentEditedAgain(true)
  }

  function handleTimeChange(e) {
    setDiscussionTime(e.target.value)
    setContentEditedAgain(true)
  }

  return (
    <div className="mx-3 mt-2">
      <>
        <div className={`row py-2`}>
          <div className="col-12">
            <InputField
              style={{ backgroundColor: "#42506C" }}
              label="Topic of Discussion"
              name="discussion_topic"
              defaultValue={discussionTopic}
              onChange={handleTopicChange}
              required
            />
          </div>
          <div className={`ml-3 w-100`}>
            <div className={`d-flex w-100`}>
              <div style={{ borderLeft: "1px solid #303C54" }}></div>
              <div className={`w-100`}>
                {subTopics.map((data, index) => {
                  return (
                    <div key={`sub_topic_${index}`} className="col-12">
                      <InputField
                        style={{ backgroundColor: "#42506C" }}
                        onDelete={() => {
                          handleDeleteSubTopics(index)
                        }}
                        label={`${index + 1} Sub Topic of Discussion`}
                        name={`sub_topic_${index}`}
                        onChange={(e) => {
                          handleSubtopicsChanges(e, index)
                        }}
                        defaultValue={data.name}
                        required
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className={`col-12 mb-2 fs-16px link`} onClick={handleAddSubTopics}>
            + Add more Sub Topics
          </div>
          <div className="col-12">
            <TextArea
              style={{ backgroundColor: "#42506C" }}
              label="Instructions"
              name="instructions"
              defaultValue={discussionInstructions}
              onChange={handleInstructionChange}
              required
            />
          </div>
          <div className="col-12">
            <InputField
              style={{ backgroundColor: "#42506C" }}
              type="number"
              label="Define Discussion Time :"
              name="discussion_time"
              defaultValue={discussionTime}
              placeholder="00"
              onChange={handleTimeChange}
              min={1}
              max={999}
              required
            >
              mins
            </InputField>
          </div>
          <div className="col-12">
            <div className="d-flex my-2">
              <input
                style={{ height: "22px", width: "22px" }}
                type="checkbox"
                onChange={(e) => {
                  setRestrictVote(e.target.checked)
                  setContentEditedAgain(true)
                }}
                checked={restrictVote}
                id="ldb_allow_vote"
              />
              <label htmlFor={"ldb_allow_vote"} className="ml-2 mb-0 fs-14px">
                Limit No of votes per user
              </label>
            </div>
          </div>
          {restrictVote && (
            <>
              <div className="col-12">
                <InputField
                  style={{ backgroundColor: "#42506C" }}
                  type="number"
                  label="Number of Votes per student :"
                  name="vote_per_student"
                  defaultValue={voteCount}
                  value={voteCount}
                  onChange={(e) => {
                    setVoteCount(e.target.value)
                    setContentEditedAgain(true)
                  }}
                  min={1}
                  max={999}
                >
                  votes
                </InputField>
              </div>
              <div className={`col-12 my-2`}>
                <div className={`d-flex fs-14px`}>
                  <div className="mr-2">
                    <Image src={idea} alt="idea" />
                  </div>
                  <div className="text-grey">
                    Each student can only use the total number of votes assigned . The votes will be limited to one vote for each card per each student
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className={`w-100 pb-3 d-flex align-items-center justify-content-between`}>
          <div className="d-flex align-items-center">
            <Btn onClick={handleCreateAndStartDiscussionBoard}>Start Discussion</Btn>
            <div className={`mb-0 ml-1 fs-12px align-self-center`}>
              {boardError !== null && (
                <div className="d-inline-flex px-2 py-0" style={{ borderRadius: "23px" }}>
                  <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "13px" }} />
                  <p className="mb-0 fs-14px" style={{ color: "#F4F411" }}>
                    {boardError}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className={`d-flex fs-14px position-relative`}>
            <Notification style={{ position: "absolute", left: "-225px", top: "-55px", right: "0px" }} data={notificationData} setData={setNotificationData} />
            {boardSavedAsTemplate ? (
              <React.Fragment>
                <Image className={`mb-0 ${Style.h_16px}`} src={savedTemplateIcon} />
                <div
                  onClick={() => (contentEditedAgain ? handleAddTemplateClick() : null)}
                  style={{ color: "#47E383" }}
                  className={`ml-2 underline ${contentEditedAgain ? "pointer" : ""}`}
                >
                  Saved as a template
                </div>
              </React.Fragment>
            ) : (
              <div className={`${props.isTemplate && !contentEditedAgain ? "disabled-div" : ""} d-flex`}>
                <Image className={`mb-0 ${Style.h_16px}`} src={templateIcon} />
                <div onClick={handleAddTemplateClick} className={`ml-2 underline pointer`}>
                  Save as a template
                </div>
              </div>
            )}
          </div>
        </div>
      </>
      {openTemplateModal && !props.isTemplate && (
        <SaveTemplateModal
          show={openTemplateModal}
          setShow={setOpenTemplateModal}
          handleCreateDiscussionBoardTemplate={handleCreateDiscussionBoardTemplate}
          templateCreated={templateCreated}
          newCreatedTemplate={newCreatedTemplate}
          handleEditDiscussionBoardTemplate={handleEditDiscussionBoardTemplate}
        />
      )}
      {openTemplateModal && props.isTemplate && (
        <SaveOverwriteTemplateModal
          show={openTemplateModal}
          setShow={setOpenTemplateModal}
          handleCreateDiscussionBoardTemplate={handleCreateDiscussionBoardTemplate}
          templateCreated={templateCreated}
          template={props.template}
          handleEditDiscussionBoardTemplate={handleEditDiscussionBoardTemplate}
        />
      )}
    </div>
  )
}

LiveDiscussionStart.propTypes = {
  //active breakout session object
  breakoutSessionActive: PropTypes.object,

  //boolean field to check whether it is template or not
  isTemplate: PropTypes.bool,
}

export default LiveDiscussionStart
