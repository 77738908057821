import React, { useEffect, useState } from "react"

import Modal from "react-bootstrap/Modal"
import Loader from "../custom/Loader"

import InputField from "../custom/InputField"
import Btn from "../custom/Button"
import CollapsableBox from "./_collapsable_box"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import Style from "./style.module.scss"

const SaveOverwriteTemplateModal = (props) => {
  const [newTemplateName, setNewTemplateName] = useState("")
  const [existingTemplateName, setExistingTemplateName] = useState("")
  const [validationError, setValidationError] = useState("")
  const [updateValidationError, setUpdateValidationError] = useState("")
  const [savingTemplateLoader, setSavingTemplateLoader] = useState(false)
  const [editTemplateLoader, setEditTemplateLoader] = useState(false)

  useEffect(() => {
    if(props.templateCreated){
      setSavingTemplateLoader(false)
      setEditTemplateLoader(false)
    }
  }, [props.templateCreated])

  useEffect(() => {
    if(props.template){
      setExistingTemplateName(props.template.template_name)
    }
  }, [props.template])

  const handleCloseModal = () => {
    props.setShow(false)
  }

  const showSaveButton = (type, name, error, loader) => {
    return(
      <div className={"d-flex align-items-center"} style={{position: "relative"}}>
        {/*Save button is disabled if template name is empty or saving template is in progress.
        Once it is saved then it will stay disabled till the content is edited again.*/}
        <Btn className={"d-flex"} onClick={() => validateTemplateName(type) } type="small"
        disabled={name === "" || loader} >
          {loader && <Loader/> }
          Save Template
        </Btn>
        {error && (
          <div className="d-inline-flex px-2 py-0" style={{ borderRadius: "23px" }}>
            <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "13px" }} />
            <p className="mb-0" style={{ fontSize: "14px", color: "#F4F411" }}>
              {error}
            </p>
          </div>
        )}
      </div>
    )
  }

  const validateTemplateName = (type) => {
    if(type === 'create'){
      if(newTemplateName){
        setValidationError("")
        setSavingTemplateLoader(true)
        props.handleCreateDiscussionBoardTemplate(newTemplateName)
      }else{
        setValidationError("Please provide template name")
      }
    }else{
      if(existingTemplateName){
        setUpdateValidationError("")
        setEditTemplateLoader(true)
        props.handleEditDiscussionBoardTemplate(props.template.id, existingTemplateName)
      }else{
        setUpdateValidationError("Please provide template name")
      }
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={handleCloseModal}
      size="md"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      dialogClassName={`${Style.live_discussion_modal} ${Style.left_5}`}
      contentClassName={`${Style.questions_modal}`}
    >
      <Modal.Header className={`px-2 pt-1 pb-0`} style={{ borderColor: "transparent" }}>
        <div className={`w-100 d-flex justify-content-between`}>
          <div className={`d-flex fs-16px align-items-center`}>
            <div>Save as a Template</div>
          </div>
          <div>
            <FontAwesomeIcon className={`pointer`} onClick={handleCloseModal} icon={faTimesCircle} />
          </div>
        </div>
      </Modal.Header>
      <hr className="my-0 mx-auto" style={{ width: "98%", background: "#8C929E" }} />
      <Modal.Body>
        <div className={`my-2`}>
          <CollapsableBox defaultClose={true} title={`Save as a new template`}>
            <div className={`mx-3 pb-2`}>
              <div className={`row mt-2`}>
                <div className="col-12">
                  <InputField onChange={(e) => { setNewTemplateName(e.target.value) }}
                  style={{ backgroundColor: "#42506C" }} label="Name of the template" name="template_name" required />
                </div>
              </div>
              {showSaveButton('create', newTemplateName, validationError, savingTemplateLoader)}
            </div>
          </CollapsableBox>
          <CollapsableBox defaultClose={false} title={`Overwrite existing template`}>
            <div className={`mx-3 pb-2`}>
              <div className={`row mt-2`}>
                <div className="col-12">
                  <InputField onChange={(e) => { setExistingTemplateName(e.target.value) }} defaultValue={ existingTemplateName}
                  style={{ backgroundColor: "#42506C" }} label="Name of the template" name="template_name" required />
                </div>
              </div>
              {showSaveButton('edit', existingTemplateName, updateValidationError, editTemplateLoader)}
            </div>
          </CollapsableBox>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SaveOverwriteTemplateModal
