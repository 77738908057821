import React, { useEffect, useState } from "react"

import PropTypes from "prop-types"
import Image from "../../custom/Image"

import Style from "./session-break.module.scss"
import Tick from "../../../images/svgs/classroom/tick.svg"

const SelectBreakTime = (props) => {
  const [customDuration, setCustomDuration] = useState(false)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)

  const handleHoursChange = (e) => {
    if (!e.target.value) {
      setHours(0)
      return
    }
    setHours(parseInt(e.target.value))
  }

  const handleMinsChange = (e) => {
    const enteredMinutes = parseInt(e.target.value)
    if (!enteredMinutes) {
      setMinutes(0)
      return
    }
    const newHours = Math.floor(enteredMinutes / 60)
    setHours((h) => {
      return parseInt(h) + newHours
    })
    setMinutes(enteredMinutes % 60)
  }

  useEffect(() => {
    props.onValueChange(hours * 60 + minutes)
  }, [hours, minutes])

  return (
    <>
      {customDuration ? (
        <div className={`text-light`}>
          <input type="number" className={`${Style.input_box_extend_time}`} value={hours} onChange={(e) => handleHoursChange(e)} maxLength={2} min={0} />
          <span className={`${Style.select_break_text}`}>Hrs</span> :{" "}
          <input type="number" className={`${Style.input_box_extend_time}`} value={minutes} onChange={(e) => handleMinsChange(e)} min={1} />
          <span className={`${Style.select_break_text}`}>Mins</span>
        </div>
      ) : (
        <div className="d-flex text-light justify-content-between">
          <div className="d-flex">
            {props.durations.map((duration, index) => {
              return (
                <div
                  onClick={() => setMinutes(duration)}
                  key={`duration_${index}`}
                  id={`duration-${index}`}
                  className={`${minutes === duration ? Style.duration_selected_btn : Style.duration_select_btn} mr-1 p-1 px-3 pointer`}
                >
                  <Image src={Tick} alt={"tick"} className={minutes === duration ? `my-auto` : `d-none`} style={{ width: "14px" }} />
                  <p className="my-auto ml-1"> {duration} Mins</p>
                </div>
              )
            })}
          </div>
          <div onClick={() => setCustomDuration(true)}>
            <u className="pointer fs-12px">Add another duration</u>
          </div>
        </div>
      )}
    </>
  )
}

SelectBreakTime.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  durations: PropTypes.arrayOf(PropTypes.number),
}

export default SelectBreakTime
