import React, { useContext, useEffect, useState } from "react"

import PropTypes from "prop-types"
import { Modal } from "react-bootstrap"
import Image from "../../custom/Image"

import Style from "./session-break.module.scss"
import Lightening from "../../../images/svgs/classroom/lightening.svg"
import HotTea from "../../../images/svgs/classroom/hot_tea.svg"

import CloseIcon from "../../../images/svgs/close.svg"
import { apiBreakSuggessions } from "./_break_apis"
import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"

const ScheduleBreakModal = (props) => {
  const [suggestedBreaks, setSuggestedBreaks] = useState([])
  const [classroomState, dispatch] = useContext(ClassroomContext)

  useEffect(() => {
    getBreakotSuggestions()
  }, [])

  const getBreakotSuggestions = async () => {
    let res = await apiBreakSuggessions(classroomState.training_id)
    if (res.success) {
      setSuggestedBreaks([...res.data])
    }
  }

  return (
    <Modal show={true} onHide={props.handleSessionBreakModalHide} centered={true} dialogClassName={Style.break_modal_height} size={"md"}>
      <Modal.Body className={`${Style.break_modal}`}>
        <div className="d-flex">
          <Image
            className={`pointer ml-auto text-light ${Style.extend_time_close}`}
            src={CloseIcon}
            onClick={props.handleSessionBreakModalHide}
            alt={`close_icon`}
          />
        </div>
        <div className="d-flex flex-column m-2 mb-4">
          <div className={`text-light mx-auto d-flex fs-14px`}>
            <Image src={HotTea} alt={"hot tea"} className={`mt-3 mr-2 mb-4`} />
            <p className={`my-auto ${Style.schedule_break_text} fs-18px bold-600`}>Hey, Looks like you need a break!</p>
          </div>
          <div className={`d-inline-block w-100 align-items-cemter text-light`}>
            <p className={`mb-2 ${Style.schedule_break_text} text-center`}>Mostly used breaks</p>
            <div className={`d-flex flex-wrap w-100 mb-3 justify-content-center`}>
              {suggestedBreaks.map((item, index) => {
                return (
                  <div
                    className={`d-flex flex-wrap pointer align-items-center border_on_hover py-2 px-3 ${Style.mostly_used_break} mr-1 mb-1`}
                    onClick={(e) => {
                      e.preventDefault()
                      props.onClickOfMostlyUsedBreaks(item)
                    }}
                    key={`breaks-${index}`}
                  >
                    <Image src={item.icon ? item.icon : Lightening} alt={item.name} className={`mb-0`} />
                    <p className="mb-0 pl-2 fs-14px">{item.name}</p>
                  </div>
                )
              })}
            </div>
          </div>

          <div className={`${Style.break_option_btn} d-inline-flex mx-auto py-2 pointer border_on_hover mt-3`} onClick={props.onClickScheduleInstantBreak}>
            <div className="d-flex mx-auto">
              <Image src={Lightening} alt={"lightening"} className={`my-auto mr-2`} />
              <p className="my-auto">Other Breaks</p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

ScheduleBreakModal.propTypes = {
  // To handle on close
  handleSessionBreakModalHide: PropTypes.func.isRequired,

  // Action callbacks on the modal
  onClickScheduleInstantBreak: PropTypes.func.isRequired,
}

export default ScheduleBreakModal
