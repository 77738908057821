import React, { useCallback, useEffect } from "react"
import _ from "lodash"

import Col from "react-bootstrap/Col"
import Btn from "../custom/Button"

import deleteimg from "../../images/svgs/delete.svg"
import DragHandleIcon from "../../images/svgs/drag-handle-gray.svg"

import StylesA from "./matching.module.scss"
import Styles from "../../modularscss/styles.module.scss"

const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

function AnswerCell(props) {
  const matchingData = props.matchingData

  const delayedQuery = useCallback(
    _.debounce((name, value, data, id, row_index, col_index, col_child_index) => sendQuery(name, value, data, id, row_index, col_index, col_child_index), 1000),
    []
  )

  function sendQuery(name, value, data, id, row_index, col_index, col_child_index) {
    props.handleUpdateAnswerCellData(name, value, data, id, row_index, col_index, col_child_index)
  }

  useEffect(() => {
    if (props.answer_cell.answer) {
      props.handleUpdateHeightOfRow("answer", props.row_index, props.col_index, props.col_child_index)
    }
  }, [props.answer_cell.answer])

  return (
    <Col
      id={`answers_list_${props.col_child_index}`}
      key={`answers_list_${props.col_child_index}_${props.answer_cell?.id}`}
      onClick={(e) => {
        e.preventDefault()
        document.getElementById(`answer_${props.row_index}_${props.col_index}_${props.col_child_index}`).focus()
      }}
      xs="12"
      className={`px-0 ${props.mtf_type === "0" && props.col_child_index > 0 ? `d-none` : `d-flex`} w-100 ${
        props.mtf_type === "1" ? ` mb-2 flex-shrink-1` : `h-100`
      } align-items-stretch`}
    >
      <div
        variant="none"
        className={`${Styles.font_16} h-100 w-100 shadow-none d-flex align-items-stretch`}
        style={{
          color: `#F2F4FF`,
          backgroundColor: "#42506C",
          border: props.showValidationErrors && props.answer_cell?.answer?.length === 0 ? "1px solid #ff7458" : "none",
        }}
      >
        <div className={`d-inline-flex h-100 pl-2 pr-3 py-1 align-items-baseline`} style={{ borderRight: "1px solid #838B9E" }}>
          {props.type !== "view_detail" ? (
            <img
              src={DragHandleIcon}
              alt={`drag-handle`}
              className={`mt-1 mb-0 mr-1 pointer`}
              style={{ objectFit: `contain`, height: `13px`, width: "16px" }}
            />
          ) : null}
          <p className={`mb-0 pl-2`} style={{ fontSize: "19px", fontWeight: "500", color: "#E0E2E5" }}>
            {alphabets[props.mtf_type === "0" ? props.row_index : props.col_child_index]}
          </p>
        </div>
        <textarea
          id={`answer_${props.row_index}_${props.col_index}_${props.col_child_index}`}
          name={`answer`}
          className={` ${StylesA.resizing_textarea} shadow-none px-2 py-1 ${StylesA.answer_cell}`}
          style={{ height: "auto" }}
          placeholder={props.type !== "view_detail" ? "Type here" : ""}
          disabled={props.type === "view_detail" ? true : false}
          onKeyDown={(e) => {
            props.handleUpdateHeightOfRow("answer", props.row_index, props.col_index, props.col_child_index)
          }}
          onChange={(e) => {
            props.setSavedContent(false)
            delayedQuery(e.target.name, e.target.value, matchingData, props.answer_cell?.id, props.row_index, props.col_index, props.col_child_index)
          }}
          onBlur={(e) => {
            e.preventDefault()
            e.stopPropagation()
            props.handleUpdateHeightOfRow("answer", props.row_index, props.col_index, props.col_child_index)
            if (e.target.value !== props.answer_cell?.answer) {
              props.setSavedContent(false)
              props.handleUpdateAnswerCellData(
                e.target.name,
                e.target.value,
                matchingData,
                props.answer_cell?.id,
                props.row_index,
                props.col_index,
                props.col_child_index
              )
            }
          }}
          defaultValue={typeof props.answer_cell?.answer === "object" ? props.answer_cell?.answer?.answer : props.answer_cell?.answer}
        />
      </div>
      {((props.col_index === props.single_row?.answers_list?.length - 1 && props.mtf_type === "0") || (props.mtf_type === "1" && props.answers?.length > 1)) &&
      props.type !== "view_detail" ? (
        <div className="pl-2">
          <Btn
            style={{ backgroundColor: "#42506C", boxShadow: " 0px 3px 6px #00000029" }}
            onClick={(e) => {
              e.preventDefault()
              if (props.mtf_type === "0") {
                if (localStorage.getItem("dontShowDeleteRow") === "true") {
                  props.handleDeleteRow(props.single_row?.question_data?.id)
                } else {
                  props.setShowRowDeleteModal(true)
                  props.setActiveDeleteRowData(props.single_row?.question_data)
                }
              } else {
                props.handleDeleteAnswerField(
                  props.single_row?.question_data.id,
                  props.column_id,
                  props.answer_cell.id,
                  props.row_index,
                  props.col_index,
                  props.col_child_index
                )
              }
            }}
            type={`round`}
          >
            <img
              style={{
                width: "15px",
                height: "15px",
              }}
              alt="delete class"
              src={deleteimg}
              className="mb-0 mx-auto"
            />
          </Btn>
        </div>
      ) : null}
    </Col>
  )
}

export default AnswerCell
