import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Modal, Col } from "react-bootstrap"
import SunEditor from "suneditor-react"
import { putRequest } from "../../../../services/rest_service"

import CancelWhite from "../../../../images/svgs/close.svg"
import CheckIcon from "../../../../images/svgs/check-white.svg"
import Style from "../../../class-room-training/lesson-board.module.scss"
import StylesA from "../../../template-resources/breakout-session.module.scss"
import StyleModular from "./seek_support_reply_modal.module.scss"

const SeekSupportReplyFromNotificationModal = (props) => {
  const [trainerReplay, setTrainerReplay] = useState("")
  const [sentToStudent, setSentToStudent] = useState(false)
  const [sentToGroup, setSentToGroup] = useState(false)
  const [showSendToGroupModal, setShowSendToGroupModal] = useState(false)

  useEffect(() => {
    setSentToStudent(false)
    setSentToGroup(false)
  }, [trainerReplay])

  const handleSubmitReplay = async (e, type) => {
    e.preventDefault()

    if (props.notification_data?.group_id != null) {
      var formData = new FormData()
      formData.append("value", trainerReplay)
      formData.append("question", props.notification_data?.help_text)
      formData.append("group_name", props.notification_data?.group_name)
      if (type === "to_student") {
        formData.append("send_student", props.notification_data?.student_id)
      }
      let response = await putRequest(`/breakout-groups-web-socket/` + props.notification_data?.group_id + "/reply_seek_support/", formData)
      if (response.success) {
        if (type === "to_student") {
          setSentToStudent(true)
        } else if (type === "to_group") {
          setSentToGroup(true)
          setShowSendToGroupModal(false)
        }
      }
    }
  }

  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={props.onHide}
        centered
        size="lg"
        className={`${StylesA.classroom_delete_modal} classroom-resource-delete-modal p-0 zIndex-9999`}
      >
        <Modal.Body className={`float-left p-3 ${StyleModular.modal_body}`}>
          <div className={`d-inline-flex w-100 `}>
            <div
              className={`mr-2 ${Style.dangerous_data} fs-16px bold-500 turient-header-text-color`}
              dangerouslySetInnerHTML={{ __html: props.notification_data?.help_text }}
            />
            <p className={`mb-0 mr-2 fs-16px turient-header-text-color opacity-60`}>
              Asked by <u className="font-weight-bold">{props.notification_data?.student_name}</u> From{" "}
              <span className="font-weight-bold">
                <u>{props.notification_data?.group_name}</u>
              </span>
            </p>
            <img onClick={props.onHide} src={CancelWhite} alt={`Cancel`} height={`20px`} className={`ml-auto mr-1 mb-0 pointer object-fit-contain`} />
          </div>
          <Col className={`px-0 border_on_hover pl-0 ${StyleModular.suneditor_parent_div} `}>
            <div className={`w-100 mb-3 d-inline-flex align-items-center`}>
              <SunEditor
                id="replay"
                placeholder="Type here"
                onChange={setTrainerReplay}
                setContents={trainerReplay}
                setOptions={{
                  height: "auto",
                  maxHeight: "100px",
                  minHeight: "100px",
                  backgroundColor: "#212C42",
                  buttonList: [["align", "list", "font", "fontSize", "bold", "underline", "italic", "link"]],
                }}
              />
            </div>
          </Col>
          <div className={`d-inline-flex`}>
            {!sentToStudent ? (
              <button
                disabled={trainerReplay.length === 0}
                className={`poppins_font ${Style.breakout_modal_fotter_btn_inactive} border_on_hover float-left px-3 py-1 mt-2 mr-2`}
                onClick={(e) => {
                  handleSubmitReplay(e, "to_student")
                }}
              >
                <p className={`mb-0 poppins_font fs-14px bold-500`}>Send To Student </p>
              </button>
            ) : (
              <div className="d-flex pr-3 align-items-center">
                <img src={CheckIcon} alt={`white check`} width={`13px`} className={`my-auto mr-1 pointer object-fit-contain`} />
                <p className="fs-14px m-0 turient-header-text-color">Sent to student</p>
              </div>
            )}
            {!sentToGroup ? (
              <button
                disabled={trainerReplay.length === 0}
                className={`poppins_font ${Style.breakout_modal_fotter_btn_inactive} border_on_hover float-left py-1 px-3 mt-2`}
                onClick={(e) => {
                  setShowSendToGroupModal(true)
                }}
              >
                <p className={`mb-0 poppins_font fs-14px bold-500`}>Send To Group </p>
              </button>
            ) : (
              <div className="d-flex pr-3 align-items-center">
                <img src={CheckIcon} alt={`white check`} width={`13px`} className={`my-auto mr-1 pointer object-fit-contain`} />
                <p className="fs-14px m-0 turient-header-text-color">Sent to Group</p>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showSendToGroupModal}
        onHide={() => {
          setShowSendToGroupModal(false)
        }}
        centered
        size="md"
        className={`${StylesA.classroom_delete_modal} classroom-resource-delete-modal p-0 zIndex-10000`}
      >
        <Modal.Body className={`float-left text-center p-3 ${StyleModular.send_to_group_modal_body}`}>
          <p className={`mb-3 turient-header-text-color fs-18px`}>Are you sure you want to send to {props.notification_data?.group_name} ?</p>
          <img
            onClick={(e) => {
              setShowSendToGroupModal(false)
            }}
            src={CancelWhite}
            alt={`Cancel`}
            className={`ml-auto mr-1 mb-0 pointer ${StyleModular.cancel_white}`}
          />
          <div className={`w-100 mb-2`}>
            <button
              className={`poppins_font mx-auto px-4 py-2 border_on_hover turient-header-text-color w-50 ${StyleModular.send_to_group_btn}`}
              onClick={(e) => {
                handleSubmitReplay(e, "to_group")
              }}
            >
              <p className={`mb-0 poppins_font fs-16px bold-500`}>Yes, send to group</p>
            </button>
          </div>
          <div className={`w-100`}>
            <button
              className={`poppins_font px-5 py-2 w-50 ${Style.breakout_modal_fotter_btn_inactive} border_on_hover`}
              onClick={(e) => {
                setShowSendToGroupModal(false)
              }}
            >
              <p className={`mb-0 poppins_font fs-16px bold-500`}>No, don't send</p>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

SeekSupportReplyFromNotificationModal.propTypes = {
  notification_data: PropTypes.object.isRequired, //Contains all the notiication data which is needed
  show: PropTypes.bool.isRequired, // For showing modal
  onHide: PropTypes.func.isRequired, // For hiding  the modal
}

export default SeekSupportReplyFromNotificationModal
