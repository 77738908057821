import React from "react"
import Style from "./style.module.scss"

import utcDateTime from "../../services/utcdatetime"
import AvatarCircle from "../custom/AvatarCircle"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons"

const CommentCard = (props) => {
  let updated_at = props?.data?.updated_at,
    comment_time = utcDateTime.utcDateTime(updated_at, "time")
  let comment_date = utcDateTime.utcDateTime(updated_at, "date")
  comment_date += " " + utcDateTime.utcDateTime(updated_at, "month_name")
  comment_date += " " + utcDateTime.utcDateTime(updated_at, "year")
  return (
    <div
      className={`col-lg-12 col-md-6 p-2`}
      style={{ minWidth: "270px", maxWidth: `${props.topicCount == "2" ? "320px" : props.topicCount == "1" ? "320px" : "650px"}` }}
    >
      <div className={`${Style.cs_ques_box} ${Style.comment_box} position-relative inner-element-box-shadow p-2 active-session-bg`}>
        <div className={`d-flex align-items-center w-100`}>
          <AvatarCircle index={props?.index} name={props.data?.answered_by?.first_name} size={"30px"} avatar={props.data?.answered_by?.profile_pic} />
          <div className={`flex-column ml-2 w-100`}>
            <div className={`bold-500 fs-14px`}>{props?.data?.answered_by?.first_name}</div>
            <div className={`fs-12px opacity-60`}>{comment_time + ", " + comment_date}</div>
          </div>
        </div>
        <p className={`mb-0 w-100 fs-16px ${Style.comment_data}`}>{props?.data?.comment}</p>
        <div className={`position-absolute text-center p-1 align-items-center d-flex ${Style.vote_count_1}`}>
          {props?.type === "result" ? (
            <>
              <FontAwesomeIcon className={`ml-1`} color={props.data?.comment_votes_count > 0 ? "#FFFFFF" : "#FFFFFF50"} icon={faThumbsUp} />
              <p className="mb-0 fs-14px">
                {props.data?.comment_votes_count + `${props.data?.comment_votes_count > 1 || props.data?.comment_votes_count === 0 ? " likes" : " like"}`}
              </p>
            </>
          ) : (
            <>
              <FontAwesomeIcon className={`ml-1 mr-1`} color={props.data?.votes?.likes > 0 ? "#FFFFFF" : "#FFFFFF50"} icon={faThumbsUp} />
              <p className="d-flex mb-0 pr-1">
                <span className="mr-1">{props.data?.votes?.likes}</span>
                <span className="mr-1">{props.data?.votes?.likes > 1 || props.data?.votes?.likes === 0 ? " likes" : " like"}</span>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default CommentCard
