import React, { useContext, useEffect } from "react"
import { Modal } from "react-bootstrap"

import Btn from "../../custom/Button"

import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"
import { TrainingSocketContext } from "../../../stores/socket/training_socket/trainingSocketProvider"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import TimerComponent from "../../custom/TimerComponent"
import useTimer from "../../../hooks/_countdown_timer"
import ExtendTimeReplyModal from "./extendTimeReplyModal"

const SuggestExtendTimeModal = ({ showModal, setShowModal, updateBreakoutSessionStatus, handleSubmitExtendTime, handleChangeExtendMins }) => {
  const [classroomState, dispatch] = useContext(ClassroomContext)
  const [trainingWSready, trainingWSval, trainingWSsend] = useContext(TrainingSocketContext)

  const [remainingHours, remainingMinutes, remainingSeconds, timerEnded, timerReset, timerEndsWarning] = useTimer(
    classroomState?.activeBreakoutSession?.start_time,
    classroomState?.activeBreakoutSession?.total_duration / 60,
    "decrement"
  )

  useEffect(() => {
    if (trainingWSval?.event_type === "breakout_session_status" && trainingWSval?.data?.status == 2) {
      setShowModal(false)
    }
  }, [trainingWSval])

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false)
      }}
      centered
      // Need to reafactor classroom-delete-modal css class.
      className={`classroom-delete-modal classroom-resource-delete-modal`}
    >
      <div
        className={``}
        style={{
          backgroundColor: "#19253b",
          borderRadius: "8px 8px 0px 0px",
        }}
      >
        <div className="w-100 align-items-center px-3 py-2">
          <div className={`w-100 d-inline-flex`}>
            <FontAwesomeIcon
              className={`pointer ml-auto`}
              onClick={() => {
                setShowModal(false)
              }}
              icon={faTimesCircle}
              color="white"
            />
          </div>
          <div className={`w-100 d-inline-flex`}>
            <div className={`mx-auto d-inline-flex`}>
              <FontAwesomeIcon className="" icon={faExclamationTriangle} color="#E0E2E5" size="sm" style={{ height: "25px" }} />
              <p className="mb-0 ml-2" style={{ fontSize: "18px", color: "#E0E2E5" }}>
                {timerEnded ? (
                  `Breakout Session time ended.`
                ) : (
                  <>
                    Your session ends in{" "}
                    <span style={{ fontWeight: "bold" }}>
                      <TimerComponent
                        startedTime={classroomState?.activeBreakoutSession?.start_time}
                        totalTimerDuration={classroomState?.activeBreakoutSession?.total_duration / 60}
                        timerType={"decrement"}
                      />
                    </span>{" "}
                    mins
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Modal.Body
        className="text-center"
        style={{
          backgroundColor: "#121b2b",
          borderRadius: "0px 0px 8px 8px",
        }}
      >
        <p className={`mb-0`} style={{ fontSize: "18px", fontWeight: "500" }}>
          {" "}
          Do you want to extend time or end it?
        </p>
        <p className={`mb-3`} style={{ fontSize: "18px", fontWeight: "500" }}>
          {" "}
          {classroomState.activeBreakoutSession.name}
        </p>
        <div className="d-flex mb-3 justify-content-center">
          <ExtendTimeReplyModal updateSessionDuration={handleSubmitExtendTime} handleChangeExtendMins={handleChangeExtendMins} duration={5} />

          {timerEnded ? (
            <div
              className={`px-3 pointer border_on_hover py-1 float-left`}
              style={{
                marginBottom: "0px",
                fontSize: "16px",
                color: "#E0E2E5",
                backgroundColor: "#303C54",
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: "4px",
              }}
              onClick={(e) => {
                updateBreakoutSessionStatus("2")
                setShowModal(false)
              }}
            >
              End
            </div>
          ) : (
            <div
              className={`px-3 pointer border_on_hover py-1 float-left`}
              style={{
                marginBottom: "0px",
                fontSize: "16px",
                color: "#E0E2E5",
                backgroundColor: "#303C54",
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: "4px",
              }}
              onClick={() => {
                setShowModal(false)
              }}
            >
              Ignore
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SuggestExtendTimeModal
