import React from "react"
import { Dropdown } from "react-bootstrap"
import Image from "../custom/Image"
import arrow from "../../images/svgs/down_arrow_white.svg"

import StylesA from "../../components/match-the-following/matching.module.scss"
import StylesBS from "./bs-viewdetail.module.scss"

const GroupSelectionModal = (props) => {
  const groupList = props?.activityWiseGroupsData
    ? props.activeResource?.resource_type === "matchthefollowing"
      ? props?.activityWiseGroupsData[`res_${props.activeResource?.id}`]
      : props?.activityWiseGroupsData[`res_${props.activeResource?.id}`]?.results
    : []
  return (
    <Dropdown className={`d-inline-flex align-items-center px-2 ${StylesBS.border_white} ${StylesBS.group_selection_dropdown} `}>
      <Dropdown.Toggle
        id="group-select-dropdown"
        size="lg"
        className={`d-inline-flex align-items-center caret-none w-100 px-0 ${StylesBS.dropdown_toggle} ${StylesBS.dropdown_bg}`}
      >
        <span className={`mb-0 pr-3 fs-18px text-color-1 ellipsis ${StylesBS.maxw_180px}`}>
          {props.activeGroup?.group_name || props.activeGroup?.title || "No Groups"}
        </span>
        <Image className={`ml-auto pointer icon}`} src={arrow} />
      </Dropdown.Toggle>
      {groupList?.length > 0 && (
        <Dropdown.Menu className={`ml-lg-n2 ${StylesA.category_dropdown_menu} ${StylesBS.group_dropdown_menu} `}>
          <div className={`d-flex ${StylesBS.dropdown_menu} ${StylesBS.border_white}`}>
            <div className={`${StylesBS.group_item_div} w-100`}>
              {groupList?.map((item, index) => {
                return (
                  <Dropdown.Item
                    key={`group_${item.group_id}`}
                    className={`pl-2 py-1 pr-3 pointer ${StylesA.resource_option_hover} ${StylesBS.group_item_ele}`}
                    onClick={(e) => {
                      e.preventDefault()
                      props.setActiveGroup(item)
                    }}
                  >
                    <div className="text-color-1 ellipsis">{item.group_name || item.title}</div>
                  </Dropdown.Item>
                )
              })}
            </div>
          </div>
        </Dropdown.Menu>
      )}
    </Dropdown>
  )
}
export default GroupSelectionModal
