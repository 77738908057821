import React, { useState, useContext, useEffect } from "react"
import _ from "lodash"

import { Col, Modal } from "react-bootstrap"
import Spinner from "react-bootstrap/Spinner"
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import InputGroup from "react-bootstrap/InputGroup"
import SunEditor from "suneditor-react"
import Image from "../../../custom/Image"
import { Droppable, Draggable } from "react-beautiful-dnd"
import { LazyLoadImage } from "react-lazy-load-image-component"

import { ClassroomContext } from "../../../../stores/classroom_store/ClassroomStore"
import {
  SET_ACTIVE_BREAKOUT_SESSION,
  SET_SHOW_BREAKOUT_SESSION_EDIT_DURATION_MODAL,
  SET_OPENED_OVERLAY_SECTION,
  SET_SHARED_RESOURCE_DRAG_ENDED,
  SET_SHOW_BREAKOUT_SESSION_EXTEND_DURATION_MODAL,
  SET_ACTIVE_ACTIVITY_DATA,
  SHOW_ACTIVE_ACTIVITY,
} from "../../../../stores/classroom_store/ClassroomActions"
import { GlobalContext } from "../../../../stores/global_store/GlobalStore"
import { SET_NOTIFICATION_DATA, SET_ACTIVE_NOTIFICATION_REPLY_DATA } from "../../../../stores/global_store/GlobalActions"
import { SET_SHOW_EXTEND_TIME_MODAL_FROM_NOTIFICATION, SET_SHOW_SEEK_REPLY_MODAL_FROM_NOTIFICATION } from "../../../../stores/classroom_store/ClassroomActions"
import { TrainingSocketContext } from "../../../../stores/socket/training_socket/trainingSocketProvider"
import { LessonboardContext } from "../../../../stores/classroom_store/lessonboard_store/LessonboardStore"
import { SET_TOPIC_DATA } from "../../../../stores/classroom_store/lessonboard_store/LessonboardActions"
import { deleteRequest, getRequest, postRequest, putRequest } from "../../../../services/rest_service"
import EndBreakoutModal from "../endBreakoutModal"
import utcDateTime from "../../../../services/utcdatetime"
import Btn from "../../../custom/Button"
import getImageToBeRendered from "../../../utilities/resourceIcons"
import Loader from "../../../custom/Loader"
import UploadFiles from "../../../courses/upload-files"
import { getResourceItemStyle } from "../../../template-resources/breakout-groups/dragAndDropStyle"
import ViewDetailsModal from "../../../breakout-session-details/breakout-session-view-detali"
import { BreakoutGroupContext } from "../../../../stores/socket/breakout_group_socket/breakoutGroupProvider"
import { ClassroomSidebarContext } from "../../../../stores/classroom_store/classroom_sidebar/classroomSidebarStore"
import {
  SET_CURRENT_NAV_TAB,
  SET_SELECTED_RESOURCE_TYPE,
  SET_ALL_RESOURCES,
  SET_DRAGGED_ITEM,
  SET_DISABLED_RESOURCES,
} from "../../../../stores/classroom_store/classroom_sidebar/classroomSidebarActions"
import { ResourceModelName } from "../../../live_class/classroom_sidebar/constants"
import BreakoutSessionIcon from "../../../../images/svgs/lesson_board_breakout_session_icon.svg"
import DeleteIcon from "../../../../images/svgs/delete.svg"
import CancelWhite from "../../../../images/svgs/close.svg"
import Minimize from "../../../../images/svgs/RectangleMinimize.svg"
import Maximize from "../../../../images/svgs/rectangleMaximize.svg"
import ExclaimationTriangleYellow from "../../../../images/svgs/exclamation-triangle-yellow.svg"
import DownArrow from "../../../../images/svgs/down_arrow_white.svg"
import Times from "../../../../images/svgs/times.svg"

import Style from "../../../class-room-training/lesson-board.module.scss"
import StylesA from "../../../template-resources/breakout-session.module.scss"
import StylesB from "../../../courses/course.module.scss"
import StylesM from "../../../../components/upload-resource.module.scss"
import Styles from "../../../../modularscss/styles.module.scss"
import StyleNew from "./style.module.scss"

import TimerComponent from "../../../custom/TimerComponent"
import SuggestExtendTimeModal from "../timeWarningModal"
import ExtendTimeModalFromNotification from "../extend_time_modal/index.js"
import SeekSupportReplyFromNotificationModal from "../seek_support_reply_modal/index.js"
import SeeMore from "../../../custom/see_more"

export function convertToTime(x) {
  let totalSec = x * 60
  let [min, sec] = [Math.floor(totalSec / 60), Math.round(totalSec % 60)]
  return min.toString() + ":" + sec.toString()
}

/**
 * Component for active breakout session view.
 * @returns {React.ReactElement} ActiveBreakoutSession component.
 */

export const ReportBox = (props) => {
  return (
    <div className={`d-flex text-center flex-column ml-3 ${Style.cs_ques_box}`} style={{ flex: "1" }}>
      {props.header && (
        <div className={`fs-14px py-1`} style={{ borderBottom: "1px solid #6b7894" }}>
          {props.header}
        </div>
      )}
      {props.children}
      {props.footer && (
        <div className={`fs-14px py-1`} style={{ borderTop: "1px solid #6b7894" }}>
          {props.footer}
        </div>
      )}
    </div>
  )
}

const ActiveBreakoutSession = () => {
  const [classroomState, dispatch] = useContext(ClassroomContext)
  const [globalState, globalDispatch] = useContext(GlobalContext)
  const [lessonboardState, lessonboardDispatch] = useContext(LessonboardContext)
  const [trainingWSready, trainingWSval, trainingWSsend] = useContext(TrainingSocketContext)
  const [breakoutWsReady, breakoutWsVal, breakoutWsSend] = useContext(BreakoutGroupContext)
  const [sidebarState, sidebarDispatch] = useContext(ClassroomSidebarContext)

  const [isMaximize, setIsMiximize] = useState(true)
  const [activeTab, setActiveTab] = useState("overall_progress")
  const [activeFooterTab, setActiveFooterTab] = useState(null)
  const [newDuration, setNewDuration] = useState(classroomState.activeBreakoutSession.duration)
  const [breakoutGroupsProgress, setBreakoutGroupsProgress] = useState([])
  const [isShareResActive, setIsShareResActive] = useState(false)
  const [showEndBreakoutSessionModal, setShowEndBreakoutSessionModal] = useState(false)
  const [showBREndWarningModal, setShowBREndWarningModal] = useState(false)
  const [breakoutActivities, setBreakoutActivities] = useState([])
  const [breakoutActivitiesNextUrl, setBreakoutActivitiesNextUrl] = useState(null)
  const [isBreakoutActivitiesFetched, setIsBreakoutActivitiesFetched] = useState(false)
  const [activeBreakoutActivity, setActiveBreakoutActivity] = useState(null)
  const [activityResourcesFetched, setActivityResourcesFetched] = useState(false)
  const [activeActivityResource, setActiveActivityResource] = useState(null)
  const [activityWiseProgressFetched, setActivityWiseProgressFetched] = useState(false)
  const [activityWiseBreakoutGroupsProgress, setActivityWiseBreakoutGroupsProgress] = useState("")
  const [showBreakoutActivities, setShowBreakoutActivities] = useState(false)
  const [breakoutSession, setBreakoutSession] = useState(classroomState.activeBreakoutSession)

  const [showInstructions, setShowInstructions] = useState(false)
  const [sharedResources, setSharedResources] = useState({})
  const [sharedResourcesNextUrl, setSharedResourcesNextUrl] = useState({})
  const [sharedResourcesCount, setSharedResourcesCount] = useState({})
  const [sessionLevelInstructions, setSessionLevelInstructions] = useState("")
  const [sessionInstructionsList, setSessionInstructionsList] = useState([])
  const [sessionInstructionsFetched, setSessionInstructionsFetched] = useState(false)
  const [sessionInstructionsCount, setSessionInstructionsCount] = useState(null)

  const [activityWiseCaseStudyGroups, setActivityWiseCaseStudyGroups] = useState({})
  const [activityWiseCaseStudyGroupsFetched, setActivityWiseCaseStudyGroupsFetched] = useState({})

  const [activityWiseQuizGroups, setActivityWiseQuizGroups] = useState({})
  const [activityWiseQuizGroupsFetched, setActivityWiseQuizGroupsFetched] = useState({})

  const [activityResources, setActivityResources] = useState({})
  const [activityResourcesNextUrl, setActivityResourcesNextUrl] = useState({})
  const [activityResourcesLoader, setActivityResourcesLoader] = useState(false)
  const [showActivityResources, setShowActivityResources] = useState(false)
  const [activityWiseChecklistGroups, setActivityWiseChecklistGroups] = useState({})
  const [activityWiseChecklistGroupsFetched, setActivityWiseChecklistGroupsFetched] = useState({})

  //states for match-the-folloings
  const [activityWiseMTFGroups, setActivityWiseMTFGroups] = useState({})
  const [activityWiseMTFGroupsFetched, setActivityWiseMTFGroupsFetched] = useState({})
  const [activeGroup, setActiveGroup] = useState()
  const [showViewDetailsModal, setShowViewDetailsModal] = useState(false)

  const [currentSharedResources, setCurrentSharedResources] = useState({})
  const [sharedResInstruction, setSharedResInstruction] = useState("")
  const [srResourcesFetched, setSrResourcesFetched] = useState({})
  const [extendDurationValue, setExtendDurationValue] = useState(30)
  const [showNegativeError, setShowNegativeError] = useState(false)

  const [files, setFiles] = useState([])
  const [uploadInTopic, setUploadInTopic] = useState(null)
  const [uploadDataSequence, setUploadDataSequence] = useState(0)
  const [highlight, setHighlight] = useState("unhighlight")
  const [showSection, setShowSection] = useState("video")

  const [showSuggestionModal, setShowSuggestionModal] = useState(false)
  const disabledResourceType = ["MTFs", "Quizzes", "Polls", "Checklists", "Case Studies", "Pop Quizzes", "Poll"]

  const [closeModal, setCloseModal] = useState(false)

  //Notification reply modals
  const activeNotificationReplyingData = globalState.activeNotificationReplyingData
  const setActiveNotificationReplyingData = (data) => {
    globalDispatch({ type: SET_ACTIVE_NOTIFICATION_REPLY_DATA, payload: data })
  }
  const showExtendTimeModalFromNotification = classroomState.showExtendTimeModalFormNotifcation
  const showSeekReplyModalFormNotification = classroomState.showSeekReplyModalFormNotification

  function updateNotificationReplyModalStates(type, data) {
    dispatch({ type: type, payload: data })
  }

  const grid = 8
  const session = classroomState.activeBreakoutSession
  const showBreakoutSessionEditDurationModal = classroomState.showBreakoutSessionEditDurationModal

  useEffect(() => {
    fetchBreakoutSessionProgress()
  }, [])

  useEffect(() => {
    if (trainingWSval?.event_type === "breakout_session_status" && trainingWSval?.data?.status == 2) {
      handleEndBreakoutSession()
    } else if (trainingWSval?.event_type === "extend_time" && activeFooterTab !== "extend_time") {
      trainingWSval?.total_duration && updateTotalBreakoutRoomTime(parseInt(trainingWSval?.total_duration))
    }
  }, [trainingWSval])

  useEffect(() => {
    if (classroomState.sharedResourceDragEnded) {
      setSrResourcesFetched(false)
      onSharedResourceDragEnd(sidebarState.draggedItem)
      sidebarDispatch({ type: SET_DRAGGED_ITEM, payload: "" })
      dispatch({ type: SET_SHARED_RESOURCE_DRAG_ENDED, payload: false })
    }
  }, [classroomState.sharedResourceDragEnded])

  useEffect(() => {
    if (classroomState.openedOverlaySection === "ActiveBreakoutSession") {
      setCloseModal(false)
    }
  }, [classroomState.openedOverlaySection])

  const handleEndBreakoutSession = () => {
    updateBreakoutSessionStatusInGlobalStore("2")
    setShowEndBreakoutSessionModal(false)
    dispatch({ type: SET_ACTIVE_ACTIVITY_DATA, payload: {} })
    sidebarDispatch({ type: SET_DISABLED_RESOURCES, payload: [] })
  }

  const handleToggleMaximize = () => {
    setIsMiximize((prev) => {
      if (!prev) {
        dispatch({ type: SET_OPENED_OVERLAY_SECTION, payload: "ActiveBreakoutSession" })
      }
      return !prev
    })
  }
  useEffect(() => {
    if (activeFooterTab === "share_resources") {
      if (!isMaximize || (session && classroomState.openedOverlaySection === null)) {
        sidebarDispatch({ type: SET_DISABLED_RESOURCES, payload: [] })
      } else {
        closeSidebar()
        sidebarDispatch({ type: SET_DISABLED_RESOURCES, payload: disabledResourceType })
      }
    } else {
      sidebarDispatch({ type: SET_DISABLED_RESOURCES, payload: [] })
    }
  }, [isMaximize, activeFooterTab, classroomState.openedOverlaySection])

  const closeSidebar = () => {
    if (sidebarState.currentNavTab) {
      sidebarDispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
      sidebarDispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: "" })
    }
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  function onSharedResourceDragEnd(result) {
    if (!result.destination) {
      return
    }
    const source = result.source
    const destination = result.destination
    if (destination.droppableId === "resource_video") {
      return null
    } else {
      let obj
      //If these resources are dropped, dont accept it.
      switch (source.droppableId) {
        case "resource_Quizzes":
        case "resource_Polls":
        case "resource_Pop Quizzes":
        case "resource_Case Studies":
        case "resource_Checklists":
        case "resource_MTFs":
          return
        default:
          break
      }
      if (currentSharedResources && currentSharedResources[destination.droppableId] && currentSharedResources[destination.droppableId]?.resources) {
        obj = currentSharedResources[destination.droppableId]?.resources
      } else {
        return
      }
      if (source.droppableId === destination.droppableId) {
        const items = reorder(obj, source.index, destination.index)
        let currentResource = items[destination.index]
        let currentData = obj
        if (destination.index === 0) {
          currentResource.sequence = (parseFloat(currentData[0].sequence) - 10).toFixed(20)
        } else if (destination.index === currentData.length - 1) {
          currentResource.sequence = (parseFloat(currentData[currentData.length - 1].sequence) + 10).toFixed(20)
        } else {
          if (source.index > destination.index) {
            currentResource.sequence = (
              (parseFloat(currentData[destination.index - 1].sequence) + parseFloat(currentData[destination.index].sequence)) /
              2
            ).toFixed(20)
          } else if (source.index < destination.index) {
            currentResource.sequence = (
              (parseFloat(currentData[destination.index].sequence) + parseFloat(currentData[destination.index + 1].sequence)) /
              2
            ).toFixed(20)
          }
        }
        handleSaveResource(currentResource, "resources")
        currentSharedResources[destination.droppableId].resources = items
      } else {
        createResource(source, destination)
      }
    }
  }

  const handleSaveResource = async (resource, type) => {
    setSrResourcesFetched(false)
    var formData = new FormData()
    formData.append("sequence", resource.sequence)
    formData.append("resource_id", resource.resource_id)
    formData.append("resource_type", resource.resource_type)

    let response = await putRequest("/resources/create/", formData)
    if (response.success) {
      setSrResourcesFetched(true)
    }
  }

  const createResource = async (source, destination) => {
    const itemSubItemMap = { ...sidebarState.allResources }

    // let newItems = props.allResources[sidebarState.currentSelectedResourcesType]

    let newSourceSubItems = itemSubItemMap[sidebarState.currentSelectedResourcesType]

    const [draggedItem] = newSourceSubItems.splice(source.index, 1)

    let newDestSubItems = []
    var formData = new FormData()
    newDestSubItems = currentSharedResources[destination.droppableId].resources
    formData.append("add_to_instruction", currentSharedResources[`active_${session.id}`].id)

    let destSubItems = newDestSubItems

    if (destination.index === 0 && destSubItems.length === 0) {
      draggedItem.sequence = destination.index
    } else if (destination.index === 0) {
      if (draggedItem) {
        draggedItem.sequence = (parseFloat(destSubItems[destination.index].sequence) - 10).toFixed(20)
      }
    } else if (destination.index === destSubItems.length) {
      draggedItem.sequence = (parseFloat(destSubItems[destination.index - 1].sequence) + 10).toFixed(20)
    } else {
      draggedItem.sequence = (
        (parseFloat(destSubItems[destination.index].sequence) + parseFloat(destSubItems[parseFloat(destination.index) - 1].sequence)) /
        2
      ).toFixed(20)
    }

    formData.append("sequence", draggedItem.sequence)
    formData.append("resource_type", ResourceModelName[sidebarState.currentSelectedResourcesType])
    formData.append("resource_id", draggedItem.id)

    let response = await postRequest("/resources/create/", formData)
    if (response.success) {
      draggedItem.id = response.data.id
      draggedItem.resource_data = response.data.resource_data
      draggedItem.resource_id = response.data.resource_id
      draggedItem.resource_type = response.data.resource_type
      draggedItem.sequence = response.data.sequence
      delete draggedItem["name"]
      setSrResourcesFetched(true)
    }

    newDestSubItems.splice(destination.index, 0, draggedItem)
    sidebarDispatch({ type: SET_ALL_RESOURCES, payload: itemSubItemMap })
  }

  useEffect(() => {
    if (breakoutWsVal && Object.keys(breakoutWsVal).length > 0 && classroomState.breakoutGroupsIds?.includes(parseInt(breakoutWsVal.group_id))) {
      if (breakoutWsVal.event_type === "update_group_progress") {
        // Update current breakout session group progress from websocket
        let current_progress = [...breakoutGroupsProgress]
        let group_index = current_progress.findIndex((group) => group.id === breakoutWsVal.group_id)
        let group = current_progress[group_index]
        if (group) {
          group.breakout_session_report.completed_resources = group.breakout_session_report.completed_resources + 1
          current_progress[group_index] = group
          setBreakoutGroupsProgress(current_progress)
        }
      } else if (breakoutWsVal?.action === "report_card_updated") {
        if (breakoutWsVal?.source === "quiz") {
          //update group progress here
          let key = `res_${breakoutWsVal?.resource}`
          let data = { ...activityWiseQuizGroups }
          if (data[key]) {
            let index = data[key]?.results.findIndex((res) => res.group_id === breakoutWsVal?.group_id)
            if (index !== -1) {
              data[key].results[index].finished_percentage = breakoutWsVal?.progress_percentage
              setActivityWiseQuizGroups(data)
            }
          }
        } else if (breakoutWsVal?.source === "case_study") {
          //update group progress here
          let key = `res_${breakoutWsVal?.resource}`
          let data = { ...activityWiseCaseStudyGroups }
          if (data[key]) {
            let index = data[key]?.results.findIndex((res) => res.group_id === breakoutWsVal?.group_id)
            if (index !== -1) {
              data[key].results[index].finished_percentage = breakoutWsVal?.progress_percentage
              setActivityWiseCaseStudyGroups(data)
            }
          }
        }
      } else if (breakoutWsVal?.action === "update_answer") {
        if (breakoutWsVal?.source === "checklist") {
          //update group progress here
          let key = `res_${breakoutWsVal?.resource}`
          let data = { ...activityWiseChecklistGroups }
          if (data[key]) {
            let index = data[key]?.results.findIndex((res) => res.group_id === breakoutWsVal?.group_id)
            if (index !== -1) {
              data[key].results[index].finished_percentage = breakoutWsVal?.progress_percentage
              setActivityWiseChecklistGroups(data)
            }
          }
        }
      } else if (breakoutWsVal?.action === "report_card_created_for_group") {
        if (breakoutWsVal?.source === "case_study") {
          let key = `res_${breakoutWsVal?.resource}`
          let data = { ...activityWiseCaseStudyGroups }
          if (data[key]) {
            let index = data[key]?.results.findIndex((res) => res.group_id === breakoutWsVal?.group_id)
            if (index !== -1) {
              data[key].results[index].report_card = breakoutWsVal?.report_card_id
              setActivityWiseCaseStudyGroups(data)
            }
          }
        } else if (breakoutWsVal?.source === "quiz") {
          let key = `res_${breakoutWsVal?.resource}`
          let data = { ...activityWiseQuizGroups }
          if (data[key]) {
            let index = data[key]?.results.findIndex((res) => res.group_id === breakoutWsVal?.group_id)
            if (index !== -1) {
              data[key].results[index].report_card = breakoutWsVal?.report_card_id
              setActivityWiseQuizGroups(data)
            }
          }
        } else if (breakoutWsVal?.event_type === "match_the_following") {
          let data = { ...activityWiseMTFGroups }
          let targetData = data[`res_${breakoutWsVal?.data?.resource_id}`]
          if (targetData) {
            let index = targetData.findIndex((group) => group?.group_id == breakoutWsVal?.group_id)
            if (targetData[index]) {
              targetData[index].report_card = breakoutWsVal?.data?.report_card
              targetData[index].group_status = breakoutWsVal?.data?.group_status
              setActivityWiseMTFGroups(data)
            }
          }
        }
      } else if (breakoutWsVal?.event_type === "match_the_following") {
        if (breakoutWsVal?.action === "answer_created" || breakoutWsVal?.action === "answer_updated" || breakoutWsVal?.action === "answer_deleted") {
          let key = `res_${breakoutWsVal?.data?.instance?.resource}`
          let data = { ...activityWiseMTFGroups }
          if (data[key]) {
            let index = data[key]?.findIndex((res) => res.group_id === breakoutWsVal?.group_id)
            if (index !== -1) {
              data[key][index].finished_percentage = breakoutWsVal?.data?.instance?.progress_percentage
              setActivityWiseMTFGroups(data)
            }
          }
        }
      } else if (breakoutWsVal?.action === "submitted") {
        if (breakoutWsVal?.event_type === "match_the_following") {
          let data = { ...activityWiseMTFGroups }
          let targetData = data[`res_${breakoutWsVal?.data?.instance?.resource_id}`]
          if (targetData) {
            let index = targetData.findIndex((group) => group?.group_id == breakoutWsVal?.group_id)
            if (targetData[index]) {
              targetData[index].group_status = "2"
              setActivityWiseMTFGroups(data)
            }
          }
          if (breakoutWsVal?.group_id == activeGroup?.group_id) {
            let activeData = { ...activeGroup }
            activeData.group_status = "2"
            setActiveGroup(activeData)
          }
        }
      }
    }
  }, [breakoutWsVal])

  useEffect(() => {
    if (trainingWSval.action === "fetch_leader_board") {
      if (trainingWSval.source === "group_checklist") {
        let key = `res_${trainingWSval?.resource_id}`
        let data = { ...activityWiseChecklistGroups }
        if (data[key]) {
          let index = data[key]?.results.findIndex((res) => res.group_id == trainingWSval?.group_id)
          if (index !== -1) {
            data[key].results[index].report_card = trainingWSval?.report_card_id
            setActivityWiseChecklistGroups(data)
          }
        }
      }
    }
  }, [trainingWSval])

  useEffect(() => {
    if (currentSharedResources[`active_${session?.id}`]?.resources.length > 0) {
      setIsShareResActive(true)
    } else {
      setIsShareResActive(false)
    }
  }, [currentSharedResources[`active_${session?.id}`]?.resources.length])

  useEffect(() => {
    if (activeActivityResource && activeActivityResource.resource_type === "casestudy") {
      let key = `res_${activeActivityResource?.id}`
      let index = activityWiseCaseStudyGroups[key]?.results.findIndex((value) => {
        return value.group_id === activeGroup?.group_id
      })
      activityWiseCaseStudyGroups[key] && setActiveGroup(activityWiseCaseStudyGroups[key]?.results[index])

      if (!activityWiseCaseStudyGroupsFetched[key]) {
        fetchActivityWiseCaseStudyGroupList()
      }
    } else if (activeActivityResource && activeActivityResource.resource_type === "checklist") {
      //fetch checklist  group list
      let key = `res_${activeActivityResource?.id}`
      let index = activityWiseChecklistGroups[key]?.results.findIndex((value) => {
        return value.group_id === activeGroup?.group_id
      })
      setActiveGroup(activityWiseChecklistGroups[key]?.results[index])
      if (!activityWiseChecklistGroupsFetched[key]) {
        fetchActivityWiseChecklistGroupList()
      }
    } else if (activeActivityResource && activeActivityResource.resource_type === "quiz") {
      let key = `res_${activeActivityResource?.id}`
      let index = activityWiseQuizGroups[key]?.results.findIndex((value) => {
        return value.group_id === activeGroup?.group_id
      })
      if (activityWiseQuizGroups[key]) {
        setActiveGroup(activityWiseQuizGroups[key]?.results[index])
      }
      if (!activityWiseQuizGroupsFetched[key]) {
        fetchActivityWiseQuizGroupList()
      }
    } else if (activeActivityResource && activeActivityResource.resource_type === "matchthefollowing") {
      let key = `res_${activeActivityResource?.id}`
      if (!activityWiseMTFGroupsFetched[key]) {
        fetchActivityWiseMTFGroupList()
      } else {
        if (activeGroup) {
          let index = activityWiseMTFGroups[key].findIndex((d) => d.group_id == activeGroup?.group_id)
          activityWiseMTFGroups[key][index] && setActiveGroup(activityWiseMTFGroups[key][index])
        }
      }
    }
  }, [activeActivityResource])

  const fetchActivityWiseCaseStudyGroupList = async () => {
    let response = await getRequest(
      `/case-study-group-report-card/${session?.id}/groups_list/?breakout_activity=${activeBreakoutActivity.id}&resource=${activeActivityResource?.id}&limit=10`
    )
    if (response.success) {
      let data = { ...activityWiseCaseStudyGroups }
      let fetchData = { ...activityWiseCaseStudyGroupsFetched }
      let key = `res_${activeActivityResource?.id}`
      data[key] = response.data
      fetchData[key] = true
      setActivityWiseCaseStudyGroups(data)
      setActivityWiseCaseStudyGroupsFetched(fetchData)

      if (activeGroup) {
        let index = response.data?.results?.findIndex((d) => d.group_id == activeGroup.group_id)
        setActiveGroup(response?.data?.results[index] || response?.data?.results?.[0])
      }
    }
  }

  const fetchActivityWiseChecklistGroupList = async () => {
    let response = await getRequest(
      `/checklist/group/report_card/list_report_cards/?resource_id=${activeActivityResource?.id}&breakout_activity=${activeBreakoutActivity.id}&limit=10`
    )
    if (response.success) {
      let data = { ...activityWiseChecklistGroups }
      let fetchData = { ...activityWiseChecklistGroupsFetched }
      let key = `res_${activeActivityResource?.id}`
      data[key] = response.data
      fetchData[key] = true
      setActivityWiseChecklistGroups(data)
      setActivityWiseChecklistGroupsFetched(fetchData)
      if (activeGroup) {
        let index = response.data?.results?.findIndex((d) => d.group_id == activeGroup.group_id)
        setActiveGroup(response?.data?.results[index] || response?.data?.results?.[0])
      }
    }
  }

  const fetchActivityWiseMTFGroupList = async () => {
    let response = await getRequest(
      `/match-the-followings/reportcard/breakout_group_reports/?breakout_activity=${activeBreakoutActivity?.id}&resource=${activeActivityResource?.id}`
    )
    if (response.success) {
      let data = { ...activityWiseMTFGroups }
      let fetchData = { ...activityWiseMTFGroupsFetched }
      let key = `res_${activeActivityResource?.id}`
      data[key] = response.data
      fetchData[key] = true
      setActivityWiseMTFGroups(data)
      setActivityWiseMTFGroupsFetched(fetchData)
      if (activeGroup) {
        let index = response.data.findIndex((d) => d.group_id == activeGroup.group_id)
        if (response?.data?.[index]) {
          setActiveGroup(response?.data?.[index])
        }
      }
    }
  }

  const fetchActivityWiseQuizGroupList = async () => {
    let response = await getRequest(
      `/quiz-group-report-card/${session?.id}/groups_list/?breakout_activity=${activeBreakoutActivity.id}&resource=${activeActivityResource?.id}&limit=10`
    )
    if (response.success) {
      let data = { ...activityWiseQuizGroups }
      let fetchData = { ...activityWiseQuizGroupsFetched }
      let key = `res_${activeActivityResource?.id}`
      data[key] = response.data
      fetchData[key] = true
      setActivityWiseQuizGroups(data)
      setActivityWiseQuizGroupsFetched(fetchData)

      if (activeGroup) {
        let index = response.data?.results?.findIndex((d) => d.group_id == activeGroup.group_id)
        setActiveGroup(response?.data?.results[index] || response?.data?.results?.[0])
      }
    }
  }

  useEffect(() => {
    if (activeBreakoutActivity) {
      setActivityWiseProgressFetched(false)
      fetchActivityWiseProgressData()
      let key = `act_${activeBreakoutActivity?.id}`
      if (activityResources && activityResources[key] === undefined) {
        fetchActivityWiseResources()
      } else if (activityResources && activityResources[key] && activityResources[key].length > 0) {
        setActiveActivityResource(activityResources[key][0])
      }
    }
  }, [activeBreakoutActivity])

  useEffect(() => {
    if (showViewDetailsModal) {
      fetchActivityWiseResources(false)
    }
  }, [showViewDetailsModal])

  const fetchActivityWiseResources = async (resetActiveActivityResource = true) => {
    let response = await getRequest(`/resources-names-list/?breakout_activity__id=${activeBreakoutActivity?.id}&paginate_by=10&is_dropdown=true`)
    if (response.success) {
      let key = `act_${activeBreakoutActivity?.id}`
      let actRes = { ...activityResources }
      let actResNextUrl = { ...activityResourcesNextUrl }
      actRes[key] = response.data.results
      actResNextUrl[key] = response.data.next
      setActivityResourcesFetched(true)
      setActivityResources(actRes)
      setActivityResourcesNextUrl(actResNextUrl)
      if (response.data.results.length > 0) {
        resetActiveActivityResource && setActiveActivityResource(response.data.results[0])
      } else {
        setActiveActivityResource(null)
      }
    }
  }

  const deleteResource = async (id) => {
    let response = await deleteRequest(`/resources/${id}/`)
    if (response.success) {
      setSrResourcesFetched(true)
    }
  }

  const handleActivitySeeMoreResponse = (response) => {
    let data = [...breakoutActivities, ...response.data?.results]
    setBreakoutActivities(data)
    setBreakoutActivitiesNextUrl(response.data.next)
  }

  const fetchActivityWiseProgressData = async () => {
    let response = await getRequest(`/breakoutgroups-activity-progress/${activeBreakoutActivity.id}/`)
    if (response.success) {
      setActivityWiseBreakoutGroupsProgress(response.data.results)
      setActivityWiseProgressFetched(true)
    }
  }

  const fetchInstructionResources = async (id) => {
    let response = await getRequest(`/basic-resources-list/?instruction=${currentSharedResources[`active_${session.id}`].id}`)
    if (response.success) {
      let current_data = { ...currentSharedResources }
      current_data[`active_` + session.id]["resources"] = response.data.results
      setCurrentSharedResources(current_data)
    }
  }

  useEffect(() => {
    if (activeTab === "shared_items") {
      fetchSessionInstructions()
      if (sharedResources && sharedResources[`as_${session?.id}`] === undefined) {
        fetchSharedResources()
      }
    } else if (activeTab === "activity_wise_progress") {
      fetchBreakoutActivities()
    }
  }, [activeTab])

  const fetchBreakoutSessionProgress = async () => {
    let response = await getRequest(`/breakoutgroups-progress/${session.id}/?training=${classroomState.training_id}&paginate_by=30`)
    if (response.success) {
      setBreakoutGroupsProgress(response.data.results)
    }
  }

  function handleActivityResourcesScroll(e) {
    e.stopPropagation()
    if (
      e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 5 &&
      activityResourcesNextUrl[`act_${activeBreakoutActivity?.id}`] != null &&
      !activityResourcesLoader
    ) {
      fetchNextActivityWiseResources(activityResourcesNextUrl[`act_${activeBreakoutActivity?.id}`])
    }
  }

  const fetchNextActivityWiseResources = async (next_url) => {
    setActivityResourcesLoader(true)
    let response = await getRequest(next_url)
    if (response.success) {
      let key = `act_${activeBreakoutActivity?.id}`
      let actRes = { ...activityResources }
      let actResNextUrl = { ...activityResourcesNextUrl }
      actResNextUrl[key] = response.data.next
      setActivityResourcesNextUrl(actResNextUrl)
      let currentActivityData = actRes[key]
      currentActivityData = [...currentActivityData, ...response.data.results]
      actRes[key] = currentActivityData
      setActivityResources(actRes)
      setActivityResourcesLoader(false)
    }
  }

  const fetchBreakoutActivities = async () => {
    let response = await getRequest(`/breakout-activities/?breakout_session=${session.id}&paginate_by=15`)
    if (response.success) {
      let data = response.data
      setBreakoutActivities(data.results)
      setBreakoutActivitiesNextUrl(data.next)
      setActiveBreakoutActivity(data.results[0])
      setIsBreakoutActivitiesFetched(true)
    }
  }

  const handleStartBreakoutSession = () => {
    if (newDuration > 0) {
      updateBreakoutSessionStatus("1")
    } else {
      globalDispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: "Please enter valid duration" } })
    }
  }

  const updateBreakoutSessionStatusInGlobalStore = (session_status, startTime = null) => {
    if (classroomState.activeSession) {
      let ongoing_topics = { ...lessonboardState.topics["module_Doing"] }
      let current_topic_index = ongoing_topics?.topics?.findIndex((topic) => topic.id === session.topic)
      if (ongoing_topics.topics[current_topic_index]) {
        let current_topic = ongoing_topics.topics[current_topic_index]
        if (current_topic) {
          let current_breakout = current_topic.breakout_sessions.filter((breakout_session) => breakout_session.id === session.id)
          if (current_breakout) {
            current_breakout[0].status = session_status
            if (startTime) {
              current_breakout[0].start_time = startTime
            }
            lessonboardDispatch({ type: SET_TOPIC_DATA, payload: { key: "module_Doing", value: ongoing_topics } })
          }
        }
      }
    }
  }

  const updateBreakoutSessionStatus = async (session_status) => {
    try {
      let formData = new FormData()
      if (session_status === "1") {
        formData.append("duration", newDuration)
      }
      let response = await postRequest(`/update-breakout-session-status/${classroomState.training_id}/${session.id}/${session_status}/`, formData)
      if (response.success) {
        // Breakout session won't start if there are no breakout rooms available or all students are not assigned to groups.
        if (response.data?.error && response.data?.error !== undefined) {
          globalDispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: response.data["error"] } })
          if (response.data["error"] === "No breakout rooms") {
            // To open breakout groups modal.
            dispatch({ type: SET_OPENED_OVERLAY_SECTION, payload: "BreakoutGroup" })
          }
        } else {
          dispatch({ type: SET_ACTIVE_BREAKOUT_SESSION, payload: response.data })
          setBreakoutSession(response.data)

          // Update the session status in lessonboard topic.
          updateBreakoutSessionStatusInGlobalStore(session_status, response?.data?.start_time)

          let currActiveActivity
          if (session_status === "1") {
            currActiveActivity = { current_active_activity: response.data, active_breakout_session: true }
            dispatch({ type: SET_SHOW_BREAKOUT_SESSION_EDIT_DURATION_MODAL, payload: false })
            globalDispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "success", title: "Session Started Sucessfully" } })
            fetchBreakoutSessionProgress()
          } else {
            currActiveActivity = {}
            globalDispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "success", title: "Ended Session Sucessfully" } })
          }
          dispatch({ type: SET_ACTIVE_ACTIVITY_DATA, payload: currActiveActivity })
          trainingWSsend(
            JSON.stringify({
              event_type: "breakout_session_status",
              data: {
                id: response?.data?.id,
                status: response?.data?.status,
                duration: response?.data?.duration,
                name: response?.data?.name,
                activity_count: response?.data?.activity_count,
                start_time: response?.data?.start_time,
                total_duration: response?.data?.total_duration,
              },
            })
          )
        }
      } else {
        let currActiveActivity = response.data?.current_active_activity
        if (currActiveActivity && currActiveActivity?.id) {
          if (
            classroomState.activeActivityData?.current_active_activity?.resource ||
            classroomState.activeActivityData?.current_active_activity?.triggered_data?.id
          ) {
            currActiveActivity = classroomState.activeActivityData?.current_active_activity
          }
          dispatch({
            type: SET_ACTIVE_ACTIVITY_DATA,
            payload: { current_active_activity: currActiveActivity, active_breakout_session: response.data.active_breakout_session },
          })
          dispatch({ type: SHOW_ACTIVE_ACTIVITY, payload: true })
        } else {
          globalDispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: response?.data?.error ?? "Something went wrong" } })
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  function onDragEnter(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("highlight")
  }

  function onDragOver(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("highlight")
  }

  function onDragLeave(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("unhighlight")
  }

  function onDrop(event, id = 0, seq = 0) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("unhighlight")
    handleDrop(event)
    setShowSection("video")
    if (id > 0) setUploadInTopic(id)
    if (seq > 0) setUploadDataSequence(seq)
  }

  function handleDrop(e) {
    var dt = e.dataTransfer
    let new_files = dt.files
    let filex = []
    for (let i = 0; i < new_files.length; i++) {
      if (parseInt(new_files[i].size) < 200000000) {
        filex.push(new_files[i])
        setFiles([...files, ...filex])
      } else {
        globalDispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: `${new_files[i].name} has size more than 200MB.` } })
      }
    }
  }

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#000" : "#303C54",
    border: isDraggingOver ? "1px dotted white" : "0px solid white",
    padding: grid,
  })

  function validateAndAdd(event) {
    let new_files = event.target.files
    let filex = []
    for (let i = 0; i < new_files.length; i++) {
      if (parseInt(new_files[i].size) < 200000000) {
        filex.push(new_files[i])
        setFiles([...files, ...filex])
      } else {
        globalDispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: `${new_files[i].name} has size more than 200MB.` } })
      }
    }
  }

  function displayBreakoutSessionStart() {
    let launchOrRelaunchName = session.status === "2" ? "re-launch" : "launch"
    let cssClass = Style.breakout_session_detail_closed1

    return (
      <div className={`${cssClass}`}>
        <div
          className={`${Style.breakout_modal} pl-4 pr-1 pt-2 pb-3`}
          style={{
            borderRadius: "8px",
            maxHeight: "90vh",
            width: "27vw",
            overflowY: "auto",
            border: "1px solid #929BBD",
          }}
        >
          <div className={""}>
            <div className={`d-flex justify-content-end mb-1`}>
              <Image
                src={CancelWhite}
                alt="close"
                className="w-18px pointer"
                onClick={() => {
                  setCloseModal(true)
                  dispatch({ type: SET_ACTIVE_BREAKOUT_SESSION, payload: null })
                  dispatch({ type: SET_OPENED_OVERLAY_SECTION, payload: null })
                  dispatch({ type: SET_SHOW_BREAKOUT_SESSION_EDIT_DURATION_MODAL, payload: false })
                }}
              />
            </div>
            <div className={``} style={{ fontSize: 14 }}>
              <div className={`d-flex w-100 justify-content-center`}>
                <p style={{ fontSize: 16, fontWeight: "bold" }}>{session.name}</p>
              </div>
              <div className="d-flex w-100 justify-content-center">
                <span>
                  You are going to {launchOrRelaunchName} {session.name}.
                </span>
              </div>
              <div className="d-flex w-100 justify-content-center">
                <span>You can edit the allotted time if required</span>
              </div>
              <div className="d-flex justify-content-center mt-2">
                <div
                  className={`d-flex justify-content-between`}
                  style={{ backgroundColor: "#212C42", border: "1px solid #E0E2E565", borderRadius: 9, width: "70%" }}
                >
                  <span className="py-3 px-2" style={{ width: "50%", color: "#E0E2E5" }}>
                    Allotted Time:
                  </span>
                  <span
                    className="d-flex-inline py-3 px-2"
                    style={{ width: "50%", backgroundColor: "#42506C", color: "#E0E2E5", borderLeft: "1px solid #E0E2E565" }}
                  >
                    <input
                      autoFocus
                      defaultValue={newDuration ?? 30}
                      onChange={(e) => {
                        setNewDuration(e.target.value)
                      }}
                      id="allottedTime"
                      size={4}
                      min={1}
                      max={100000}
                      style={{ backgroundColor: "transparent", color: "#fff", padding: "0px 0px 0px 5px", border: 0 }}
                    />
                    <span>Mins</span>
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <Btn
                  style={{ backgroundColor: "#586886" }}
                  type="medium"
                  className={`mt-3 px-5`}
                  onClick={(e) => {
                    handleStartBreakoutSession()
                  }}
                >
                  Start
                </Btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const createSharedInstruction = async (type) => {
    if (type === "share_resources" && currentSharedResources[`active_${session.id}`] === undefined) {
      let formData = new FormData()
      formData.append("training", classroomState.training_id)
      formData.append("published", false)
      formData.append("object_id", session.id)
      formData.append("resource_type", "breakoutsession")
      formData.append("instruction_type", "1")
      let response = await postRequest(`/instructions/create/`, formData)
      if (response.success) {
        let current_data = currentSharedResources ? { ...currentSharedResources } : {}
        current_data[`active_` + session.id] = response.data
        current_data[`active_` + session.id]["resources"] = []
        setCurrentSharedResources(current_data)
      }
    } else if (currentSharedResources[`active_${session.id}`]["resources"].length > 0) {
      handleUpdateInstruction()
    }
    setActiveFooterTab(`share_resources`)
    if (type === "share_resources") {
      sidebarDispatch({ type: SET_CURRENT_NAV_TAB, payload: "Resource" })
      sidebarDispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: "Videos" })
    }
  }

  const handleUpdateInstruction = async () => {
    var formData = new FormData()
    // add your instruction title here in form data
    formData.append("training", classroomState.training_id)
    formData.append("published", true)
    formData.append("object_id", session.id)
    formData.append("title", sharedResInstruction)
    formData.append("resource_type", "breakoutsession")
    formData.append("instruction_type", "1")
    let response = await putRequest(`/instructions/${currentSharedResources[`active_${session.id}`].id}/`, formData)
    if (response.success) {
      let current_data = { ...currentSharedResources }
      delete current_data[`active_` + session.id]
      setCurrentSharedResources(current_data)
      setActiveFooterTab(null)
      setSharedResInstruction("")
      fetchSharedResources()
    }
  }

  function getFooterData() {
    if (activeFooterTab === "extend_time") {
      return (
        <div className={`w-100 mb-5 d-inline-flex align-items-center`}>
          <div className={`pr-3`}>
            <p className={`mb-0 poppins_font`} style={{ fontSize: "16px", fontWeight: "500" }}>
              Extend Session by
            </p>
            <div className={`d-inline-flex align-items-center`}>
              <InputGroup
                className="py-1 align-items-center"
                style={{
                  borderRadius: "9px",
                  width: "270px",
                  backgroundColor: "#303C54",
                  border: "1px solid transparent",
                }}
              >
                <input
                  className={`d-flex border-0 justify-content-center shadow-none pr-1 text-white`}
                  style={{
                    maxWidth: "200px",
                    backgroundColor: "#303C54",
                    fontSize: "16px",
                  }}
                  type="number"
                  name="time"
                  id="time"
                  min="1"
                  defaultValue={extendDurationValue}
                  onChange={(e) => {
                    let val = handleChangeExtendMins(e)
                    setExtendDurationValue(val)
                    setShowNegativeError(false)
                  }}
                  placeholder="Provide custom timings"
                  required
                />
                <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54" }}>
                  <InputGroup.Text
                    className="border-0 d-flex justify-content-center"
                    style={{
                      backgroundColor: "#303C54",
                      color: "#E0E2E5",
                      borderRadius: "0px 9px 9px 0px",
                      fontSize: "16px",
                    }}
                    id="basic-addon1"
                  >
                    Mins
                  </InputGroup.Text>
                </InputGroup.Prepend>
              </InputGroup>
            </div>
            {showNegativeError ? (
              <div className="d-inline-flex px-3 py-0" style={{ borderRadius: "23px" }}>
                <Image src={ExclaimationTriangleYellow} className="mt-1 mr-1 h-13px" alt="exclaimation-icon" />
                <p className="mb-0 color-F4F411 fs-14px">Enter valid time (Positive)</p>
              </div>
            ) : null}
          </div>
        </div>
      )
    } else if (activeFooterTab === "send_instructions") {
      return (
        <div className={`w-100 mb-3 d-inline-flex align-items-center`}>
          <SunEditor
            id="instructions"
            placeholder="Add Instructions"
            onChange={setSessionLevelInstructions}
            setContents={sessionLevelInstructions}
            setOptions={{
              height: "auto",
              maxHeight: "200px",
              minHeight: "200px",
              backgroundColor: "#212C42",
              buttonList: [["align", "list", "font", "fontSize", "bold", "underline", "italic", "link"]],
            }}
          />
        </div>
      )
    } else if (activeFooterTab === "share_resources" && currentSharedResources[`active_${session.id}`]) {
      let data = currentSharedResources[`active_${session.id}`].resources
      return (
        <div className={`w-100 mb-3`}>
          <Droppable droppableId={`active_${session.id}`} type={`resourceDraggable`}>
            {(provided, snapshot) => (
              <div
                id={`drop-area`}
                onDragEnter={onDragEnter}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                className={`bg-transparent px-4`}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {currentSharedResources[`active_${session.id}`].resources.map((item, index) => {
                  let { typeId, imgObj, imgType } = getImageToBeRendered(item)
                  return (
                    <Draggable
                      key={item.id + `_name_` + index}
                      draggableId={item.id.toString() + "_" + index.toString()}
                      index={index}
                      className={item.resource_data !== null ? "" : "d-none"}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={item.resource_data !== null ? "resource-item" : "d-none"}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getResourceItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <div
                            className={`pr-2 d-flex`}
                            style={{
                              color: `#E0E2E5`,
                              background: "#4F6080",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className="d-flex align-items-center">
                              {!item?.resource_type && <Loader size="lg" />}
                              {item.resource_data ? (
                                <LazyLoadImage
                                  className={`my-auto`}
                                  src={item.resource_data.thumbnail_url || imgObj}
                                  alt={item.name}
                                  width="38px"
                                  height="30px"
                                />
                              ) : (
                                ``
                              )}
                              <p
                                style={{
                                  fontSize: "14px",
                                  margin: "0px 0px 0px 10px",
                                }}
                              >
                                {item.resource_data?.display_name ?? item.resource_data?.name ?? item.display_name ?? item.name}
                              </p>
                            </div>
                            <div>
                              <img
                                src={DeleteIcon}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  const newState = data
                                  newState.splice(index, 1)
                                  setSrResourcesFetched(false)
                                  currentSharedResources[`active_${session.id}`].resources = newState
                                  deleteResource(item.id)
                                }}
                                alt={"resource delete icon"}
                                className={`my-auto pointer`}
                                style={{ objectFit: `contain`, height: `13px` }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
                <React.Fragment>
                  <div className={`justify-content-center pt-4 pb-3 d-flex bg-transparent`}>
                    <div className={`${Styles.drop_area} m-0 px-0 py-0 bg-transparent  text-center ${files.length === 0 ? `` : `border-0`}`}>
                      <div className={`w-100 col-12`}>
                        <Form className="my-form mb-0">
                          <React.Fragment>
                            <p className={`${StylesM.font_14} mb-2`}>
                              Drag and drop media/document files to add content /&nbsp;
                              <label
                                className={` btn border-0 px-0 ${StylesM.font_14}`}
                                style={{
                                  color: "#E0E2E5",
                                  textDecoration: "underline",
                                }}
                                htmlFor="fileElem"
                              >
                                Browse
                              </label>
                            </p>
                          </React.Fragment>
                          <input
                            className={`d-none`}
                            type="file"
                            id="fileElem"
                            multiple
                            accept="image/*,application/pdf,video/*,.doc,.docx,.ppt,.pptx,.xlsx,.xls"
                            onChange={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              validateAndAdd(e)
                            }}
                          />
                        </Form>
                      </div>
                      <UploadFiles
                        sequence={data.length === 0 ? 0 : parseFloat(data[data.length - 1].sequence).toFixed(2)}
                        topic={currentSharedResources[`active_${session.id}`]}
                        files={files}
                        setFiles={(value) => setFiles(value)}
                        fetchTopicResources={(id) => fetchInstructionResources(id)}
                        parentType={`instruction`}
                      />
                    </div>
                  </div>
                </React.Fragment>
              </div>
            )}
          </Droppable>
          {activeFooterTab === "share_resources" && isShareResActive ? (
            <Col
              className="px-0 border_on_hover pl-0"
              style={{
                backgroundColor: "#303C54",
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: "9px",
                border: "1px solid #303C54",
                marginBottom: "12px",
                marginTop: "12px",
              }}
            >
              <InputGroup
                className="py-0 d-block"
                style={{
                  borderRadius: "9px",
                  border: "1px solid transparent",
                }}
              >
                <InputGroup.Prepend
                  className="border-0"
                  style={{
                    backgroundColor: "#303C54",
                    borderRadius: "9px 0px 0px 9px",
                  }}
                >
                  <InputGroup.Text
                    className="border-0 d-flex justify-content-center"
                    style={{
                      backgroundColor: "#303C54",
                      borderRadius: "9px 0px 0px 9px",
                      color: "#E0E2E5",
                      opacity: "41%",
                      fontSize: "16px",
                    }}
                    id="basic-addon1"
                    onClick={() => {
                      document.getElementById("resource_instructions").focus()
                    }}
                  >
                    Type instructions
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  className={`border-0 d-flex w-100 justify-content-center shadow-none ${StylesB.course_create} text-white`}
                  style={{
                    backgroundColor: "#303C54",
                    borderRadius: "0px",
                    fontSize: "16px",
                  }}
                  as="textarea"
                  type="text"
                  rows="4"
                  name="resource_instructions"
                  id="resource_instructions"
                  placeholder=""
                  onChange={(e) => {
                    setSharedResInstruction(e.target.value)
                  }}
                />
              </InputGroup>
            </Col>
          ) : (
            ""
          )}
        </div>
      )
    }
  }

  function handleClickInstructions() {
    if (activeFooterTab === "send_instructions") {
      handleSubmitInstructions()
      setActiveFooterTab(null)
    } else {
      setActiveFooterTab("send_instructions")
    }
  }
  const handleSubmitInstructions = async () => {
    let formData = new FormData()
    formData.append("training", classroomState.training_id)
    formData.append("title", sessionLevelInstructions)
    formData.append("published", true)
    formData.append("object_id", session.id)
    formData.append("resource_type", "breakoutsession")
    let response = await postRequest(`/instructions/create/`, formData)
    if (response.success) {
      let dummy = [...sessionInstructionsList]
      dummy.unshift(response.data)
      setSessionInstructionsList(dummy)
      setSessionLevelInstructions("")
    }
  }

  function getBreakoutModalFooter() {
    return (
      <div className={`w-100`}>
        <div className={`w-100 align-items-center`}>
          {activeFooterTab ? (
            <div className={`w-100 d-inline-flex`}>
              <Image
                src={Times}
                alt="close"
                className={`pointer ml-auto`}
                onClick={() => {
                  setActiveFooterTab(null)
                  if (activeFooterTab === "share_resources") {
                    sidebarDispatch({ type: SET_DISABLED_RESOURCES, payload: [] })
                    sidebarDispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
                    sidebarDispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: "" })
                  }
                }}
              />
            </div>
          ) : null}
          {getFooterData()}
        </div>
        <div className={`w-100 d-inline-flex align-items-center`}>
          <Btn
            className={`poppins_font ${
              activeFooterTab === "share_resources" ? `${Style.breakout_modal_fotter_btn_active} py-2` : `${Style.breakout_modal_fotter_btn_inactive} py-1`
            } ${activeFooterTab === "share_resources" && !isShareResActive ? Style.btn_inactive : ""} border_on_hover px-3 mr-3`}
            disabled={activeFooterTab === "share_resources" && !isShareResActive}
            onClick={(e) => {
              e.preventDefault()
              sidebarDispatch({ type: SET_DISABLED_RESOURCES, payload: disabledResourceType })
              createSharedInstruction(activeFooterTab === "share_resources" ? null : "share_resources")
            }}
          >
            <p className={`mb-0 poppins_font`} style={{ fontSize: "16px", fontWeight: "500" }}>
              Share Resources
            </p>
          </Btn>
          <Btn
            className={`poppins_font ${
              activeFooterTab === "send_instructions" ? `${Style.breakout_modal_fotter_btn_active} py-2` : `${Style.breakout_modal_fotter_btn_inactive} py-1`
            } border_on_hover px-3 mr-3`}
            onClick={(e) => {
              e.preventDefault()
              handleClickInstructions()
              closeSidebar()
            }}
          >
            <p className={`mb-0 poppins_font`} style={{ fontSize: "16px", fontWeight: "500" }}>
              Send Instructions
            </p>
          </Btn>
          <Btn
            className={`poppins_font ${
              activeFooterTab === "extend_time" ? `${Style.breakout_modal_fotter_btn_active} py-2` : `${Style.breakout_modal_fotter_btn_inactive} py-1`
            } border_on_hover px-4 mr-3`}
            onClick={(e) => {
              e.preventDefault()
              handleExtendTime()
              closeSidebar()
            }}
          >
            <p className={`mb-0 poppins_font`} style={{ fontSize: "16px", fontWeight: "500" }}>
              Extend Time
            </p>
          </Btn>
          <Btn
            className={`poppins_font ml-auto ${Style.breakout_modal_fotter_btn_active} border_on_hover px-3 py-1`}
            onClick={() => {
              setShowEndBreakoutSessionModal(true)
              closeSidebar()
            }}
            style={{ backgroundColor: "#E88373" }}
          >
            <p className={`mb-0 poppins_font`} style={{ fontSize: "16px", fontWeight: "500", color: "#121B2B" }}>
              End Session
            </p>
          </Btn>
        </div>
      </div>
    )
  }

  const updateTotalBreakoutRoomTime = (newDuration, source) => {
    // Update the session status in lessonboard topic.
    let ongoing_topics = { ...lessonboardState.topics["module_Doing"] }
    let current_topic_index = ongoing_topics?.topics?.findIndex((topic) => topic.id === session.topic)
    if (ongoing_topics.topics[current_topic_index]) {
      let current_topic = ongoing_topics.topics[current_topic_index]
      if (current_topic) {
        let current_breakout = current_topic.breakout_sessions.filter((breakout_session) => breakout_session.id === session.id)
        if (current_breakout) {
          current_breakout[0].total_duration = newDuration
          lessonboardDispatch({ type: SET_TOPIC_DATA, payload: { key: "module_Doing", value: ongoing_topics } })
        }
      }
    }

    let current_session = { ...classroomState.activeBreakoutSession }
    current_session.total_duration = newDuration
    dispatch({ type: SET_ACTIVE_BREAKOUT_SESSION, payload: current_session })
    setBreakoutSession(current_session)
    globalDispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "success", title: "Extended Time Sucessfully" } })
    if (source === "active_bs_modal") {
      setActiveFooterTab(null)
      dispatch({ type: SET_SHOW_BREAKOUT_SESSION_EXTEND_DURATION_MODAL, payload: false })
      setExtendDurationValue(30)
    } else {
      setShowSuggestionModal(false)
      setShowBREndWarningModal(false)
    }
  }

  function handleExtendTime() {
    if (activeFooterTab === "extend_time") {
      if (parseInt(extendDurationValue) > 0) {
        handleSubmitExtendTime()
      }
    }
    setActiveFooterTab("extend_time")
  }

  const handleSubmitExtendTime = async (extendedValue = null, source = "active_bs_modal") => {
    let session_id = session.id
    let extended_duration = extendedValue || extendDurationValue

    let formData = new FormData()
    formData.append("duration", extended_duration)

    if (session_id != null) {
      let response = await postRequest(`/breakout-session-utils/${session_id}/extend-time/`, formData)
      if (response.success) {
        updateTotalBreakoutRoomTime(response?.data?.total_duration, source)
      }
    }
  }

  const openViewDetailsModal = (item) => {
    setActiveGroup(item)
    setShowViewDetailsModal(true)
  }

  const selectActivityFilter = () => {
    return (
      <div
        className={`mb-2`}
        style={{
          backgroundColor: "#42506C",
          borderRadius: "9px",
          border: "1px solid white",
        }}
      >
        {isBreakoutActivitiesFetched ? (
          breakoutActivities && breakoutActivities.length > 0 ? (
            <React.Fragment>
              <div
                className={`w-100 pl-2 py-1 pr-3 pointer d-inline-flex align-items-center position-relative`}
                onClick={() => setShowBreakoutActivities(!showBreakoutActivities)}
              >
                <div>
                  <p className={`mb-1 poppins_font`} style={{ fontSize: "14px", fontWeight: "500" }}>
                    Select Activity
                  </p>
                  <p className={`mb-0 poppins_font`} style={{ fontSize: "16px", fontWeight: "500" }}>
                    {activeBreakoutActivity ? activeBreakoutActivity.name : null}
                  </p>
                </div>
                <div className={`ml-auto`}>
                  <Image src={DownArrow} alt="down" className={`w-18px ${showBreakoutActivities ? "rotate-180" : ""}`} />
                </div>
              </div>
              <div className={`px-2`} style={{}}>
                {showBreakoutActivities ? (
                  <div className={`d-inline-block w-100 align-items-center `} style={{ maxHeight: "200px", overflowY: "auto" }}>
                    {breakoutActivities.map((activity, index) => {
                      return (
                        <div
                          key={`activity_${index}`}
                          className={`mb-2 px-3 py-2 pointer`}
                          onClick={() => {
                            setShowBreakoutActivities(false)
                            setActiveBreakoutActivity(activity)
                          }}
                          style={{
                            backgroundColor: "#4F6080",
                            borderRadius: "9px",
                          }}
                        >
                          <p className={`mb-0`}>{activity.name}</p>
                        </div>
                      )
                    })}
                    {breakoutActivitiesNextUrl !== null && <SeeMore url={breakoutActivitiesNextUrl} handleData={handleActivitySeeMoreResponse} />}
                  </div>
                ) : null}
              </div>
            </React.Fragment>
          ) : (
            <div className={`px-3 text-center`}>No Activities Found</div>
          )
        ) : (
          <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
            <Spinner animation="border" variant="secondary" />
          </Col>
        )}
      </div>
    )
  }

  const getAllBreakOutGroups = () => {
    return (
      <div
        onScroll={(e) => {
          e.stopPropagation()
        }}
        style={{ maxHeight: "50vh", overflowY: "scroll" }}
      >
        {breakoutGroupsProgress.map((item, index) => {
          let report = item.breakout_session_report
          return (
            <div
              key={`breakout_${index}`}
              className={`w-100 d-inline-flex mb-2 position-relative`}
              style={{
                backgroundColor: "#303C54",
                borderRadius: "9px",
                height: "60px",
              }}
            >
              <p
                className={`mb-0 pl-3 poppins_font position-absolute`}
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  left: "0px",
                  top: "25%",
                }}
              >
                {item.title} ({report.members})
              </p>
              <div
                className={`py-4`}
                style={{
                  backgroundColor: "#5D6D8D69",
                  width: `${(report.completed_resources / report.resources) * 100}%`,
                  borderRadius: "9px 0px 0px 9px",
                }}
              ></div>
            </div>
          )
        })}
      </div>
    )
  }

  const showSharedItems = () => {
    return (
      <Col
        className={`pt-2`}
        style={{
          backgroundColor: "#303C54",
          borderRadius: "9px",
        }}
      >
        <div className={`w-100 mb-3 d-inline-flex align-items-center`}>
          <p
            className={`poppins_font pointer mb-0 mr-3`}
            onClick={() => {
              setShowInstructions(false)
            }}
            style={{
              fontSize: "16px",
              fontWeight: "500",
              borderBottom: !showInstructions ? "1px solid white" : "0px",
            }}
          >
            Resources ({sharedResourcesCount[`as_${session?.id}`]})
          </p>
          <p
            className={`poppins_font pointer mb-0`}
            onClick={() => {
              setShowInstructions(true)
            }}
            style={{
              fontSize: "16px",
              fontWeight: "500",
              borderBottom: showInstructions ? "1px solid white" : "0px",
            }}
          >
            Instructions ({sessionInstructionsCount})
          </p>
        </div>
        {getSharedResources()}
      </Col>
    )
  }

  const fetchSessionInstructions = async () => {
    let response = await getRequest(
      `/instructions/?training=${classroomState.training_id}&object_id=${session?.id}&content_type=breakoutsession&instruction_type=0`
    )
    if (response.success) {
      setSessionInstructionsList(response.data.results)
      setSessionInstructionsFetched(true)
      setSessionInstructionsCount(response?.data?.count)
    }
  }
  const fetchSharedResources = async () => {
    let response = await getRequest(
      `/instructions/?instruction_type=1&published=true&show_resources=true&isEmpty=false&ordering=-id&paginate_by=4&breakout_session__id=${session?.id}`
    )
    if (response.success) {
      let key = `as_${session?.id}`
      let data = { ...sharedResources }
      let dataUrl = { ...sharedResourcesNextUrl }
      let dataCount = { ...sharedResourcesCount }
      data[key] = response?.data?.results
      dataUrl[key] = response?.data?.next
      dataCount[key] = response?.data?.resources_count
      setSharedResources(data)
      setSharedResourcesNextUrl(dataUrl)
      setSharedResourcesCount(dataCount)
    }
  }

  const getSharedResources = () => {
    if (showInstructions) {
      if (sessionInstructionsFetched) {
        return (
          <div style={{ maxHeight: "50vh", overflowY: "scroll" }}>
            {sessionInstructionsList.map((item, index) => {
              return (
                <div key={`resource_${index}`}>
                  <p className={`mb-2 poppins_font`} style={{ fontSize: "14px" }}>
                    Shared : {utcDateTime.utcDateTime(item.created, "time")}
                  </p>
                  <div style={{ fontSize: "16px", color: "#E0E2E5" }} dangerouslySetInnerHTML={{ __html: item.title }} />
                </div>
              )
            })}
          </div>
        )
      } else {
        return (
          <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
            <Spinner animation="border" variant="secondary" />
          </Col>
        )
      }
    } else {
      if (sharedResources && sharedResources[`as_${session?.id}`]) {
        return (
          <div onScroll={handleSharedResourcesScroll} style={{ maxHeight: "400px", overflowY: "scroll" }}>
            {sharedResources[`as_${session?.id}`].map((item, index) => {
              if (item?.resources.length > 0) {
                return (
                  <React.Fragment>
                    <p className={`mb-2 poppins_font`} style={{ fontSize: "14px" }}>
                      {item?.resources.length} resources shared : {utcDateTime.utcDateTime(item?.updated_at, "time")}
                    </p>
                    {item.resources.map((res, index) => {
                      return (
                        <div
                          key={`sharedRes${index}`}
                          className={`w-100 py-2 mb-2 align-items-center d-inline-flex`}
                          style={{
                            backgroundColor: "#4F6080",
                            borderRadius: "9px",
                            boxShadow: "0px 3px 6px #00000029",
                          }}
                        >
                          <img className="mb-0" style={{ height: "30px", width: "80px" }} src={BreakoutSessionIcon} />
                          <p className={`mb-0 poppins_font`} style={{ fontSize: "14px" }}>
                            {res?.resource_data?.name}
                          </p>
                        </div>
                      )
                    })}
                  </React.Fragment>
                )
              }
            })}
          </div>
        )
      }
    }
  }

  function handleSharedResourcesScroll(e) {
    e.stopPropagation()
    if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 5 && sharedResourcesNextUrl["as_" + session?.id]) {
      fetchNextSharedResources(sharedResourcesNextUrl["as_" + session?.id])
    }
  }

  const showActivitywiseReport = () => {
    if (activeActivityResource) {
      let activityWiseGroups

      switch (activeActivityResource.resource_type) {
        case "casestudy": {
          activityWiseGroups = activityWiseCaseStudyGroups
          break
        }
        case "quiz": {
          activityWiseGroups = activityWiseQuizGroups
          break
        }
        case "checklist": {
          activityWiseGroups = activityWiseChecklistGroups
          break
        }
        case "matchthefollowing": {
          activityWiseGroups = activityWiseMTFGroups
          break
        }
      }

      let key = `res_${activeActivityResource?.id}`
      const groupsList = activityWiseGroups
        ? activeActivityResource.resource_type === "matchthefollowing"
          ? activityWiseGroups[key]
          : activityWiseGroups[key]?.results
        : activeBreakoutActivity?.breakout_groups
      return (
        <div
          onScroll={(e) => {
            e.stopPropagation()
          }}
          style={{ maxHeight: "50vh", overflowY: "scroll" }}
        >
          {groupsList ? (
            groupsList?.length > 0 ? (
              groupsList.map((item, index) => {
                let progress
                if (item["finished_percentage"] === undefined) {
                  progress = 0
                } else {
                  progress = item["finished_percentage"]
                }
                return (
                  <div
                    key={`allBreakout${index}`}
                    className={`w-100 d-inline-flex mb-2 position-relative`}
                    style={{
                      backgroundColor: "#303C54",
                      borderRadius: "9px",
                      height: "60px",
                    }}
                  >
                    <p
                      className={`mb-0 px-3 poppins_font position-absolute w-100`}
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        left: "0px",
                        top: "25%",
                      }}
                    >
                      {item.title || item.group_name} {!isNaN(item.students_count) ? `(${item.students_count})` : ""}
                      {item?.report_card && (
                        <span onClick={() => openViewDetailsModal(item)} className={`float-right underline pointer`}>
                          View Details
                        </span>
                      )}
                    </p>
                    <div
                      className={`py-4`}
                      style={{
                        backgroundColor: "#5D6D8D69",
                        width: `${progress}%`,
                        borderRadius: "9px 0px 0px 9px",
                      }}
                    ></div>
                  </div>
                )
              })
            ) : (
              <div className={`w-100 d-inline-flex text-center`}>
                <p className={`mb-3 py-3 mx-auto`}>No Groups Found</p>
              </div>
            )
          ) : (
            <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
              <Spinner animation="border" variant="secondary" />
            </Col>
          )}
        </div>
      )
    } else if (
      !activeActivityResource ||
      !(activityResources && activityResources[`act_${activeBreakoutActivity?.id}`] && activityResources[`act_${activeBreakoutActivity?.id}`].length)
    ) {
      return (
        <div className={`w-100 d-inline-flex text-center`}>
          <p className={`mb-3 py-3 mx-auto`}>No Resource found in current activity</p>
        </div>
      )
    } else {
      return (
        <div
          onScroll={(e) => {
            e.stopPropagation()
          }}
          style={{ maxHeight: "50vh", overflowY: "scroll" }}
        >
          {activityWiseProgressFetched ? (
            activityWiseBreakoutGroupsProgress.length > 0 ? (
              activityWiseBreakoutGroupsProgress.map((item, index) => {
                let report = item.breakout_session_report
                return (
                  <div
                    key={`allBreakout${index}`}
                    className={`w-100 d-inline-flex mb-2 position-relative`}
                    style={{
                      backgroundColor: "#303C54",
                      borderRadius: "9px",
                      height: "60px",
                    }}
                  >
                    <p
                      className={`mb-0 px-3 poppins_font position-absolute w-100`}
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        left: "0px",
                        top: "25%",
                      }}
                    >
                      {item.title} ({report.members})
                    </p>
                    <div
                      className={`py-4`}
                      style={{
                        backgroundColor: "#5D6D8D69",
                        width: `${(report.completed_resources / report.resources) * 100}%`,
                        borderRadius: "9px 0px 0px 9px",
                      }}
                    ></div>
                  </div>
                )
              })
            ) : (
              <div className={`w-100 d-inline-flex text-center`}>
                <p className={`mb-3 py-3 mx-auto`}>No Groups Found</p>
              </div>
            )
          ) : (
            <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
              <Spinner animation="border" variant="secondary" />
            </Col>
          )}
        </div>
      )
    }
  }

  const showActivitywiseData = () => {
    return (
      <div>
        {selectActivityFilter()}
        {activityResourcesFetched && selectResourceFilter()}
        {showActivitywiseReport()}
      </div>
    )
  }
  const selectResourceFilter = () => {
    if (activeActivityResource !== null) {
      let key = `act_${activeBreakoutActivity?.id}`
      let activeImg = getImageToBeRendered(activeActivityResource)
      if (activityResourcesFetched) {
        if (activityResources && activityResources[key] && activityResources[key].length > 0) {
          return (
            <div
              className={`mb-2`}
              style={{
                backgroundColor: "#42506C",
                borderRadius: "9px",
                border: "1px solid white",
              }}
            >
              <div
                className={`w-100 pl-2 py-1 pr-3 pointer d-inline-flex align-items-center position-relative`}
                onClick={() => setShowActivityResources(!showActivityResources)}
              >
                <div>
                  <p className={`mb-1 poppins_font`} style={{ fontSize: "14px", fontWeight: "500" }}>
                    Select Resource
                  </p>
                  <div className={`d-flex`}>
                    <div>
                      <img
                        alt={`image_${activeImg.imgType}`}
                        src={activeImg.imgObj}
                        className={`mb-0`}
                        style={{
                          height: "25px",
                          width: "35px",
                          contain: "content",
                        }}
                      />
                    </div>
                    <div className={`ml-2 poppins_font`}>{activeActivityResource ? activeActivityResource?.resource_data?.name : null}</div>
                  </div>
                </div>
                <div className={`ml-auto`}>
                  <Image src={DownArrow} alt="down" className={`w-18px ${showActivityResources ? "rotate-180" : ""}`} />
                </div>
              </div>
              <div className={`px-2`} style={{}}>
                {showActivityResources ? (
                  <div
                    className={`d-inline-block w-100 align-items-center`}
                    onScroll={handleActivityResourcesScroll}
                    style={{ maxHeight: "200px", overflowY: "auto" }}
                  >
                    {activityResources[key].map((resource, index) => {
                      let image = getImageToBeRendered(resource)
                      return (
                        <div
                          key={`resource_${index}`}
                          className={`mb-2 px-3 py-2 pointer d-flex`}
                          onClick={() => {
                            setShowActivityResources(false)
                            setActiveActivityResource(resource)
                          }}
                          style={{
                            backgroundColor: "#4F6080",
                            borderRadius: "9px",
                          }}
                        >
                          <div>
                            <img
                              alt={`image_${image.imgType}`}
                              src={image.imgObj}
                              className={`mb-0`}
                              style={{
                                height: "25px",
                                width: "35px",
                                contain: "content",
                              }}
                            />
                          </div>
                          <div className={`ml-2`}>{resource?.resource_data?.name}</div>
                        </div>
                      )
                    })}
                    {activityResourcesLoader && <Loader />}
                  </div>
                ) : null}
              </div>
            </div>
          )
        } else {
          return null
        }
      } else {
        return (
          <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
            <Spinner animation="border" variant="secondary" />
          </Col>
        )
      }
    } else {
      return null
    }
  }

  const fetchNextSharedResources = async (next_url) => {
    let response = await getRequest(next_url)
    let key = `as_${session?.id}`
    let data = { ...sharedResources }
    let dataUrl = { ...sharedResourcesNextUrl }
    data[key] = [...data[key], ...response?.data?.results]
    dataUrl[key] = response?.data?.next
    setSharedResources(data)
    setSharedResourcesNextUrl(dataUrl)
  }

  const handleCloseModal = () => {
    setCloseModal(true)
    dispatch({ type: SET_OPENED_OVERLAY_SECTION, payload: null })
    if (session.status === "2") {
      dispatch({ type: SET_ACTIVE_BREAKOUT_SESSION, payload: null })
    }
    closeSidebar()
  }

  function breakoutSessionModal() {
    if (session && (classroomState.openedOverlaySection === "ActiveBreakoutSession" || (!isMaximize && !closeModal))) {
      let cssClass = Style.breakout_session_detail_closed

      return (
        <div className={`${isMaximize ? cssClass : ""}`}>
          <div
            className={`${Style.breakout_modal} ${isMaximize ? "" : StyleNew.minimize_modal}`}
            style={{
              borderRadius: "8px",
              maxHeight: "90vh",
              overflowY: "auto",
              border: session?.status === "1" ? "2px solid #3FBDAC" : "1px solid #929BBD",
            }}
          >
            {breakoutGroupsProgress.length > 0 ? (
              <div style={{ opacity: `${showBreakoutSessionEditDurationModal ? 0.2 : 1}` }}>
                <div className="pt-2 px-0">
                  <div className={`w-100 px-2 pb-2 d-inline-flex justify-content-between`} style={{ borderBottom: "1px solid #929BBD" }}>
                    <div className={`w-100 d-inline-flex align-items-center justify-content-between`}>
                      <div className={`d-flex align-items-center`}>
                        <p className={`mb-0 mr-2 poppins_font`} style={{ fontSize: "19px", fontWeight: "500" }}>
                          {session.name}
                        </p>
                        <p className={`poppins_font mb-0`} style={{ fontSize: "16px", fontWeight: "500" }}>
                          {`(${session?.activity_count} Activities)`}
                        </p>
                      </div>
                      {session.status == "1" && (
                        <p className={`mb-0 mr-3`} style={{ fontSize: "16px", fontWeight: "500" }}>
                          Ends in{" "}
                          <span
                            className={`px-2 py-1`}
                            style={{
                              backgroundColor: "#3B82F4",
                              borderRadius: "4px",
                            }}
                          >
                            <TimerComponent
                              startedTime={classroomState.activeBreakoutSession.start_time}
                              totalTimerDuration={classroomState.activeBreakoutSession.total_duration / 60}
                              timerType="decrement"
                              onTimerAction={(isEnded) => {
                                if (isEnded) {
                                  setShowBREndWarningModal(true)
                                  closeSidebar()
                                }
                              }}
                              showTimerEndWarning={true}
                              onShowTimerEndWarning={(showWarning) => {
                                if (showWarning) {
                                  setShowBREndWarningModal(true)
                                }
                              }}
                            />
                          </span>{" "}
                          Mins.
                        </p>
                      )}
                    </div>
                    <div className={`d-flex align-items-center justify-content-center`}>
                      <img
                        src={isMaximize ? Minimize : Maximize}
                        alt={isMaximize ? "minimize" : "maximize"}
                        width={isMaximize ? "18px" : "auto"}
                        height={isMaximize ? "4.5px" : "auto"}
                        className={`${isMaximize ? "object-fit-cover br-11px" : ""} pointer mr-2`}
                        onClick={handleToggleMaximize}
                      />
                      <Image src={CancelWhite} alt="close" className="w-18px pointer" onClick={handleCloseModal} />
                    </div>
                  </div>
                  {isMaximize && (
                    <Col className={`p-2 pb-0 w-100 m-0 poppins_font`}>
                      <div className={`w-100 pb-3 d-inline-flex align-items-center`}>
                        <Btn
                          className={`poppins_font ${
                            activeTab === "overall_progress" ? Style.breakout_modal_btn_active : Style.breakout_modal_btn_inactive
                          } border_on_hover py-2 px-3 mr-3`}
                          onClick={() => {
                            setActiveTab("overall_progress")
                          }}
                        >
                          Overall Progress
                        </Btn>
                        <Btn
                          className={`poppins_font ${
                            activeTab === "activity_wise_progress" ? Style.breakout_modal_btn_active : Style.breakout_modal_btn_inactive
                          } border_on_hover py-2 px-3 mr-3`}
                          onClick={() => {
                            setActiveTab("activity_wise_progress")
                          }}
                        >
                          Activity wise Progress
                        </Btn>
                        <Btn
                          className={`poppins_font ${
                            activeTab === "shared_items" ? Style.breakout_modal_btn_active : Style.breakout_modal_btn_inactive
                          } border_on_hover py-2 px-3 mr-3`}
                          onClick={() => {
                            setActiveTab("shared_items")
                            setActiveFooterTab(null)
                          }}
                        >
                          Shared Items
                        </Btn>
                      </div>
                      <div
                        onScroll={(e) => {
                          e.stopPropagation()
                        }}
                        style={{
                          maxHeight: activeFooterTab ? "200px" : "50vh",
                          overflowY: "scroll",
                        }}
                      >
                        {activeTab === "shared_items" && showSharedItems()}
                        {activeTab === "overall_progress" && getAllBreakOutGroups()}
                        {activeTab === "activity_wise_progress" && showActivitywiseData()}
                      </div>
                    </Col>
                  )}
                </div>
                {isMaximize && session.status === "1" && (
                  <div className={`p-2`} style={{ borderTop: "1px solid #929BBD" }}>
                    <Col className={`w-100 px-0 align-items-center`}>{getBreakoutModalFooter()}</Col>
                  </div>
                )}
              </div>
            ) : (
              <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
                <Spinner animation="border" variant="secondary" />
              </Col>
            )}
            {showBreakoutSessionEditDurationModal && displayBreakoutSessionStart()}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  function handleChangeExtendMins(e) {
    let val = e?.target?.value ?? ""
    if (val.indexOf(".") !== -1) {
      val = parseInt(e.target.value)
    }
    if (e.target.value.indexOf("-") !== -1) {
      val = val * -1
    }
    if (e?.target?.value) e.target.value = val
    return val
  }

  function extendBreakoutSessionTimeModal() {
    return (
      <Modal
        show={classroomState.showBreakoutSessionExtendDurationModal}
        onHide={() => {
          dispatch({ type: SET_SHOW_BREAKOUT_SESSION_EXTEND_DURATION_MODAL, payload: false })
        }}
        style={{ zIndex: "10000" }}
        centered
        size="md"
        className={`${StylesA.classroom_delete_modal} classroom-resource-delete-modal p-0`}
      >
        <Modal.Body
          className="float-left text-center p-3"
          style={{
            backgroundColor: "#212C42",
            borderRadius: "8px 8px 8px 8px",
          }}
        >
          <img
            onClick={(e) => {
              dispatch({ type: SET_SHOW_BREAKOUT_SESSION_EXTEND_DURATION_MODAL, payload: false })
            }}
            src={CancelWhite}
            alt={`Cancel`}
            className={`ml-auto mr-1 mb-0 pointer`}
            style={{
              objectFit: `contain`,
              height: `15px`,
              position: "absolute",
              top: "5px",
              right: "5px",
            }}
          />
          <div
            className={`d-inline-block text-center py-3 px-5`}
            style={{
              backgroundColor: "#42506C",
              borderRadius: "9px",
              boxShadow: "0px 3px 6px #00000029",
            }}
          >
            <p className={`mb-2`} style={{ color: "#E0E2E570", fontSize: "14px" }}>
              Extend time by
            </p>
            <Form
              onSubmit={(e) => {
                e.preventDefault()
                if (parseInt(extendDurationValue) > 0) {
                  handleSubmitExtendTime()
                }
              }}
            >
              <input
                type="number"
                min="1"
                defaultValue={extendDurationValue}
                onChange={(e) => {
                  let val = handleChangeExtendMins(e)
                  setExtendDurationValue(val)
                  setShowNegativeError(false)
                }}
                className={`${StylesA.session_input_field} mx-auto form-control shadow-none px-2 ${StylesA.border_on_hover}`}
                style={{ width: "65px", fontSize: "36px", fontWeight: "500" }}
              />
              <p className={`mb-3`} style={{ color: "#E0E2E570", fontSize: "16px" }}>
                Mins
              </p>
              <button type="submit" className={`poppins_font px-4 py-1 ${Style.breakout_modal_fotter_btn_inactive} border_on_hover`}>
                <p className={`mb-0 poppins_font`} style={{ fontSize: "16px", fontWeight: "500" }}>
                  Extend{" "}
                </p>
              </button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  let activityWiseGroupsData

  if (activeActivityResource?.resource_type === "matchthefollowing") {
    activityWiseGroupsData = activityWiseMTFGroups
  } else if (activeActivityResource?.resource_type === "checklist") {
    activityWiseGroupsData = activityWiseChecklistGroups
  } else if (activeActivityResource?.resource_type === "casestudy") {
    activityWiseGroupsData = activityWiseCaseStudyGroups
  } else if (activeActivityResource?.resource_type === "quiz") {
    activityWiseGroupsData = activityWiseQuizGroups
  }
  return (
    <>
      {breakoutSessionModal()}
      {extendBreakoutSessionTimeModal()}
      {activeGroup && (
        <ViewDetailsModal
          activityWiseGroupsData={activityWiseGroupsData}
          sessionData={session}
          training_id={classroomState.training_id}
          activeResource={activeActivityResource}
          setActiveResource={setActiveActivityResource}
          resourceList={activityResources[`act_${activeBreakoutActivity?.id}`]}
          activeGroup={activeGroup}
          setActiveGroup={setActiveGroup}
          showViewDetailsModal={showViewDetailsModal}
          activeResourceId={classroomState.activeActivityData?.current_active_activity?.resource?.id}
          setShowViewDetailsModal={setShowViewDetailsModal}
          breakoutActivities={breakoutActivities}
          activeBreakoutActivity={activeBreakoutActivity}
          setActiveBreakoutActivity={setActiveBreakoutActivity}
          breakoutActivitiesNextUrl={breakoutActivitiesNextUrl}
          handleActivitySeeMoreResponse={handleActivitySeeMoreResponse}
          handleActivityResourcesScroll={handleActivityResourcesScroll}
        />
      )}
      {showEndBreakoutSessionModal && (
        <EndBreakoutModal
          showEndBreakoutSessionModal={showEndBreakoutSessionModal}
          setShowEndBreakoutSessionModal={setShowEndBreakoutSessionModal}
          updateBreakoutSessionStatus={updateBreakoutSessionStatus}
        />
      )}
      {showBREndWarningModal && (
        <SuggestExtendTimeModal
          showModal={showBREndWarningModal}
          setShowModal={setShowBREndWarningModal}
          updateBreakoutSessionStatus={updateBreakoutSessionStatus}
          handleSubmitExtendTime={handleSubmitExtendTime}
          handleChangeExtendMins={handleChangeExtendMins}
        />
      )}
      {showExtendTimeModalFromNotification && Object.keys(activeNotificationReplyingData).length > 0 && (
        <ExtendTimeModalFromNotification
          show={showExtendTimeModalFromNotification}
          onHide={() => {
            updateNotificationReplyModalStates(SET_SHOW_EXTEND_TIME_MODAL_FROM_NOTIFICATION, false)
            setActiveNotificationReplyingData({})
          }}
          updateSessionDuration={handleSubmitExtendTime}
          handleChangeExtendMins={handleChangeExtendMins}
          notification_data={activeNotificationReplyingData?.notification_data}
        />
      )}
      {showSeekReplyModalFormNotification && Object.keys(activeNotificationReplyingData).length > 0 && (
        <SeekSupportReplyFromNotificationModal
          show={showSeekReplyModalFormNotification}
          onHide={() => {
            updateNotificationReplyModalStates(SET_SHOW_SEEK_REPLY_MODAL_FROM_NOTIFICATION, false)
            setActiveNotificationReplyingData({})
          }}
          notification_data={activeNotificationReplyingData?.notification_data}
        />
      )}
    </>
  )
}

ActiveBreakoutSession.propTypes = {}

export default ActiveBreakoutSession
